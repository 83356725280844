enum UN_ICONS_BUTTON_VARIANT {
  HOLLOW = 'hollow',
  FILLED = 'filled',
  DISABLED = 'disabled'
}

enum UN_ICONS_BUTTON_COLOR {
  GREEN = 'green',
  RED = 'red',
  BLACK = 'black',
  BLUE = 'blue',
  GOLD = 'gold'
}

enum UN_ICONS_BUTTON_MAX_HEIGHT {
  SM = 'sm',
  XS = 'xs',
  XL = 'xl'
}

enum UN_ICONS_BUTTON_TYPE {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}
export {
  UN_ICONS_BUTTON_VARIANT,
  UN_ICONS_BUTTON_COLOR,
  UN_ICONS_BUTTON_MAX_HEIGHT,
  UN_ICONS_BUTTON_TYPE
};
