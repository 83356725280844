import { useRef } from 'react';

const useComponentWillMount = (callback) => {
  const willMount = useRef(true);

  if (typeof window !== 'undefined' && willMount.current) {
    willMount.current = false;
    callback();
  }
};

export default useComponentWillMount;
