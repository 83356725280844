import { useEffect, useState } from 'react';
import { useIsMobile } from '@stores/AppCommonData';

export default function useWindowSize() {
  const isMobile = useIsMobile();
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    isMobile,
    isTablet: false
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < 767,
        isTablet: window.innerWidth < 1135
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
