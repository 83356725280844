import captureAPIFailruesFor from './captureAPIFailuresFor.constants';

const shouldCaptureAPIFailure = (endpoint: string, statusCode: number) => {
  for (let i = 0; i < captureAPIFailruesFor.length; i += 1) {
    // check with regex or endpoint if anyone matches with current endpoint then return true
    if (
      captureAPIFailruesFor[i]?.regex?.test(endpoint) ||
      endpoint?.includes(captureAPIFailruesFor[i].endpoint)
    ) {
      if (!captureAPIFailruesFor[i].statusCodes.length) return true; // Capture all API error status codes.

      return captureAPIFailruesFor[i].statusCodes.includes(statusCode);
    }
  }

  return false;
};

export default shouldCaptureAPIFailure;
