/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, createContext } from 'react';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { PurchaseTypeContextProvider } from '@context/PurchaseTypeContext';
import AppLayout from '@cont/App/AppLayout';
import SegmentAnalytics from '@Analytics/Segment';
import useInternetConnection from '@hooks/useInternetConnection';
import useEducatorContext from '@hooks/useEducatorContext';
import useLogoutListener from '@hooks/useLogoutListener';
import useTTU from '@hooks/useTTU';
import useFullStory from '@hooks/useFullStory';
import useGAId from '@hooks/useGAId';
import uuid from '@utils/uuid';
import { getBrowserInfo, getSystemInfo } from '@utils/browserInfo';
import segmentStorage from '@events/segmentStorage';
import cookies from '@utils/cookies';
import { getMe, getIsLoggedIn } from '@api/selectors/auth';
import { useRouter } from 'next/router';
import useDarkModeCalculator from '@hooks/useDarkModeCalculator';
import useSocket from '@hooks/useSocket';
import useHomepageRedirectsInterceptor from '@hooks/useHomepageRedirects';
import useSegmentUserIdentifier from '@hooks/useSegmentUserIdentifier';
import GlobalStyles from '@shared/styles';
import Dynamic from '@base/Dynamic/Dynamic';
import { useIsMobile, useIsLoginProcessCompleted } from '@stores/AppCommonData';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';
import useSetNudgeData from '@hooks/useSetNudgeData';
import useSegmentPageView from '@hooks/useSegmentPageView';
import StaticPageSetup from '@cont/App/StaticPage/StaticPageSetup';
import validateAndSetSessionId from '@utils/session/validateAndSetSessionId';
import useUserLocation from '@hooks/useUserLocation';
import useConstants from '@api/hooks/useConstants';
import * as amplitude from '@amplitude/analytics-browser';
import ThemeHeader from './ThemeHeader';
import loadAndSetupFonts from './utils/loadAndSetupFonts';

const createJSS = () => {
  if (typeof window === 'undefined') {
    return create(jssPreset());
  }

  const styleNode = document.getElementById('material-ui-styles-begin');

  return create({
    ...jssPreset(),
    insertionPoint: styleNode
  });
};

const jss = createJSS();
const PlannerSetup = Dynamic(() => import('@cont/Planner/PlannerSetup'));
const RenderDeviceLimitationLogoutNudge = Dynamic(() =>
  import('./RenderDeviceLimitationLogoutNudge')
);
const PreviewMode = Dynamic(() => import('@cont/App/PreviewMode'));
const OfflineNotification = Dynamic(() =>
  import('@cont/App/OfflineNotification')
);
export const IsEducatorSideContext = createContext(false);

export default function AppContainer({ Page, pageProps }) {
  const { query } = useRouter();
  const [DOMReadyForAssetLoad, setDOMReadyForAssetLoad] = useState(false);

  const inEducatorSide = useEducatorContext();
  const me = useMyInfo(getMe);
  const isLoggedIn = useMyInfo(getIsLoggedIn);

  useDarkModeCalculator({ inEducatorSide });

  const { selectedGoal } = useSelectedGoal();
  const isMobileDevice = useIsMobile();
  const { accessToken, refreshToken } = pageProps?.token || {};
  const isOffline = useInternetConnection();

  useEffect(() => {
    validateAndSetSessionId();
    if (accessToken) {
      cookies.setCookie('accessToken', accessToken, 3024000, true, true);
    } else if (!me.uid) {
      cookies.setCookie('anonymous_session_id', uuid());
    }
    if (refreshToken) {
      cookies.setCookie('refreshToken', refreshToken, 3024000, true);
    }

    const systemInfo = getSystemInfo(getBrowserInfo());
    systemInfo.device_id = cookies.readCookie('BROWSER_ID') || 'NA';
    segmentStorage.setItem('systemInfo', systemInfo);
    loadAndSetupFonts();
    setDOMReadyForAssetLoad(true);
  }, []);

  // getting user geo location
  useUserLocation();
  // Full Story
  useFullStory();
  // Sending GA Id
  useGAId();
  // GTAG
  // useGtag();
  // GTM
  // useGTM();
  // Segment User Identification
  useSegmentUserIdentifier();
  // Temporary Web Page View event
  useSegmentPageView();
  // Ttu
  useTTU();
  // WebP Support
  // Logout Listeners to log user out across all tabs.
  useLogoutListener();
  // Socket Connection
  useSocket();
  // Homepage redirects listener
  useHomepageRedirectsInterceptor();
  // Nudge Data
  useSetNudgeData();
  // Geosynced Timestamps
  // useGeosyncedTimestamps(pageProps.currentTime);
  // Login process completion listener
  useIsLoginProcessCompleted();

  const isStaticPage = Boolean(Page.pageInfo && !Page.getInitialProps);
  const hideOfflineState = Boolean(Page.pageInfo?.hideOfflineState);
  const { constants } = useConstants();
  const { AMPLITUDE_ENABLED, SEGMENT_ENABLED } = constants;

  useEffect(() => {
    if (AMPLITUDE_ENABLED === true) {
      amplitude.init(process.env.AMPLITUDE_API_KEY);
    }
  }, [AMPLITUDE_ENABLED]);

  return (
    <>
      {isLoggedIn && !isMobileDevice && <PlannerSetup />}
      {isStaticPage && <StaticPageSetup Page={Page} />}
      <GlobalStyles
        isOffline={!hideOfflineState && isOffline}
        isMobile={isMobileDevice}
        fontsLoaded={DOMReadyForAssetLoad}
      />

      <SegmentAnalytics
        AMPLITUDE_ENABLED={AMPLITUDE_ENABLED}
        SEGMENT_ENABLED={SEGMENT_ENABLED}
      />

      <ThemeHeader />
      <StylesProvider jss={jss}>
        <PurchaseTypeContextProvider
          goalUID={query.goalUID || selectedGoal?.uid}
        >
          <IsEducatorSideContext.Provider value={inEducatorSide}>
            <PreviewMode />
            <AppLayout
              Page={Page}
              pageProps={pageProps}
              inEducatorSide={inEducatorSide}
            />
          </IsEducatorSideContext.Provider>
        </PurchaseTypeContextProvider>
        {!hideOfflineState && (
          <OfflineNotification
            isMobileDevice={isMobileDevice}
            isOffline={isOffline}
          />
        )}

        <RenderDeviceLimitationLogoutNudge />
      </StylesProvider>
    </>
  );
}
