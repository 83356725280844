/* eslint-disable import/prefer-default-export */

export const OS = {
  MacOS: 'MacOS',
  Windows: 'Windows',
  UNIX: 'UNIX',
  Linux: 'Linux',
  Android: 'Android',
  iOS: 'iOS',
  UNKNOWN: 'Unknown OS'
};
