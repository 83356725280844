import { usePageInfo } from '@stores/AppCommonData';

const emptyObject = {};

export const useLayoutOptions = () => {
  const currentPageInfo = usePageInfo();
  return currentPageInfo.layoutOptions || emptyObject;
};

export const useFlagSelector = (flag) =>
  useLayoutOptions()[flag] || emptyObject[flag];
