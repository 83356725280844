import segmentStorage from '@events/segmentStorage';
import cookies from '@utils/cookies';
import { getHoursMinutesSeconds, getYYYYMMDDFormat } from '@utils/dates';
import validateAndSetSessionId, {
  DEVICE_ID_COOKIE_NAME
} from '@utils/session/validateAndSetSessionId';
import uuid from '@utils/uuid';

const getWebPageViewEventProperties = (eventName, payload = {}) => {
  validateAndSetSessionId();
  const deviceID = cookies.readCookie(DEVICE_ID_COOKIE_NAME);
  const segmentData = segmentStorage.getItem('segmentData', {});
  const systemInfo = segmentStorage.getItem('systemInfo', {});
  let event_json = 'NA';
  if (segmentData?.event_json) {
    event_json = JSON.stringify(segmentData?.event_json);
  }
  const date = new Date();
  const event_timestamp = Date.parse(date);
  const received_at = `${getYYYYMMDDFormat(date, '-')} ${getHoursMinutesSeconds(
    date
  )}`;

  const eventProperties = {
    experiment_name: payload?.experiment_name ?? 'NA',
    learner_uid: segmentData?.learner_uid || 'NA',
    context_page_url: window.location.href,
    context_page_referrer: document.referrer,
    login_status: segmentData.login_status,
    current_screen: segmentData.current_screen,
    current_page_url: segmentData.current_page_url,
    user_type: segmentData.user_type,
    days_since_joining: (segmentData?.days_since_joining || '').toString(),
    use_segment_api: !!payload.use_segment_api,
    session_id: segmentData?.session_id ?? null,
    is_verified: segmentData.is_verified,
    anonymous_user_id: deviceID,
    last_primary_source_section:
      segmentData?.last_primary_source_section ?? 'NA',
    last_primary_source: segmentData?.last_primary_source ?? 'NA',
    default_goal_name: segmentData.default_goal_name,
    default_goal_uid: segmentData.default_goal_uid,
    app_version: (systemInfo?.app_version ?? '').toString(),
    os_version: systemInfo.os_version,
    os: systemInfo.os,
    browser_version: systemInfo.browserVersion,
    browser: systemInfo.browser,
    device_id: systemInfo.device_id,
    event_referrer: segmentData?.event_referrer ?? 'NA',
    event_json,
    client_id: segmentData.client_id,
    uuid: uuid(),
    uid: uuid(),
    event_timestamp,
    received_at
  };

  return {
    eventName,
    payload: [
      {
        name: eventName,
        version: 1,
        data: {
          ...eventProperties,
          platform: window.isMobile ? 7 : 0
        }
      }
    ]
  };
};

export default getWebPageViewEventProperties;
