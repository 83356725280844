import fetch, { simpleFetch } from '@api/fetchers';
import { ttuStore } from '@stores/ttu';
import { getHost } from '@utils/api-helper/host';
import { readCookie } from '@utils/cookies';

const defaultHost = getHost();

export const showTTUOption = (goalUID, accessToken) => {
  const authorization = accessToken || readCookie('accessToken') || null;
  return simpleFetch(
    `${defaultHost}v1/user/sales_connect/?goal_uid=${goalUID}`,
    { authorization }
  )
    .then((res) => {
      ttuStore.getState().setShowData(res.data);
      return res.data;
    })
    .catch(() => {});
};

export const postTTUResponse = (body) =>
  fetch('v2/user/talk_to_unacademy/post_response/', {
    requestType: 'POST',
    body
  });
