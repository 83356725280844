import { nudgeTypes } from '@constants/index';

/* eslint-disable import/prefer-default-export */
export const getNudgeData = (me) => {
  if (!me || !me.uid) {
    return null;
  }

  const { phone, isPhoneVerified, email, isEmailVerified, country } = me;
  let data = null;

  if (!phone) {
    data = {
      type: nudgeTypes.ADD_PHONE,
      title: 'Add your mobile number',
      description: 'Adding your mobile number helps you secure your account.'
    };
  } else if (phone && !isPhoneVerified) {
    data = {
      type: nudgeTypes.VERIFY_PHONE,
      title: `Confirm your number: +${country.phoneCode} ${phone}`,
      description:
        'We’ll send you confirmation OTP to verify your mobile number. A verified mobile number helps ensure you don’t lose access to your account'
    };
  } else if (!email) {
    data = {
      type: nudgeTypes.VERIFY_EMAIL,
      title: 'Add your e-mail',
      description:
        'Help keep your account secure and you can reset your password if you ever need to. Only you will see your number'
    };
  } else if (email && !isEmailVerified) {
    data = {
      type: nudgeTypes.VERIFY_EMAIL,
      title: `Confirm your email: ${email}`,
      description:
        'Check your inbox for Unacademy’s confirmation email. A current email address helps ensure you don’t lose access to your account'
    };
  }
  return data;
};
