import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { fetchMeData } from '@api/fetchers/helpCenter';
import { useFlagSelector } from '@hooks/useLayoutOptions';
import initializeFreshbots from '@utils/freshbotsInit';
import { useToggleable as useDrawer } from '@stores/toggleables';
import {
  getIsSubscribedToPlusWithoutTrial,
  getIsSubscribedToPlatformBatchGroup
} from '@api/selectors/subscription';
import useAccessControl from '@api/hooks/auth/useAccessControl';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn, getMe, getIsPartner } from '@api/selectors/auth';
import { readCookie } from '@utils/cookies';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';
import { DRAWER_IDS } from '@constants/index';
import useGeoLocation from '@api/hooks/useGeoLocation';
import useFetch from './useFetch';

const useFreshBots = (isEducatorSide, accessToken) => {
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const me = useMyInfo(getMe);
  const isSubscribedNonTrial = useMyInfo(getIsSubscribedToPlusWithoutTrial);
  const { selectedGoal } = useSelectedGoal();
  const isSubscribedToPlatformBatch = useMyInfo(
    getIsSubscribedToPlatformBatchGroup
  );

  const { data: selfData = {} } = useFetch(
    selectedGoal?.uid ? `v1/topology/users/${selectedGoal?.uid}/self/` : null
  );

  const { isOpen: isClassModalOpened } = useDrawer(
    DRAWER_IDS.COURSE_CLASS_PLAYER
  );
  const { accessControl } = useAccessControl({ goalUID: selectedGoal?.uid });
  const isPartner = getIsPartner(me, accessControl);
  const { geoLocationData: { countryCode } = {} } = useGeoLocation();
  const showFreshbot = isSubscribedNonTrial || isSubscribedToPlatformBatch;
  const router = useRouter();
  const { route } = router;
  const isEducatorOrPartner = me.isEducator || isPartner;
  const hideFreshbot = useFlagSelector('hideFreshbot') || isEducatorOrPartner;

  const initializeBot = async () => {
    const roles = { 1: 'learner', 2: 'educator' };

    const response = await fetchMeData(isEducatorSide ? roles[2] : roles[1]);
    initializeFreshbots(
      route,
      {
        accessToken: accessToken || readCookie('accessToken'),
        me,
        isLoggedIn,
        selectedGoal: selectedGoal?.uid,
        selfData
      },
      response,
      countryCode
    );
    window.isFreshbotsVisible = true;
  };

  useEffect(() => {
    if (!process.env.FRESHBOT_CLIENT_KEY && !process.env.FRESHBOT_HASH_KEY)
      return;
    if (!window.Freshbots && !hideFreshbot && showFreshbot) {
      if (!isLoggedIn || me.isEducator) return;
      initializeBot();
    }
    const interval = setInterval(() => {
      const freshBot = document.getElementById('mobile-chat-container');
      if (freshBot) {
        if (!hideFreshbot && isLoggedIn && !isClassModalOpened) {
          freshBot.style.display = 'block';
        } else {
          freshBot.style.display = 'none';
        }
        clearInterval(interval);
      }
      if (!window.Freshbots) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, isLoggedIn, isClassModalOpened, hideFreshbot]);
};

export default useFreshBots;
