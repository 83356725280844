/* eslint-disable no-throw-literal */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { readCookie } from '@utils/cookies';

// Hook to intercept client-side redirects to home-page, and ensure that if the user has a selected goal, they are redirected to the goal page instead. This is done as home page is now a purely a static page.
const useHomepageRedirects = () => {
  const { events, push } = useRouter();

  useEffect(() => {
    const onRouteChangeStart = (path) => {
      const selectedGoalUid = readCookie('selectedGoalUid');
      if (path === '/' && selectedGoalUid) {
        events.emit('routeChangeError');
        // setTimeout for the alternative route change, so this executes after the throw statement is done executing and cancelling the redirect.
        setTimeout(() => push(`/goal/-/${selectedGoalUid}`), 0);
        throw 'Cancelling redirect';
      }
    };

    events.on('routeChangeStart', onRouteChangeStart);
    return () => events.off('routeChangeStart', onRouteChangeStart);
  }, [events, push]);
};

export default useHomepageRedirects;
