export const DEFAULT_PLAYER_TIME = '00:00';

export const ICONS_GOALS_KEY = 'ICONS_GOALS';

export const ICONS_KEY = 'uaIcons';

export const ICONS_GOAL_UID =
  process.env.ENVIRONMENT === 'gamma' || process.env.NODE_ENV === 'development'
    ? 'KDLIR'
    : 'OUUJV';

export const ICONS_GOAL_TYPE = 'Icons';

export const ICONS_THEME_ID = 'uaicons';

export const UA_ICONS_SLUG = 'icons';

export const ICONS_GOAL_META_TITLE =
  'Unacademy Icons - Sachin Tendulkar - Learn cricket online';

export const ICONS_GOAL_META_DESCRIPTION =
  'Unacademy Icons gives you the chance to learn cricket from the Master Blaster himself - Sachin Tendulkar. Learn the fundamentals of cricket and other valuable lessons on and off the field.';

export const PLAYER_EVENTS = {
  WAITING: 'waiting',
  PAUSE: 'pause',
  PLAY: 'play',
  DISPOSE: 'dispose',
  TIMEUPDATE: 'timeupdate'
};

export const PLAYER_HOTKEYS = {
  M: 77,
  K: 75,
  F: 70,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  SPACE: 32,
  ESC: 27
};

// TODO:To be discarded once API needs to be is integrated
export const LAUNCH_TEASER_VIDEO =
  'https://icons.uacdn.net/cs/514d047a-599a-4de7-ace6-e44f4d4c5d1d/v/050156a1-8cc7-4589-a1ac-7df48155ab5c.mp4';

export const ICONS_INSTAGRAM =
  'https://www.instagram.com/unacademyicons/?utm_medium=copy_link';

export const SEEKBAR_OFFSET = 125;

export const CONTROLS_PADDING_X = 24;

export const APP_DRAWER_ID = 'un-icons-nudge';

export const COURSE_PATHNAME = '/icons/courses/[courseSlug]';

export const LESSON_PATHNAME =
  '/icons/courses/[courseSlug]/lessons/[lessonSlug]';

export const ICONS_HOME_PATHNAME = '/goal/[goalSlug]/[goalUID]/icons-home';

export const ICONS_DOWNLOAD_PATHNAME =
  '/goal/[goalSlug]/[goalUID]/icons-download';

export const BREAKDOWN_ENTITIES = {
  ASSET_QUESTION: 'assetquestion'
};
export const NOTIFY_MESSAGE =
  'Thank you, we will be in touch soon about the launch of Unacademy Icons ';

export const ITCL_CARD_TITLE = 'Learn from the Master Blaster';

export const ITCL_CARD_DESCRIPTION = 'Access to unlimited courses by Icons';

export const PLAY_STORE_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.unacademyapp&referrer=utm_source%UnacademyIcons%26utm_medium%3DWebApp%26utm_campaign%UnacademyIcons%26';
