import createHook from 'zustand';

const useSegmentStatus = createHook((set) => ({
  segmentLoaded: false,
  loadSegment: false,
  setLoadSegment: (status) =>
    set((state) => {
      return {
        ...state,
        loadSegment: status
      };
    }),
  setSegmentLoaded: (status) =>
    set((state) => {
      return {
        ...state,
        segmentLoaded: status
      };
    })
}));

export default useSegmentStatus;
