import getIsCurrentSubscription from './getIsCurrentSubscription';
import isNotLiteTypeSubscription from './isNotLiteTypeSubscription';
import isOfflineSubscription from './isOfflineSubscription';

/**
 *
 * @param {*} user
 * @returns  an array of uids - it checks whethe rthe subscription is lite or not
 * and whether the subscription is active
 * So in case of offline subscription - it will have this id
 * now it will only return goal uids for which has user online subsription, not offline
 */

const plusSubscriptionConditions = (user) =>
  (
    user?.userSubscriptionTimeline ||
    user?.latestUserSubscriptionsTimeline ||
    user?.latestUserSubscriptions ||
    user?.latestUserSubscriptionsTypeWise
  )?.reduce((_arrayIds, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      isNotLiteTypeSubscription(_plusSubscription) &&
      !isOfflineSubscription(_plusSubscription)
    ) {
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    }
    return _arrayIds;
  }, []) ?? [];

const getPlusSubscriptionsUids = (user) => {
  const plusSubscriptionUIDs = plusSubscriptionConditions(user);
  return plusSubscriptionUIDs;
};

export default getPlusSubscriptionsUids;
