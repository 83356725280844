import loadScript from '@utils/loadScript';
import { useFlagSelector } from '@hooks/useLayoutOptions';
import { noOp } from '@constants/empty';
import useAccessControl from '@api/hooks/auth/useAccessControl';
import { getHasFreshdeskEnabled, getMe } from '@api/selectors/auth';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';

const { useRouter } = require('next/router');
const { useEffect } = require('react');

const widgetId = 81000001593;

const callback = (mutation) => {
  const removedNode = mutation?.[0]?.removedNodes?.[0];
  if (removedNode && removedNode.id === 'freshworks-frame-wrapper')
    window.ReactNativeWebView?.postMessage('closeWebView');
};

const handleClose = () => {
  const b = setTimeout(() => {
    const targetNode = document.getElementById('freshworks-container');
    if (targetNode) {
      const x = new MutationObserver(callback);
      clearTimeout(b);
      x.observe(targetNode, {
        attributes: true,
        childList: true,
        subtree: true
      });
    } else handleClose();
  }, 100);
};

const useFreshTicket = () => {
  const router = useRouter();
  const meData = useMyInfo(getMe);
  const { accessControl } = useAccessControl();
  const freshdeskEnabled = getHasFreshdeskEnabled(accessControl);
  const selectedGoalUID = useSelectedGoalUID();
  const { route } = router;
  const { inside_app } = router.query;
  const hideIntercom = useFlagSelector('hideIntercom');
  const hideFreshbot = useFlagSelector('hideFreshbot');
  const hideFreshTicket = hideIntercom || hideFreshbot;
  useEffect(() => {
    if (hideFreshTicket) window?.FreshworksWidget?.('hide');
    else if (
      !window.FreshworksWidget &&
      !inside_app &&
      (freshdeskEnabled || route === '/educator/support')
    ) {
      window.fwSettings = {
        widget_id: widgetId
      };
      const init = () => {
        if (typeof window.FreshworksWidget !== 'function') {
          // eslint-disable-next-line no-inner-declarations
          function n() {
            // eslint-disable-next-line prefer-rest-params
            n.q.push(arguments);
          }
          n.q = [];
          window.FreshworksWidget = n;
        }
      };
      init();
      if (route === '/educator/support')
        window.FreshworksWidget('open', 'ticketForm');
      if (meData.firstName)
        window.FreshworksWidget('identify', 'ticketForm', {
          name: `${meData.firstName} ${meData.lastName}`,
          email: meData.email,
          custom_fields: {
            cf_educator_phone_number: meData.phone,
            cf_goal_uid: selectedGoalUID || 'NOT SELECTED'
          }
        });
      loadScript(
        `https://ind-widget.freshworks.com/widgets/${widgetId}.js`,
        'fresh-ticket'
      )
        .then(() => {
          if (route === '/educator/support') handleClose();
        })
        .catch(noOp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inside_app, meData.email, route, freshdeskEnabled, hideFreshTicket]);
};

export default useFreshTicket;
