const parseUrlQuery = (query) => {
  const {
    utm_source: source,
    utm_campaign: campaign,
    utm_medium: medium
  } = query;
  return { source, campaign, medium };
};

export default parseUrlQuery;
