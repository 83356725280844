export const getTTUShowData = (state) => state.showData;

export const getHideTTUWidget = (state) => state.hideTTUWidget;

export const getShowTTUCurtain = (state) => state.showTTUCurtain;

export const getTTUIsGoalEnabled = (state) =>
  getTTUShowData(state)?.isGoalEnabled;

export const getIsRepeatUser = (state) => getTTUShowData(state)?.isRepeatUser;

export const getTTUFlag = (state) =>
  !!(getTTUIsGoalEnabled(state) && getTTUShowData(state)?.state === 0);

export const getTTUShow = (state) => !!getTTUShowData(state)?.showTTU;

export const getTTUSource = (state) => getTTUShowData(state)?.TTUSource;
