import create from 'zustand/vanilla';
import createHook from 'zustand';

export const ttuStore = create((set) => ({
  showData: null,
  setShowData: (showData = null) =>
    set((state) => ({ showData: { ...state.showData, ...showData } })),
  hideTTUWidget: (showState) =>
    set((state) => ({ showData: { ...state.showData, state: showState } })),
  showTTUCurtain: (show, source) =>
    set((state) => ({
      showData: { ...state.showData, showTTU: show, TTUSource: source }
    }))
}));

const useTTUStore = createHook(ttuStore);

export default useTTUStore;
