import { getHost, HOST_TYPE } from '@utils/api-helper/host';
import isEmptyObject from '@utils/isEmptyObject';

// Retrieve query params from encoded url
const getParams = (params, url) => {
  try {
    const urlObject = new URL(url);

    // append the same query param with different values, eg: ?query=1&query=2
    Object.keys(params).forEach((key) => {
      if (Array.isArray(params[key])) {
        params[key].forEach((listKey) => {
          urlObject.searchParams.append(key, listKey);
        });
        return;
      }
      urlObject.searchParams.append(key, params[key]);
    });
    return urlObject.search;
  } catch (_) {
    return '';
  }
};

// Retrieve path variables from encoded url: using `{}` pattern to replace keyword in url with value
const getPath = (endpoint = '', query = {}) =>
  Object.keys(query).reduce(
    (relPath, key) => relPath.replace(new RegExp(`{${key}}`), `${query[key]}`),
    endpoint
  );

const getUrl = ({
  endpoint,
  hostType = HOST_TYPE.DEFAULT,
  params = {},
  pathVars = {},
  nextPreviewMode
}) => {
  // TODO: Need to find a better way to check for absolute url rather than using try/catch
  try {
    const absoluteUrl = new URL(endpoint);
    return absoluteUrl.href;
  } catch (_) {
    const host = getHost(hostType);
    let url = `${host}${endpoint}`;

    if (!isEmptyObject(pathVars)) {
      url = `${host}${getPath(endpoint, pathVars)}`;
    }

    if (!isEmptyObject(params)) {
      url += getParams(params, url);
    }

    if (hostType === HOST_TYPE.CMS) {
      if (nextPreviewMode) url += '?_publicationState=preview';
    }
    return url;
  }
};

export default getUrl;
