import {
  generateEventsWSD,
  prettyPrintEvents
} from '@cont/Planner/planner-utils/diagnostics/generateWSD';
import { heartbeatTypes } from '@cont/Planner/workers/main/planner.types';
import { PlannerHeartbeatError } from '@utils/customErrors';
import { captureException } from '@utils/sentry';

const enableForDev =
  process.env.isDev ||
  process.env.APP_ENV === 'staging' ||
  process.env.ENVIRONMENT === 'gamma';

const MAX_WAIT_FOR_SYNC = 6 * 1000;

export enum HEARTBEAT_ERROR {
  ON_DEMAND_SYNC_TRIGGER_FAILURE = 'ON_DEMAND_SYNC_TRIGGER_FAILURE',
  SYNC_FAILURE_API_FETCH = 'SYNC_FAILURE_API_FETCH',
  SYNC_FAILURE_DB_STORE = 'SYNC_FAILURE_DB_STORE',
  SYNC_FAILURE_UI_LOAD_FAILURE = 'SYNC_FAILURE_UI_LOAD_FAILURE'
}

interface IHeartbeatError {
  type: HEARTBEAT_ERROR;
}

type HeartbeatEventName = keyof typeof heartbeatTypes;
interface IHeartBeatLifeCycleEvent {
  eventName: HeartbeatEventName;
  timestamp: number;
  data: any;
}

export class PlannerHeartbeat {
  #isEnabled = false;

  #hasTriggeredOnDemandSync = false;

  #hasFetchedAPIUpdates = false;

  #hasStoredUpdatesInDB = false;

  #heartbeatErrors: IHeartbeatError[] = [];

  #lifecycleEvents: IHeartBeatLifeCycleEvent[] = [];

  constructor() {
    this.disableHeartbeat();
    if (enableForDev) {
      global.printEventFlow = (userEvents = []) =>
        prettyPrintEvents(userEvents, this.#lifecycleEvents);
      global.printFlowForWSD = (userEvents = []) =>
        generateEventsWSD(userEvents, this.#lifecycleEvents);
    }
  }

  disableHeartbeat() {
    this.#isEnabled = false;
    this.reset();
  }

  enableHeartbeat() {
    this.#isEnabled = true;
    this.reset();
  }

  // eslint-disable-next-line class-methods-use-this
  #safeExecutor(executeFn: any, errorMessage: string) {
    try {
      executeFn();
    } catch (err) {
      captureException(new PlannerHeartbeatError(errorMessage));
    }
  }

  #monitorOnDemandSyncTrigger() {
    this.#safeExecutor(() => {
      this.#hasTriggeredOnDemandSync = false;
      setTimeout(() => {
        if (!this.#hasTriggeredOnDemandSync) {
          this.#heartbeatErrors.push({
            type: HEARTBEAT_ERROR.ON_DEMAND_SYNC_TRIGGER_FAILURE
          });
          this.#hasTriggeredOnDemandSync = false;
        }
      }, MAX_WAIT_FOR_SYNC);
    }, 'Error while monitoring on-demand sync');
  }

  handleEvent(evtName: string, data: any = {}) {
    this.#safeExecutor(() => {
      if (this.#isEnabled) {
        const eventName = evtName as HeartbeatEventName;
        // console.log(eventName);
        this.#lifecycleEvents.push({ eventName, data, timestamp: Date.now() });
        switch (eventName) {
          case heartbeatTypes.ON_DEMAND_SYNC_TRIGGER_START: {
            this.#monitorOnDemandSyncTrigger();
            break;
          }
          case heartbeatTypes.ON_DEMAND_SYNC_TRIGGER_DONE: {
            this.#hasTriggeredOnDemandSync = true;
            break;
          }
          case heartbeatTypes.UPDATES_FETCH_START: {
            this.#hasFetchedAPIUpdates = false;
            setTimeout(() => {
              if (!this.#hasFetchedAPIUpdates) {
                this.#heartbeatErrors.push({
                  type: HEARTBEAT_ERROR.SYNC_FAILURE_API_FETCH
                });
              }
            }, MAX_WAIT_FOR_SYNC);
            break;
          }
          case heartbeatTypes.UPDATES_FETCH_DONE: {
            this.#hasFetchedAPIUpdates = true;
            break;
          }
          case heartbeatTypes.STORE_IN_DB_START: {
            this.#hasStoredUpdatesInDB = false;
            setTimeout(() => {
              if (!this.#hasStoredUpdatesInDB) {
                this.#heartbeatErrors.push({
                  type: HEARTBEAT_ERROR.SYNC_FAILURE_DB_STORE
                });
              }
            }, MAX_WAIT_FOR_SYNC);
            break;
          }
          case heartbeatTypes.STORE_IN_DB_DONE: {
            this.#hasStoredUpdatesInDB = true;
            break;
          }
          default:
            break;
        }
      }
    }, `Error while handling event ${evtName} in heartbeat`);
  }

  reset() {
    this.#hasTriggeredOnDemandSync = false;
    this.#hasFetchedAPIUpdates = true;
    this.#hasStoredUpdatesInDB = true;
    this.#heartbeatErrors = [];
    this.#lifecycleEvents = [];
  }

  getHeartbeatErrors() {
    return this.#heartbeatErrors;
  }

  getRecentLifecycleEvents(count = 25) {
    return this.#lifecycleEvents.slice(0, count);
  }
}

let heartbeatInstance: PlannerHeartbeat = null;
export const getHeartbeat = () => {
  try {
    if (!heartbeatInstance) {
      heartbeatInstance = new PlannerHeartbeat();
    }
  } catch (err) {
    captureException(new Error('Error while getting heartbeat instance'));
  }
  return heartbeatInstance;
};
