import { isCentreSubscription, subscriptionTypes } from '@constants/index';

/**
 *
 * @param {*} subscription
 * @returns true if subscription is equal to offline type
 */
const isOfflineSubscription = (subscription) => {
  return (
    subscription?.subscription?.type === subscriptionTypes.CENTRE ||
    isCentreSubscription(
      subscription?.subscription?.type,
      subscription?.contentTypeId ?? subscription?.content_type_id
    )
  );
};

export default isOfflineSubscription;
