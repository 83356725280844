import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { captureException } from '@utils/sentry';
import { segment } from '@actions/analytics';

import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn } from '@api/selectors/auth';
import { WEB_PAGE_VIEW_EVENT } from '@constants/segment';
import getWebPageViewEventProperties from '@events/getWebPageViewEventProperties';
import cookies from '@utils/cookies';

// anonymous token
const anonymousToken = process.env.ANONYMOUS_EVENTS_TOKEN;
const eventsUrl = process.env.EVENTS_URL;

export const sendWebPageViewEventToEventServer = async ({
  eventName,
  payload
}) => {
  const token = cookies.readCookie('jwtToken') ?? anonymousToken;
  if (!eventsUrl || !token) return;
  try {
    if (eventName)
      return await fetch(eventsUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json;charset=UTF-8',
          'X-Platfrom': window.isMobile ? 7 : 0
        }
      });
    throw new Error(
      `Analytics issue. no name ${window.location.hostname} ${JSON.stringify(
        payload
      )}`
    );
  } catch (error) {
    captureException(error, {}, 400);
  }
};

const triggerWebPageViewEvent = () => {
  segment.track(WEB_PAGE_VIEW_EVENT, { use_segment_api: true });
  sendWebPageViewEventToEventServer(
    getWebPageViewEventProperties(WEB_PAGE_VIEW_EVENT, {
      use_segment_api: true
    })
  );
};

const useSegmentPageView = () => {
  const { asPath } = useRouter();
  const isUserDataLoaded = useMyInfo(getIsLoggedIn);
  const { isLoading: isUserDataLoading, isValidating: userDataNeedsLoading } =
    useMyInfo();

  const isUserLoggedIn = !isUserDataLoading && isUserDataLoaded;
  const isUserLoggedOut = !userDataNeedsLoading && !isUserDataLoaded;

  useEffect(() => {
    if (isUserLoggedIn || isUserLoggedOut) triggerWebPageViewEvent();
  }, [asPath, isUserLoggedOut, isUserLoggedIn]);
};

export default useSegmentPageView;
