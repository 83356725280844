import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import themeConfig from '@themes/themeConfig';
import themeIndependentColors from '@themes/themeIndependentColors';
import SEO from '@seo/index';
import {
  ICONS_GOAL_UID,
  ICONS_THEME_ID
} from '@constants/unacademyIcons/icons.constants';
import { usePageSeoContent } from '@stores/AppCommonData';

export default function ThemeHeader() {
  const { asPath } = useRouter();
  const { title, description } = usePageSeoContent();
  const isUaIconMode = useMemo(() => {
    return (
      (asPath.includes(ICONS_GOAL_UID) || asPath.includes('/icons/')) &&
      !asPath.includes('/subscribe') &&
      !asPath.includes('/response') &&
      !asPath.includes('/thank-you')
    );
  }, [asPath]);

  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);

  const themeColor = useMemo(() => {
    if (isUaIconMode) return ICONS_THEME_ID;
    return isDarkModeOn ? 'dark' : 'light';
  }, [isDarkModeOn, isUaIconMode]);

  return (
    <>
      <SEO
        title={title}
        description={description}
        openGraph={{ title, description }}
        url={`${process.env.DOMAIN}${asPath}`}
      />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <style>{`:root{${themeConfig[themeColor]};${themeIndependentColors}}`}</style>
      </Head>
    </>
  );
}
