import { eraseCookie } from '@utils/cookies';
import { DEVICE_ID_COOKIE_NAME } from './session/validateAndSetSessionId';

function clearUserRelatedCookies(
  options = {
    keepSelectedGoal: false,
    keepDarkModePreference: false,
    keepReferralInfo: false
  }
) {
  eraseCookie('accessToken');
  eraseCookie('refreshToken');
  eraseCookie('jwtToken');
  eraseCookie('un_session_id');
  // eraseCookie('proxy-user-uid');
  eraseCookie('searchToken');
  eraseCookie(DEVICE_ID_COOKIE_NAME);
  if (!options.keepSelectedGoal) eraseCookie('selectedGoalUid');
  if (!options.keepDarkModePreference) eraseCookie('isDarkModeOn');
  if (!options.keepReferralInfo) {
    eraseCookie('referral-player');
    eraseCookie('referral-banners');
  }
}

export default clearUserRelatedCookies;
