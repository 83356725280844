export const GOAL_UID = 'goal_uid';
export const GOAL_NAME = 'goal_name';
export const IS_SUBSCRIPTION_ACTIVE = 'is_subscription_active';
export const SUBSCRIPTION_DURATION = 'subscription_duration';
export const SUBSCRIPTION_END_DATE = 'subscription_end_date';
export const LANGUAGE = 'language';
export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const NAME = 'name';
export const EDUCATOR_UID = 'educator_uid';
export const EDUCATOR_USERNAME = 'educator_username';
export const BATCH_UID = 'batch_uid';
export const BATCH_TITLE = 'batch_title';
export const TEST_SERIES_UID = 'test_series_uid';
export const TEST_SERIES_TITLE = 'test_series_title';
export const COURSE_UID = 'course_uid';
export const COURSE_TITLE = 'course_title';
export const IS_ENROLLED = 'is_enrolled';
export const NOT_APPLICABLE = 'NA';
export const LAST_PRIMARY_SOURCE_SECTION = 'last_primary_source_section';
export const SUBSCRIPTION_TYPE = 'user_subscription_plan';
export const IS_STORE_EXCLUSIVE = 'is_store_exclusive';
export const EDUCATOR_TYPE = 'educator_type';
export const BATCH_TYPE = 'batch_type';
export const CURRENT_PAGE_URL = 'current_page_url';
export const ADDITIONAL_FEATURES = 'additional_features';
export const IS_SELLABLE = 'is_sellable';

// Catalog - Doubt Browse
export const BOOK_TITLE = 'book_title';
export const EXAM_NAME = 'exam_name';
export const CHAPTER = 'chapter';
export const PAPER_TITLE = 'question_paper_title';

// experiment data props
export const EXPERIMENT_ID = 'experiment_id';
export const EXPERIMENT_NAME = 'experiment_name';
export const VARIATION_NAME = 'variation_name';
export const CURTAIN_TYPE = 'curtain_type';
