import useFetch from '@hooks/useFetch';
import { useRouter } from 'next/router';
import { readCookie } from '@utils/cookies';
import { getIsLoggedIn } from '@api/selectors/auth';
import useMyInfo from './useMyInfo';

/**
 * @param { Object } options An object with 'goalUID' and 'shouldFetch' as Boolean. If shouldFetch is false, we don't fetch access control.
 * @returns { Object } An object with the response and function to refetch access control data
 */
const useAccessControl = (options) => {
  let { goalUID, shouldFetch } = options || {};
  const { query, pathname } = useRouter();
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const isScholarshipPage = pathname === '/scholarship/[eventKey]';

  if (!goalUID && typeof window !== 'undefined')
    // If no goalUID is passed, default to selectedGoalUid cookie or the goalUID in query params
    goalUID = query.goalUID || readCookie('selectedGoalUid');
  if (typeof shouldFetch === 'undefined')
    shouldFetch = isLoggedIn && !isScholarshipPage;

  const { data: accessControl, mutate: refetchAccessControlData } = useFetch(
    shouldFetch ? `v1/user/me/access_control/?goal_uid=${goalUID}` : null,
    { headers: { 'X-Source': pathname } }
  );
  return { accessControl, refetchAccessControlData };
};

export default useAccessControl;
