const selectEvents = (userDataEvents = [], currentLifecycleEvents = []) => {
  let userEvents = [];
  if (userDataEvents && userDataEvents.length > 0) {
    userEvents = [...userDataEvents];
  } else {
    userEvents = [...currentLifecycleEvents];
  }
  return userEvents.map((evt) => ({
    ...evt,
    eventName: evt.eventName.replace('PH_', '')
  }));
};

const consolidateEvents = (targetEvents = []) => {
  let currIdx = 0;
  const consolidatedEvents = [];
  while (currIdx < targetEvents.length) {
    const currEvt = targetEvents[currIdx];
    if (currIdx !== targetEvents.length - 1) {
      const nextEvt = targetEvents[currIdx + 1];
      const [currPrefix, currSuffix] = currEvt.eventName.split('-');
      const [nextPrefix, nextSuffix] = nextEvt.eventName.split('-');
      if (
        currPrefix === nextPrefix &&
        currSuffix === 'START' &&
        nextSuffix === 'DONE'
      ) {
        consolidatedEvents.push({
          eventName: currPrefix,
          timestamp: currEvt.timestamp,
          data: { ...currEvt.data, ...nextEvt.data }
        });
        currIdx += 2;
      } else {
        consolidatedEvents.push(currEvt);
        currIdx += 1;
      }
    } else {
      consolidatedEvents.push(currEvt);
      currIdx += 1;
    }
  }
  return consolidatedEvents;
};

const startStyle = 'border: 1px solid red;';
const endStyle = 'border: 1px solid green;';
export function prettyPrintEvents(
  userDataEvents = [],
  currentLifecycleEvents = []
) {
  let userEvents = selectEvents(userDataEvents, currentLifecycleEvents);
  userEvents = consolidateEvents(userEvents);
  const evtNames = [];
  const evtStyles = [];
  const firstTimestamp =
    userEvents.length > 0 ? userEvents[0].timestamp : Date.now();
  userEvents.forEach((currEvt, currIdx) => {
    const currEvtName = currEvt.eventName;
    const currEvtStyle = currEvtName.endsWith('-START') ? startStyle : endStyle;
    const timestampDiff =
      currIdx === 0
        ? currEvt.timestamp
        : `+${Math.ceil((currEvt.timestamp - firstTimestamp) / 1000)}s`;
    evtNames.push(`%c ${currEvtName} ${timestampDiff} %c`);
    evtStyles.push(currEvtStyle, 'color: inherit;');
  });
  // eslint-disable-next-line no-console
  console.log(evtNames.join(' ==> '), ...evtStyles);
}

const TEST_HEADER = [
  'title User planner heartbeat',
  '\n',
  'participant planner service as ps',
  '\n',
  'participant planner worker as pw',
  '\n',
  'participant Indexed DB as idb',
  '\n',
  'participant planner lite as pl',
  '\n',
  'participant usePlanner as up',
  '\n',
  '\n'
];

export function generateEventsWSD(
  userDataEvents = [],
  currentLifecycleEvents = []
) {
  let userEvents = selectEvents(userDataEvents, currentLifecycleEvents);
  const firstTimestamp =
    userEvents.length > 0 ? userEvents[0].timestamp : Date.now();
  userEvents = consolidateEvents(userEvents);
  const WSDLines = [...TEST_HEADER];
  let openedOpt = false;
  const startOpt = (timeDiff = 0) => {
    WSDLines.push(`opt ${timeDiff}s`);
    WSDLines.push('\n');
    openedOpt = true;
  };
  const endOpt = () => {
    if (openedOpt) {
      WSDLines.push('end opt');
      WSDLines.push('\n');
      openedOpt = false;
    }
  };
  const basicPrint = (actorString: string, evtName: string) => {
    WSDLines.push(`${actorString}: ${evtName}`);
    WSDLines.push('\n');
  };
  const getArrow = (evtName: string) =>
    evtName.endsWith('-DONE') ? '->' : '<->';

  userEvents.forEach((evt) => {
    const timeDiff = Math.ceil((evt.timestamp - firstTimestamp) / 1000);
    const evtDisplayName = `${evt.eventName} (+${timeDiff}s)`;
    switch (evt.eventName) {
      case 'UPDATES_FETCH-START':
        startOpt(timeDiff);
        basicPrint('pw->ps', evtDisplayName);
        break;
      case 'UPDATES_FETCH-DONE':
      case 'UPDATES_FETCH': {
        if (!openedOpt) startOpt(timeDiff);
        WSDLines.push(`pw${getArrow(evt.eventName)}ps: ${evtDisplayName} `);
        WSDLines.push('\n');
        const fetchedUpdatesCount = +(evt.data.fetchedUpdatesCount ?? 0);
        if (fetchedUpdatesCount > 0) {
          WSDLines.push('note left of pw ');
          WSDLines.push('\n');
          WSDLines.push(`Fetched ${fetchedUpdatesCount} item updates`);
          WSDLines.push('\n');
          WSDLines.push('end note');
          WSDLines.push('\n');
        }
        break;
      }
      case 'STORE_IN_DB-START':
        basicPrint('pw->idb', evtDisplayName);
        break;
      case 'STORE_IN_DB':
      case 'STORE_IN_DB-DONE':
        basicPrint(`idb${getArrow(evt.eventName)}pw`, evtDisplayName);
        endOpt();
        break;
      case 'ON_DEMAND_SYNC_TRIGGER-START':
        basicPrint('pl->pl', evtDisplayName);
        break;
      case 'ON_DEMAND_SYNC_TRIGGER':
      case 'ON_DEMAND_SYNC_TRIGGER-DONE':
        basicPrint(`pl${getArrow(evt.eventName)}pw`, evtDisplayName);
        break;
      case 'SCHEDULER_SYNC_TRIGGER':
        basicPrint('pw->pw', evtDisplayName);
        break;
      case 'FOCUS_CHANGE_SCHEDULER_STOP':
      case 'FOCUS_CHANGE_SCHEDULER_START':
        basicPrint('up->pw', evtDisplayName);
        break;
      default:
        break;
    }
  });
  endOpt();
  // eslint-disable-next-line no-console
  console.log(...WSDLines);
}
