import trunc from 'lodash/truncate';

export const getIndefiniteArticle = (word) => {
  const firstLetter = (word?.length > 0 ? word.charAt(0) : '').toLowerCase();
  return ['a', 'e', 'i', 'o', 'u'].includes(firstLetter) ? 'an' : 'a';
};

export const currencyCode = {
  USD: '$',
  BRL: 'R$',
  INR: '₹'
};

export const nFormatter = (num, digits) => {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' }
  ];
  let i;
  for (i = 0; i < si.length; i += 1) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  if (num) return num.toString();
  if (num === 0) return '0';
  return num;
};

export const pluralize = (
  count,
  text,
  pluralString = 's',
  removeCount = false
) => {
  return `${removeCount ? '' : `${nFormatter(count, 1)} `}${
    count === 1 ? text : `${text}${pluralString}`
  }`;
};

export const getStringWordsJoinedWith = (string, joinChar = '_') => {
  if (string) {
    const formattedString = string.replace(/\//g, ' ');
    return formattedString.toLowerCase().split(' ').join(joinChar);
  }
  return string;
};

export const commas = (n) => {
  if (n < 1000) return String(n);
  let whole = Math.floor(n);
  const fraction = n - whole;
  whole = String(whole).split('');
  let i = whole.length;
  while (i > 3) whole.splice((i -= 3), 0, ',');
  return whole.join('') + String(fraction).slice(1);
};

export const commasIndianFormat = (n, floor) => {
  if (n < 1000 && floor) return Math.floor(n);
  if (n < 1000) return String(n);
  let whole = Math.floor(n);
  whole = String(whole).split('');
  let i = whole.length;
  const lastThree = whole.splice((i -= 3), 3);
  while (i > 2) whole.splice((i -= 2), 0, ',');
  return `${whole.join('')},${lastThree.join('')}`;
};

const getHigherSubscription = (n) => {
  if (n < 3) return 3;
  if (n < 6) return 6;
  if (n < 12) return 12;
  if (n < 24) return 24;
};

export const getParameterByName = (name, url) => {
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return undefined;
  if (results[2] === undefined) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getIntroVideoURL = (url) => {
  const regexYoutube = /^(?:http(?:s)?:\/\/)?(?:[^.]+\.)?youtube\.com(\/.*)?$/;
  if (regexYoutube.test(url)) {
    const vParam = `${getParameterByName(
      'v',
      url
    )}?modestbranding=0&rel=0&autohide=1&showinfo=0&autoplay=1&mute=0`;
    return `https://www.youtube.com/embed/${vParam}`;
  }
  return url;
};

export const currencySymbolMap = (code = 'INR') => currencyCode[code];

export const toTitleCase = (phrase) =>
  phrase
    ? phrase
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';

export const capitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};

export const textTruncate = (text, length, readMore = false) =>
  trunc(text, { length, omission: readMore ? '... Read more' : '...' });

/*
  getCommaSeparatedUIDs(objects, key, join)
  if objects - is array of objects -> returns a  comma separated string
    (or based on join arguement) object with key arguement(default uid)

   if object then return the value with key prop.
*/
export const getCommaSeparatedUIDs = (objects, key = 'uid', join = ',') => {
  if (Array.isArray(objects))
    return objects.map((object) => object?.[key]).join(join);
  return objects?.[key];
};

export const wordsToSentence = (
  objects,
  key,
  separator = ', ',
  lastSeparator = ' & '
) => {
  if (!Array.isArray(objects)) return '';
  const sentence = getCommaSeparatedUIDs(objects, key, separator);
  const lastIndexOfSeparator = sentence.lastIndexOf(separator);
  return lastIndexOfSeparator > -1
    ? `${sentence.slice(
        0,
        lastIndexOfSeparator
      )}${lastSeparator}${sentence.slice(
        lastIndexOfSeparator + separator.length
      )}`
    : sentence;
};

const replaceUrlsWithAnchorTags = (text = '', target = '_blank') => {
  if (typeof text !== 'string') return '';
  const mdLinkRegex = /\[([^[]+)\](\((https?:\/\/[^\s]+)\))/gm;
  const mdUrls = text.match(mdLinkRegex) || [];
  const singleMdUrlMatch = /\[([^[]+)\]\(((https?:\/\/[^\s]+))\)/;
  const [actualText, formattedFullText] = mdUrls.reduce(
    (texts, mdUrl) => {
      const mdUrlTextAndLink = singleMdUrlMatch.exec(mdUrl);
      const splitText = texts[0];
      const formattedText = texts[1];
      const index = splitText.indexOf(mdUrl);
      return [
        splitText.substring(index + mdUrl.length),
        `${formattedText}${splitText.substring(
          0,
          index
        )}<a class="link" href="${
          mdUrlTextAndLink[2]
        }" target="${target}" rel="noreferrer">${mdUrlTextAndLink[1]}</a>`
      ];
    },
    [text, '']
  );
  return formattedFullText || actualText;
};

export const replaceAll = (string, search, replace) =>
  string.split(search).join(replace);

export const removeLeadingZero = (value) => {
  const strVal = (value || '').toString();
  return strVal.charAt(0) === '0' ? strVal.slice(1) : strVal;
};

export const getTimeForDate = (time, date) => {
  if (!time || !date) return 0;
  const timeSplit = time.split(':');
  const hours = parseInt(removeLeadingZero(timeSplit[0]), 10);
  const minutes = parseInt(removeLeadingZero(timeSplit[1]), 10);
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate.getTime();
};

export const replaceRegisterSymbol = (str = '', replacement) => {
  const strArray = str.match(/[^®]+|®/g) || [];
  if (strArray.length === 1) return str;
  return strArray.map((string) => {
    return string === '®' ? replacement : string;
  });
};

const daysToMonth = (days) => Math.floor(days / 30);

export const getDurationText = (subsDuration) => {
  if (subsDuration >= 30) {
    const month = daysToMonth(subsDuration);
    return `${month} ${month > 1 ? 'months' : 'month'}`;
  }
  if (subsDuration < 30) {
    return `${subsDuration} days`;
  }
};

export const getRTMPUrlFromClassId = (classId) => {
  return `rtmp://ovenmedia-${classId.toLowerCase()}.unacademy.com/app/${classId.toLowerCase()}`;
};

const textHelper = {
  getIndefiniteArticle,
  nFormatter,
  pluralize,
  getStringWordsJoinedWith,
  getIntroVideoURL,
  commas,
  commasIndianFormat,
  currencySymbolMap,
  toTitleCase,
  textTruncate,
  getHigherSubscription,
  getParameterByName,
  getCommaSeparatedUIDs,
  replaceUrlsWithAnchorTags,
  wordsToSentence,
  removeLeadingZero,
  getTimeForDate,
  replaceRegisterSymbol,
  getDurationText,
  getRTMPUrlFromClassId
};

export default textHelper;
