import { useCommonData } from '@stores/AppCommonData';
import { getSelectedGoalUid } from '@stores/selectors/common';
import { readCookie, setCookie } from '@utils/cookies';
import useTopology from './useTopology';

export const useSelectedGoalUID = () => {
  const selectedGoalUidFromStore = useCommonData(getSelectedGoalUid);
  return readCookie('selectedGoalUid') || selectedGoalUidFromStore;
};

const useSelectedGoal = () => {
  const selectedGoalUid = useSelectedGoalUID();
  const { topology: selectedGoal, ...rest } = useTopology(selectedGoalUid);
  return { selectedGoal, ...rest };
};

export const useUpdateSelectedGoal = () => {
  const setSelectedGoal = useCommonData((state) => state.setSelectedGoalUid);
  return (uid) => {
    setCookie('selectedGoalUid', uid);
    setSelectedGoal(uid);
  };
};

export default useSelectedGoal;
