// me data selectors
export const getIsLoggedIn = (meData) => !!meData || false;

export const getMe = (meData) => meData || {};

export const getStargateJwtToken = (meData) => getMe(meData).stargateJwtToken;

export const getJWTToken = (meData) => getMe(meData).jwtToken;

export const getEmailId = (meData) => getMe(meData).email;

export const getMeUid = (meData) => getMe(meData).uid;

export const getMeUserId = (meData) => getMe(meData).userID;

export const getFirstName = (meData) => getMe(meData).firstName || '';

export const getLastName = (meData) => getMe(meData).lastName || '';

export const getFullName = (meData) => getMe(meData).name;

export const getThumbnail = (meData) => getMe(meData).thumbnail;

export const getMeUsername = (meData) => getMe(meData).username;

export const getMeCountry = (meData) => getMe(meData).country;

export const getCanAddSpecialClass = (meData) =>
  getMe(meData).canAddSpecialClass;

export const getCanAddPlusCourse = (meData) => getMe(meData).canAddPlusCourse;

export const getMePhone = (meData) => getMe(meData).phone ?? '';

export const getIsEducator = (state) => getMe(state).isEducator;

export const getIsTaEducator = (state) => getMe(state).isTaEducator;

export const getIsModerator = (state) => getMe(state).isModerator;

export const getIsInfluencer = (state) => getMe(state).isInfluencer;

export const getMeRelativeLink = (state) => getMe(state).relativeLink;

export const getIsVendor = (state) => getMe(state).isVendor;

export const getMeCredits = (state) => getMe(state).credits ?? 0;

export const getMeCurrentEpoch = (meData) => getMe(meData).currentEpoch;

export const getModerator = (meData) => getMe(meData).isModerator ?? false;

export const getQuestionEditor = (meData) =>
  getMe(meData).isQuestionEditor ?? false;

export const getExternalContentCreator = (meData) =>
  getMe(meData).isExternalContentCreator ?? false;

export const getShowClassPracticeTab = (meData) =>
  getMe(meData).showClassPracticeTab ?? false;

export const getContentAuditor = (meData) =>
  getMe(meData).isContentAuditor ?? false;

export const getJwtToken = (meData) => getMe(meData).jwtToken;

export const getKYCDetails = (meData) => getMe(meData).kycDetails || {};

export const getChannelUID = (meData) => getMe(meData).channels?.[0]?.uid ?? '';

export const getSearchToken = (meData) => getMe(meData).searchJwtToken;

export const getIsPlatformUnlocked = (meData) =>
  getMe(meData).platformUnlockStatus?.isPlatformUnlocked ?? false;

export const getPlatformUnlockStatus = (meData) =>
  getMe(meData).platformUnlockStatus ?? false;

// access control data selectors
export const getExternalContentUploader = (accessControl = {}) =>
  accessControl?.isExternalContentUploader || false;

export const getIsContentEditor = (accessControl = {}) =>
  accessControl?.isContentEditor || false;

export const getIsContentOperator = (accessControl = {}) =>
  accessControl?.isContentOperator || false;

export const getHasFreshdeskEnabled = (accessControl = {}) =>
  accessControl?.hasFreshdeskEnabled || false;

export const getIsTeamViewerEnabled = (accessControl = {}) =>
  accessControl?.isTeamViewerEnabled || false;

export const getIsEvaluator = (accessControl = {}) =>
  accessControl?.isEvaluator || false;

export const getIsReviewer = (accessControl = {}) =>
  accessControl?.isReviewer || false;

export const getIsFirstContest = (accessControl = {}) =>
  accessControl?.isFirstContest || false;

export const getIsDoubtTopologyPresent = (accessControl = {}) =>
  accessControl?.isDoubtTopologyPresent || false;

export const getIsDoubtExternalReviewer = (accessControl = {}) =>
  accessControl?.doubtExternalReviewer || false;

export const getIsLiveInteractionEnabled = (accessControl = {}) =>
  accessControl?.isLiveInteractionEnabled || false;

export const getIconicOnboardingStatus = (accessControl = {}) =>
  accessControl?.iconicOnboardingStatus;

export const getUserOnboardingStatus = (accessControl = {}) =>
  accessControl?.userOnboardingStatus;

export const getEditAllowedForAvatar = (accessControl) =>
  accessControl?.piiEditAllowed?.profilePicture ?? false;

export const getEditAllowedForName = (accessControl) =>
  accessControl?.piiEditAllowed?.name ?? false;

export const getEditAllowedForPhone = (accessControl) =>
  accessControl?.piiEditAllowed?.phoneNumber ?? false;

export const getUserOnboardingPreference = (accessControl) =>
  accessControl?.onboardingPrefList?.find(
    (preference) => preference.prefType === 1
  );

export const getIsPartner = (meData, accessControl) =>
  !getIsEducator(meData) &&
  (getIsDoubtTopologyPresent(accessControl) ||
    getIsReviewer(accessControl) ||
    getIsEvaluator(accessControl) ||
    getExternalContentCreator(meData) ||
    getIsLiveInteractionEnabled(accessControl) ||
    getIsVendor(meData));

export const getCheckPartPaymentStatus = (accessControl) =>
  accessControl?.checkPartPaymentStatus;

export const getIsFreeUser = (goalUID) => (meData) => {
  const user = getMe(meData);
  return !user.latestUserSubscriptionsTypeWise?.find((subscription) => {
    return (
      subscription.subscription.value?.uid === goalUID &&
      subscription.timeRemaining !== 0
    );
  });
};
