import { Spacings } from '@styles/index';

const boxShadowGenerator = ({
  xOffset = Spacings.SPACING_0B,
  yOffset = Spacings.SPACING_4B,
  blur = Spacings.SPACING_8B,
  spread = Spacings.SPACING_0B,
  color = ''
} = {}) => {
  const shadowColor = color || 'var(--color-shadow)';
  return `${xOffset} ${yOffset} ${blur} ${spread} ${shadowColor}`;
};

export default boxShadowGenerator;
