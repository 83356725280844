/* eslint-disable import/no-cycle */
import camelcaseKeys from 'camelcase-keys';
import { HOST_TYPE, getHost } from '@utils/api-helper/host';
import simpleFetch from '@api/fetchers/simpleFetcher';
import config from '@api/fetchers/config';

const defaultHost = getHost();
const backendHost = getHost(HOST_TYPE.BACKEND_API_HOST);

export const fetchNewToken = (refreshToken) => {
  const url = `${defaultHost}v1/oauth2/token/`;
  return fetch(url, {
    method: 'POST',
    body: new URLSearchParams(
      `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${config.apiClientId}&client_secret=${config.apiClientSecret}`
    ),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const fetchMyInfo = (authorization, source = '', version = 'v2') => {
  const headers = { authorization: `Bearer ${authorization}` };
  if (source) headers['X-Source'] = source;

  return fetch(`${defaultHost}${version || 'v2'}/user/me/`, { headers })
    .then((res) => {
      if (!res.ok) return {};
      return res.json();
    })
    .then((res) => {
      return camelcaseKeys(res, { deep: true });
    })
    .catch(() => {});
};

// lite api to get required information related to me api, we can pass fileds parameter to get only the response which is needed
// Example:  https://api-rankers.gamma.unacademydev.com/v2/user/me/lite/?fields=uid,latest_user_subscriptions_type_wise
export const fetchMyInfoLite = (authorization, fields = null) =>
  simpleFetch(
    `${defaultHost}v2/user/me/lite/${fields ? `?fields=${fields}` : ''}`,
    { authorization }
  ).catch(() => {});

export const fetchPurchasedItems = (authorization) =>
  simpleFetch(`${backendHost}v1/user/purchased_items/`, {
    authorization
  }).catch(() => {});

export const fetchAccessControl = (goalUID, authorization, source = '') => {
  const headers = { authorization: `Bearer ${authorization}` };
  if (source) headers['X-Source'] = source;
  return simpleFetch(
    `${defaultHost}v1/user/me/access_control/?goal_uid=${goalUID}`,
    { headers }
  );
};

export const fetchCashPaymentStatus = (goalUID, authorization) =>
  simpleFetch(
    `${defaultHost}v1/subscriptions/cash_payment_subscription_details/?goal_uid=${goalUID}`,
    { authorization }
  );
