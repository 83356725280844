/* eslint-disable import/prefer-default-export */
import { getStringWordsJoinedWith } from '@utils/textHelper';

export const processTopologyData = (data) => {
  if (!data?.uid) {
    return {};
  }

  return {
    ...data,
    processed: true,
    goalSlug: data.slug,
    coursesCount:
      data.coursesCount ||
      data.itemCount ||
      data.count ||
      data.plusCourseCount ||
      data.courseCount ||
      0,
    upcomingCoursesCount: data.upcomingCount || 0,
    upcomingCoursesThisMonth: data.upcomingThisMonth || 0,
    enrolledCoursesCount: data.enrolledCount || 0,
    totalSubItem: data.totalSubItem || 0,
    progress: data.progress || 0,
    plusCourseCount: data.plusCourseCount || data.totalCount || 0,
    fullName: data.title,
    slug: data.slug || getStringWordsJoinedWith(data.name, '-'),
    isPracticeAvailable: data.isPracticeSession,
    language: data.language || null,
    ogImage: data.ogImage || null,
    isNoTestGoal: !!data.tags?.find((tag) => tag?.name === 'NON_TEST_PREP'),
    externalApps: data.externalAppsAvailable,
    isPlatformGoal: data.isPlatformGoal || false,
    goalLevelOnboarding: {
      isAvailable: data.isGoalOnboardingAvailable || false,
      status: data.userOnboardingStatus || null
    },
    isK12: data.isK12Goal || false,
    isUaIconGoal: data.isUaIconGoal || false
  };
};
