import { Platform, LearnerId, LearnerUserName } from '@events/eventProps';
import { getDaysRemainingInSubscription } from '@models/Me';
import loadModule from './loadModule';

const fullStoryId = process.env.FULLSTORY_ID;

function loadFullStory({ me, selectedGoal = {}, isMobileDevice }) {
  if (me.uid && me.isFullStoryUser && fullStoryId && !isMobileDevice) {
    loadModule(import('@fullstory/browser')).then((fs) => {
      fs.init({ orgId: fullStoryId });
      fs.identify(me.uid, {
        [Platform]: 'Desktop',
        [LearnerId]: me.uid,
        [LearnerUserName]: me.username,
        email: me.email,
        selectedGoal: selectedGoal.uid,
        selectedGoalName: selectedGoal.name,
        subscriptionDuration: getDaysRemainingInSubscription(
          me.plusSubscriptions,
          selectedGoal.uid
        )
      });
    });
  }
}

export default loadFullStory;
