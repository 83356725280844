import { getIsLoggedIn, getStargateJwtToken } from '@api/selectors/auth';
import {
  getIsStargateEnabledForGoal,
  getSocketConstants
} from '@api/selectors/constants';
import SocketManager, {
  ISocketManager
} from '@utils/SocketManager/SocketManager';
import { useState, useEffect } from 'react';
import useGoalEventProps from '@hooks/useGoalEventProps';
import { getIsSubscribed } from '@api/selectors/subscription';
import { useRouter } from 'next/router';
import { captureException } from '@utils/sentry';
import { SocketRetryError } from '@utils/customErrors';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import useConstants from '@api/hooks/useConstants';

const useSocket = () => {
  const { refetchMyInfo } = useMyInfo();
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const stargateJwtToken = useMyInfo(getStargateJwtToken);
  const socketConfig = useConstants(getSocketConstants);
  const isSubscribed = useMyInfo(getIsSubscribed);
  const selectedGoalUID = useSelectedGoalUID();
  const isStargateEnabled = useConstants(
    getIsStargateEnabledForGoal(selectedGoalUID)
  );
  const goalSegmentData = useGoalEventProps({ goalUID: selectedGoalUID });
  const socketDomain = socketConfig?.socketDomain;

  const [socket, setSocket] = useState<ISocketManager | null>(null);

  const closeSocket = () => {
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      try {
        if (url?.includes('planner') && socket && !socket?.getISWSRunning()) {
          socket.retry();
        }
      } catch (err) {
        captureException(new SocketRetryError());
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router, socket]);

  useEffect(() => {
    closeSocket();
    if (isLoggedIn && isSubscribed && socketConfig && isStargateEnabled) {
      const socketConnection = new SocketManager(
        stargateJwtToken,
        socketConfig,
        goalSegmentData,
        refetchMyInfo
      );

      setSocket(socketConnection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    goalSegmentData,
    isLoggedIn,
    isStargateEnabled,
    isSubscribed,
    socketConfig,
    socketDomain,
    stargateJwtToken
  ]);

  const data = {
    socket
  };

  return data;
};

export default useSocket;
