import Icon from './Icon';

const CloseCircleOutline = (props) => (
  <Icon
    {...props}
    path="M40 72.5c-17.949 0-32.5-14.551-32.5-32.5s14.551-32.5 32.5-32.5c17.949 0 32.5 14.551 32.5 32.5v0c-0.019 17.942-14.558 32.481-32.498 32.5h-0.002zM40 12.5c-15.188 0-27.5 12.312-27.5 27.5s12.312 27.5 27.5 27.5c15.188 0 27.5-12.312 27.5-27.5v0c-0.019-15.18-12.32-27.481-27.498-27.5h-0.002zM43.533 40l7.667-7.667c0.416-0.445 0.671-1.045 0.671-1.704 0-1.381-1.119-2.5-2.5-2.5-0.659 0-1.259 0.255-1.706 0.672l0.001-0.001-7.667 7.667-7.667-7.667c-0.445-0.416-1.045-0.671-1.704-0.671-1.381 0-2.5 1.119-2.5 2.5 0 0.659 0.255 1.259 0.672 1.706l-0.001-0.001 7.667 7.667-7.667 7.667c-0.452 0.452-0.731 1.077-0.731 1.767s0.279 1.314 0.731 1.767v0c0.447 0.453 1.068 0.733 1.755 0.733 0.004 0 0.008 0 0.012 0h-0.001c0.008 0 0.017 0 0.026 0 0.682 0 1.299-0.281 1.741-0.733l7.667-7.667 7.667 7.667c0.442 0.453 1.059 0.733 1.741 0.733 0.009 0 0.018-0 0.027-0h-0.001c0.003 0 0.008 0 0.012 0 0.687 0 1.308-0.28 1.755-0.733l0-0c0.452-0.452 0.731-1.077 0.731-1.767s-0.279-1.314-0.731-1.767v0z"
  />
);

export default CloseCircleOutline;
