/* eslint-disable no-param-reassign */
import { emptyList } from '@constants/empty';
import { isCentreSubscription, subscriptionTypes } from '@constants/index';
import { getRegularDate } from '@utils/dates';
import getIsCurrentSubscription from '@utils/subscriptionHelpers/getIsCurrentSubscription';
import getPlusSubscriptionsUids from '@utils/subscriptionHelpers/getPlusSubscriptionUIDs';
import getActiveUidsByType from '@utils/subscriptionHelpers/getActiveUidsByType';
import getLiteSubscriptionsUids from '@utils/subscriptionHelpers/getLiteSubscriptionUIDs';
import {
  getActiveOfflineSubscriptionUIDs,
  getActiveOfflineSubscriptions,
  getOfflineSubscriptions
} from '@utils/subscriptionHelpers/getOfflineSubscriptionsAndUIDs';

const LiteType = [subscriptionTypes.LITE];
const TrialType = [subscriptionTypes.TRIAL];

export const isFreeUser = (user, goalUID) => {
  return !user.latestUserSubscriptionsTypeWise?.find((subscription) => {
    return subscription.subscription.value?.uid === goalUID;
  });
};

export {
  getPlusSubscriptionsUids,
  getActiveUidsByType,
  getLiteSubscriptionsUids,
  getActiveOfflineSubscriptionUIDs
};

const isNotLiteTypeSubscription = (subscription) =>
  !LiteType.includes(subscription.subscription.type);

const isNotTrialTypeSubscription = (subscription) =>
  !TrialType.includes(subscription.subscription.type);

export const getPlusWithoutTrialSubscriptionsUids = (user) =>
  (
    user?.latestUserSubscriptions || user?.latestUserSubscriptionsTypeWise
  )?.reduce((_arrayIds, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      isNotLiteTypeSubscription(_plusSubscription) &&
      isNotTrialTypeSubscription(_plusSubscription)
    )
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    return _arrayIds;
  }, []) ?? [];

export const getExpiredUidsByType = (user, type) =>
  user?.latestUserSubscriptions?.reduce((_arrayIds, _plusSubscription) => {
    if (
      (_plusSubscription.time_remaining <= 0 ||
        _plusSubscription.timeRemaining <= 0) &&
      (!type || _plusSubscription.subscription.type === type)
    )
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    return _arrayIds;
  }, []) ?? [];

export const getIconicSubscriptionsUids = (user) =>
  getActiveUidsByType(user, subscriptionTypes.ICONIC);

export const getCentreSubscription = (user) => {
  const userSubscriptionTimeline =
    user?.userSubscriptionTimeline ?? user?.latestUserSubscriptionsTimeline;
  if (!userSubscriptionTimeline) return false;
  const hasCentreSubscription = userSubscriptionTimeline.find(
    (subscriptionType) => {
      return isCentreSubscription(
        subscriptionType?.subscription?.type,
        subscriptionType?.contentTypeId ?? subscriptionType?.content_type_id
      );
    }
  );
  return !!hasCentreSubscription;
};

const getExpiredSubscriptionUids = (user) => getExpiredUidsByType(user);

const getExpiredIconicUIDs = (user) =>
  getExpiredUidsByType(user, subscriptionTypes.ICONIC);

export const getPlusSubscriptions = (user) =>
  user?.latestUserSubscriptions?.filter((subscription) => {
    return subscription.subscription.type === subscriptionTypes.PLUS;
  });

const getPIIEditAllowed = (user) => user?.pii_edit_allowed;

const getRelativeLink = (user) => {
  let url = `/learner/@${user.username}`;
  if (user.is_educator || user.is_moderator)
    url = user.relative_link || `/@${user.username}`;
  return url;
};

function meModel(user) {
  const firstName = user.first_name?.toLocaleString() ?? '';
  const lastName = user.last_name ?? '';
  const data = {
    firstName,
    lastName,
    uid: user.uid,
    userID: user.user_id,
    name: `${firstName} ${lastName}`,
    thumbnail: user.avatar || null,
    description: user.bio || null,
    gender: user.gender,
    isFullStoryUser: user.is_full_story_user,
    phone: user.phone,
    email: user.email,
    state: user.state,
    country: user.country,
    language: user.language,
    relativeLink: getRelativeLink(user),
    username: user.username || null,
    isEducator: user.is_educator,
    isVendor: user.is_vendor,
    isVerifiedEducator: user.is_verified_educator,
    isEmailVerified: user.is_email_verified,
    isPhoneVerified: user.is_phone_verified,
    isProfileFilled: user.is_profile_filled,
    isModerator: user.is_moderator,
    isQuestionEditor: user.is_question_editor,
    isContentAuditor: user.is_content_auditor,
    isActive: user.is_active,
    isFreeOfflinePromotionCreditsAvailable:
      user.is_free_offline_promotion_credits_available,
    isSystemUser: user.is_system_user,
    isIntercomEnabled: user.is_intercom_enabled,
    isPlusEnrolled: user.is_plus_enrolled,
    isAnonymous: user.is_anonymous,
    isKycCompleted: user.is_kyc_completed,
    isPlusEducator: user.is_plus_educator,
    socialSharing: user.social_sharing,
    referralInfo: user.referral_info,
    isPasswordSet: user.is_password_set,
    boardingSteps: user.boarding_steps_v3,
    messengerConnected: user.messenger_connected,
    hasAcceptedTNC: user.has_accepted_tnc,
    notificationToken: user.notification_token,
    feedUpdatesDisabled: user.feed_updates_disabled,
    credits: user.credits,
    educatorApprovalVotingState: user.educator_approval_voting_state,
    subscription: user.subscription,
    dateJoined: user.date_joined,
    activityDetails: user.activity_details,
    latestUserSubscriptions: user.latest_user_subscriptions_type_wise,
    latestUserSubscriptionsTimeline:
      user?.user_subscription_timeline ?? emptyList,
    userSubscriptionTimeline: user?.user_subscription_timeline ?? emptyList,
    currentEpoch: user.current_epoch,
    hasAcceptedAggrement: user.has_accepted_aggrement,
    jwtToken: user.jwt_token,
    stargateJwtToken: user.stargate_jwt_token,
    channels: user.channels,
    kycDetails: user.kyc_details,
    videoCallAllowed: user.video_call_allowed,
    videoCallEnabled: user.video_call_enabled,
    videoFlag: user.video_flag,
    chatWithUsFlag: user.chat_with_us_flag,
    canAddSpecialClass: user.can_add_special_class,
    isFirstSpecialClass: user.is_first_special_class,
    searchJwtToken: user.search_jwt_token,
    canAddPlusCourse: user.can_add_plus_course,
    isSchoolEducator: user.is_un_teach_user,
    isExternalContentCreator: user.is_external_content_creator,
    platformUnlockStatus: user.platform_unlock,
    isInvited: user.is_invited || false,
    showClassPracticeTab: user.show_class_practice_tab || false,
    isTaEducator: user.is_ta_educator
  };
  data.plusSubscriptionUids = getPlusSubscriptionsUids(data);
  data.plusWithoutTrialSubscriptionUids =
    getPlusWithoutTrialSubscriptionsUids(data);
  data.iconicSubscriptionUIDs = getIconicSubscriptionsUids(data);
  data.liteSubscriptionUids = getLiteSubscriptionsUids(data);
  data.expiredSubscriptionsUids = getExpiredSubscriptionUids(data);
  data.expiredIconicUIDs = getExpiredIconicUIDs(data);
  data.piiEditAllowed = getPIIEditAllowed(data);
  data.plusSubscriptions = getPlusSubscriptions(data);
  data.offlineSubscriptions = getOfflineSubscriptions(data);

  data.offlineSubscriptionUIDs = getActiveUidsByType(
    data,
    subscriptionTypes.CENTRE
  );
  data.activeOfflineSubscriptions = getActiveOfflineSubscriptions(data);
  data.activeOfflineSubscriptionUIDs = getActiveOfflineSubscriptionUIDs(data);
  data.hasCentreSubscription = getCentreSubscription(data);

  return data;
}

function getEntityData(data) {
  return meModel(data);
}

export const hasActiveSubscriptionToGoal = (data, uid, subscriptionType) => {
  /* this function is used for finding a active subscription to goal
    if we need to find whether its iconic or regular we can send the
    subscription_active param too
  */

  if (!data.uid || !uid) return false;
  const filteredData =
    data.latestUserSubscriptions?.filter(
      (item) =>
        item.subscription?.value.uid === uid && getIsCurrentSubscription(item)
    ) || emptyList;
  if (subscriptionType && filteredData.length) {
    const subscription = filteredData.find(
      (sub) => sub.subscription.type === subscriptionType
    );
    if (subscription) return true;
    return false;
  }

  return Boolean(filteredData?.length);
};

export const hasActiveTrialSubscriptionToGoal = (data, uid) =>
  hasActiveSubscriptionToGoal(data, uid, subscriptionTypes.TRIAL);

export const hasActiveLiteSubscriptionToGoal = (data, uid) =>
  hasActiveSubscriptionToGoal(data, uid, subscriptionTypes.LITE);

export const hasActiveIconicSubscriptionToGoal = (data, uid) =>
  hasActiveSubscriptionToGoal(data, uid, subscriptionTypes.ICONIC);

const hasExpiredSubscriptionToGoal = (data, uid) => {
  /* this function is used for finding a expired subscription to a  goal */

  if (!data.uid || !uid) return false;
  const filteredData = data.expiredSubscriptionsUids.find(
    (item) => item === uid
  );
  return Boolean(filteredData);
};

const hasExpiredIconicSubscriptionToGoal = (data, uid) => {
  /* this function is used for finding a expired subscription to a iconic goal */

  if (!data.uid || !uid) return false;
  const filteredData = data.expiredIconicUIDs.find((item) => item === uid);
  return Boolean(filteredData);
};

export const hasClassAccess = (data, classDetails = {}) => {
  if (data.uid) {
    const programme = classDetails.programme || { goal: {} };
    if (classDetails.isSpecial || programme.isSpecial) return true;
    const goalUID =
      programme.goalUid || classDetails.goalUid || programme.goal.uid;
    return hasActiveSubscriptionToGoal(data, goalUID);
  }
  return false;
};

/* getSubscription(subscriptions,goalUID) -> find subscription of the goal which has goalUID */
export const getSubscription = (subscriptions, goalUID) =>
  subscriptions?.find(
    (data) =>
      data.subscription?.value?.uid === goalUID || data?.goalUid === goalUID
  ) ?? null;

export const getActiveSubscription = (data, goalUid) => {
  if (!goalUid || !Array.isArray(data)) return null;
  const activeSubscription = getSubscription(data, goalUid);
  if (!activeSubscription) return null;
  return (activeSubscription.time_remaining ||
    activeSubscription.timeRemaining) > 0
    ? activeSubscription
    : null;
};

export const getCurrentRunningSubscriptionDetail = (data) => {
  if (data) {
    const currentRunningSubscriptionDetail = data.reduce(
      (current, subscription) => {
        const endDate = Date.parse(subscription?.expiredAt) / 1000;
        const currentDate = Math.floor(Date.now() / 1000);
        const diff = endDate - currentDate;
        if (diff > 0 && (current.diff === 0 ? true : diff < current.diff)) {
          current.diff = diff;
          current.curr = subscription;
        }
        return current;
      },
      { diff: 0, curr: {} }
    );
    return currentRunningSubscriptionDetail.curr;
  }
};

export const getIsAnyBatchActiveSubscription = (data) => {
  const hasAnyActiveSubscription = data.find((subscription) => {
    const endDate = Date.parse(subscription?.expiredAt) / 1000;
    const currentDate = Math.floor(Date.now() / 1000);
    const diff = endDate - currentDate;
    return diff > 0;
  });
  return !!hasAnyActiveSubscription;
};

const getCurrentRunningSubscriptions = (data, goalUid) => {
  const startDate = Date.parse(data?.startedAt) / 1000;
  const endDate = Date.parse(data?.expiredAt) / 1000;
  const currentDate = Math.floor(Date.now() / 1000);

  return (
    (data.subscription?.value?.uid === goalUid || data?.goalUid === goalUid) &&
    startDate <= currentDate &&
    currentDate <= endDate
  );
};
export const getAllActiveSubscriptions = (subscriptions, goalUid) => {
  if (!goalUid || !Array.isArray(subscriptions)) return null;

  return (
    subscriptions?.filter((data) =>
      getCurrentRunningSubscriptions(data, goalUid)
    ) ?? null
  );
};

export const getActiveSubscriptionToAnyGoal = (data) => {
  if (!Array.isArray(data)) return null;
  for (let subscription = 0; subscription <= data.length; subscription += 1) {
    if (
      (data[subscription]?.time_remaining ||
        data[subscription]?.timeRemaining) > 0
    )
      return data[subscription];
  }
  return null;
};

const getExpiryDateFromSubscriptions = (subscriptions, goalUID) => {
  const subscription = subscriptions?.find(
    (data) => data.subscription?.value?.uid === goalUID
  );
  return subscription?.expired_at ?? subscription?.expiredAt ?? null;
};

export const getDaysRemainingInSubscription = (subscriptions, goalUID) => {
  const currentSubscription = subscriptions?.find(
    (subscription) => subscription.subscription?.value?.uid === goalUID
  );
  const timeRemaining =
    currentSubscription?.time_remaining || currentSubscription?.timeRemaining;
  return timeRemaining / (3600 * 24) ?? 0;
};

export const getExpiryDate = (subscription) => subscription?.expired_at ?? null;

/**
 * Get subscription info as "Iconic<Plus> till 12 Aug 2020"
 */
export const getSubscriptionInfo = (me, goalUID) => {
  const isSubscribed = hasActiveSubscriptionToGoal(me, goalUID);
  if (!isSubscribed) return null;
  const expiryDate = getExpiryDateFromSubscriptions(
    me.latestUserSubscriptions || me.latestUserSubscriptionsTypeWise,
    goalUID
  );
  return `Subscription till ${getRegularDate(expiryDate)}`;
};

export const getActivityStatsData = (data) => ({
  enrollmentsCount: data.course_enrollments || 0,
  savedCount: data.saved_items || 0,
  currentStreak: data.current_streak || 0,
  longestStreak: data.longest_streak || 0,
  questionsAttempted: data.questions_attempted || 0,
  watchMinutes: data.watch_minutes || 0,
  questionsAttemptThreshold: data.questions_attempt_threshold || 100,
  watchMinsThreshold: data.watch_mins_threshold || 100
});

export const getPlatformSubscriptionUIDs = (subscriptions) => {
  const platformSubscriptionUID = subscriptions?.reduce(
    (uids, subscription) => {
      const subscriptionTimeLine =
        subscription?.user_subscription_timeline?.[0] ?? {};
      const currentSubscribed = subscriptionTimeLine.time_remaining > 0;
      if (
        currentSubscribed &&
        subscriptionTimeLine.subscription_type === subscriptionTypes.PLATFORM
      ) {
        return [...uids, subscription.uid];
      }
      return uids;
    },
    []
  );
  return platformSubscriptionUID;
};

export const getBatchSubscription = (subscriptions, goalUID) =>
  subscriptions?.find((data) => data.goal?.uid === goalUID) ?? null;

export const getBatchSubscriptionByBatchUID = (subscriptions, batchUID) =>
  subscriptions?.find((data) => data?.uid === batchUID) ?? null;

export const hasActiveSubscriptionToPlatformBatch = (
  subscriptions = [],
  uid
) => {
  if (!subscriptions?.length || !uid) return false;
  const filteredData = subscriptions?.filter(
    (item) =>
      item?.uid === uid &&
      getIsCurrentSubscription(item?.user_subscription_timeline[0] ?? {})
  );
  return Boolean(filteredData?.length);
};
export const hasActivePlatformSubscriptionToGoal = (
  platformSubscriptionGoalUIDs = [],
  goalUID
) => platformSubscriptionGoalUIDs.includes(goalUID);

const me = {
  Me: meModel,
  getEntityData,
  hasClassAccess,
  hasActiveSubscriptionToGoal,
  hasActiveLiteSubscriptionToGoal,
  hasActiveIconicSubscriptionToGoal,
  hasExpiredSubscriptionToGoal,
  hasExpiredIconicSubscriptionToGoal,
  getExpiryDate,
  getSubscription,
  getDaysRemainingInSubscription,
  getSubscriptionInfo,
  getActiveSubscription,
  getAllActiveSubscriptions,
  getActivityStatsData,
  getPlatformSubscriptionUIDs,
  getActiveUidsByType,
  getBatchSubscriptionByBatchUID,
  getActiveSubscriptionToAnyGoal
};

export default me;
