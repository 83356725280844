const messageTypes = {
  RUN_INITIAL_TASKS: 'RUN_INITIAL_TASKS',
  API_ERROR: 'API_ERROR',
  PLANNER_SYNC_DELAY_SEND: 'PLANNER_SYNC_DELAY_SEND',
  PLANNER_SEGMENT_TRACK: 'PLANNER_SEGMENT_TRACK',
  INITIAL_CHECK_COMPLETE: 'INITIAL_CHECK_COMPLETE',
  FETCH_DATA_IN_BACKGROUND: 'FETCH_DATA_IN_BACKGROUND',
  FETCH_DATA_IN_BACKGROUND_IN_PROGRESS: 'FETCH_DATA_IN_BACKGROUND_IN_PROGRESS',
  FETCH_DATA_IN_BACKGROUND_SUCCESS: 'FETCH_DATA_IN_BACKGROUND_SUCCESS',
  SETUP_POLLING: 'SETUP_POLLING',
  POLLING_SETUP_COMPLETE: 'POLLING_SETUP_COMPLETE',
  STOP_POLLING: 'STOP_POLLING',
  FORCE_FETCH_UPDATES: 'FORCE_FETCH_UPDATES',
  UPDATE_THROTTLER: 'UPDATE_THROTTLER',
  FETCH_UPDATES_IN_PROGRESS: 'FETCH_UPDATES_IN_PROGRESS',
  UPDATES_RECEIVED: 'UPDATES_RECEIVED',
  SETUP_DAILY_TASKS: 'SETUP_DAILY_TASKS',
  DAILY_UPDATES_RECEIVED: 'DAILY_UPDATES_RECEIVED',
  FETCH_DATA_IN_BATCHES_SUCCESS: 'FETCH_DATA_IN_BATCHES_SUCCESS'
};

export const heartbeatTypes = {
  FOCUS_CHANGE_SCHEDULER_START: 'PH_FOCUS_CHANGED_START_SCHEDULER',
  FOCUS_CHANGE_SCHEDULER_STOP: 'PH_FOCUS_CHANGED_STOP_SCHEDULER',
  SCHEDULER_SYNC_TRIGGER: 'PH_SCHEDULER_SYNC_TRIGGER',
  UPDATES_FETCH_START: 'PH_UPDATES_FETCH-START',
  UPDATES_FETCH_DONE: 'PH_UPDATES_FETCH-DONE',
  STORE_IN_DB_START: 'PH_STORE_IN_DB-START',
  STORE_IN_DB_DONE: 'PH_STORE_IN_DB-DONE',
  ON_DEMAND_SYNC_TRIGGER_START: 'PH_ON_DEMAND_SYNC_TRIGGER-START',
  ON_DEMAND_SYNC_TRIGGER_DONE: 'PH_ON_DEMAND_SYNC_TRIGGER-DONE'
};

export default messageTypes;
