import { getHost } from '@utils/api-helper/host';

const defaultHost = getHost();

// Add { endpoint, statusCodes: [], regex } to track API errors for endpoints.
const endpointsAndStatusCodes: {
  endpoint?: string;
  statusCodes: number[];
  regex?: RegExp;
}[] = [
  {
    endpoint: `${process.env.UN_DATA_ENDPOINT}/counts`,
    statusCodes: [400, 401]
  },
  {
    regex: new RegExp(`${defaultHost}v1/uplus/classes/([^/]*)/info_v2/`),
    statusCodes: [500]
  },
  {
    endpoint: `${defaultHost}v1/planner/schedule/`,
    statusCodes: [500, 504]
  },
  {
    regex: new RegExp('v2/uplus/programmes/([^/]*)/save/'),
    statusCodes: [500, 504]
  },
  {
    endpoint: '/api/v1/uplus/save/add_to_saved/',
    statusCodes: [500]
  },
  {
    endpoint: '/api/plus_search/search',
    statusCodes: [500]
  },
  {
    endpoint: 'v1/store/sku/filters',
    statusCodes: [500]
  },
  {
    endpoint: `${defaultHost}v1/aggregator/store/feed`,
    statusCodes: [500]
  },
  {
    regex: new RegExp(`${defaultHost}v1/batch/([^/]*)/([^/]*)/`),
    statusCodes: [500]
  },
  {
    endpoint: `${defaultHost}v1/syllabus/playlist/home/feed`,
    statusCodes: [500]
  }
];

export default endpointsAndStatusCodes;
