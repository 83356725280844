import { useEffect } from 'react';
import {
  getMeUid,
  getIsLoggedIn,
  getEmailId,
  getFullName
} from '@api/selectors/auth';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import segmentStorage from '@events/segmentStorage';
import validateAndSetSessionId, {
  DEVICE_ID_COOKIE_NAME
} from '@utils/session/validateAndSetSessionId';
import { readCookie } from '@utils/cookies';

export default function useSegmentUserIdentifier() {
  const uid = useMyInfo(getMeUid);
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const email = useMyInfo(getEmailId);
  const fullName = useMyInfo(getFullName);

  useEffect(() => {
    if (isLoggedIn) {
      if (uid && window.analytics) {
        segmentStorage.setItem('segmentUserIdentified', true);
        validateAndSetSessionId();
        const deviceID = readCookie(DEVICE_ID_COOKIE_NAME);
        window.analytics.identify(uid, {
          userId: uid,
          name: fullName,
          email
        });
        window?.analytics?.setAnonymousId(deviceID);
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    }
  }, [isLoggedIn]);
  return null;
}
