import fetch from '@api/fetchers';

export const USER_LOCATION_API = 'v1/user/location/';
export const GET_REVERSE_GEOCODE = 'https://geocode.maps.co/reverse';

export const fetchReverseGeocodeData = (latitude, longitude) => {
  return fetch(
    `${GET_REVERSE_GEOCODE}?lat=${parseFloat(latitude)}&lon=${parseFloat(
      longitude
    )}`
  );
};

export const postUserLocationData = (body) => {
  fetch(USER_LOCATION_API, {
    requestType: 'POST',
    body
  });
};

export const fetchUserLocationData = () => {
  return fetch(USER_LOCATION_API);
};
