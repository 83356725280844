/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Script from 'next/script';
import StyleProvider from 'aquilla/styled/StyleProvider';
import GlobalStyles from 'aquilla/styled/Global';
import AppContainer from '@cont/App/AppContainer';
import AppCommonDataWrapper from '@stores/AppCommonData';
import SWRProvider from '@cont/App/providers/SWRProvider';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import WebEngageScript from 'scripts/WebEngageScript';
import { GoogleAnalytics } from '@next/third-parties/google';

const UNApp = ({ Component: Page, pageProps = {} }) => {
  const PageWrapper = Page.Wrapper || React.Fragment;
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);
  const styledConfig = React.useMemo(
    () => ({
      mode: isDarkModeOn ? 'dark' : 'light'
    }),
    [isDarkModeOn]
  );

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js');
    }
  }, []);

  return (
    <AppCommonDataWrapper
      isMobile={pageProps.isMobile}
      userAgent={pageProps.userAgent}
      seoContent={pageProps.pageMetaInfo}
      previewMode={pageProps.inPreviewMode}
      selectedGoalUid={pageProps.selectedGoalUid}
      pageInfo={Page.pageInfo}
    >
      <WebEngageScript />
      <SWRProvider pageProps={pageProps}>
        <StyleProvider config={styledConfig}>
          <GlobalStyles />
          <PageWrapper initialReduxState={pageProps.initialReduxState}>
            <AppContainer Page={Page} pageProps={pageProps} />
          </PageWrapper>
        </StyleProvider>
        <Script
          src="/validate-supported-browser.js"
          id="validate-supported-browser-script"
          strategy="lazyOnload"
        />
        {/* Google Tag Manager */}
        {process.env.GTM_ID && (
          <>
            <GoogleAnalytics gaId={process.env.GTM_ID} />
            <Script
              strategy="worker"
              dangerouslySetInnerHTML={{
                __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.GTM_ID}');
          `
              }}
              id="gtm-script"
            />
          </>
        )}
        {process.env.FB_PIXEL_ID && (
          <Script
            id="fb-pixel-script"
            dangerouslySetInnerHTML={{
              __html: `
              if(typeof fbq === 'undefined') {
                !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
                n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
                document,'script','https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${process.env.FB_PIXEL_ID}');
                fbq('set','agent','tmgoogletagmanager', '${process.env.FB_PIXEL_ID}');
                fbq('track', "PageView");
              }
              else {
                fbq('track', "PageView");
              }`
            }}
            strategy="worker"
          />
        )}
      </SWRProvider>
    </AppCommonDataWrapper>
  );
};

export default UNApp;

export function reportWebVitals(metric) {
  if (typeof window === 'undefined') return;

  if (
    metric.label === 'web-vital' &&
    ['TTFB', 'FCP', 'LCP'].includes(metric.name)
  ) {
    // if (!window.dataLayer) window.dataLayer = [];
    // window.dataLayer.push({
    //   event: 'web-vitals',
    //   event_category: 'Web Vitals',
    //   event_action: metric.name,
    //   event_value: Math.round(metric.value),
    //   event_label: metric.id
    // });
  }
}
