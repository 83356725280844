import useFetch from '@hooks/useFetch';
import { emptyList } from '@constants/empty';
import { readCookie } from '@utils/cookies';
import { HOST_TYPE } from '@utils/api-helper/host';

const key =
  'v1/user/me/pinned_topology/?subscribed_goals=true&include_icons=true';

const useFollowedGoals = (
  options = { revalidateOnMount: false, fallbackData: emptyList }
) => {
  const accessToken = readCookie('accessToken');
  const {
    data: followedGoals,
    isValidating,
    ...rest
  } = useFetch(
    accessToken ? key : null,
    { hostType: HOST_TYPE.BACKEND_API_HOST },
    {
      revalidateOnMount: options.revalidateOnMount,
      fallbackData: options.fallbackData,
      dedupingInterval: 60 * 1000,
      ...options
    }
  );

  return {
    key,
    isValidating,
    ...rest,
    followedGoals: followedGoals || emptyList
  };
};

export default useFollowedGoals;
