import cookies from './cookies';
import uuid from './uuid';

export const fetchProxyUserUID = () => {
  let proxyUID = cookies.readCookie('proxy-user-uid');
  if (!proxyUID) {
    proxyUID = uuid();
    cookies.setCookie('proxy-user-uid', proxyUID);
  }
  return proxyUID;
};

export const abProxyJWT = process.env.PROXY_JWT_TOKEN;

export const abVariations = {
  CONTROL: 'Control',
  TEST_1: 'Test1',
  TEST_2: 'Test2',
  TEST_3: 'Test3',
  TEST_4: 'Test4',
  TEST_5: 'Test5'
};
