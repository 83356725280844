import createHook from 'zustand';
import cookies from '@utils/cookies';

const useDarkModeStatus = createHook((set) => ({
  userPreferredTheme: !!cookies.readCookie('isDarkModeOn'),
  isDarkModeOn: false,
  setUserPreferredTheme: (userPreferredTheme) =>
    set((state) => {
      cookies.setCookie(
        'isDarkModeOn',
        userPreferredTheme ? String(userPreferredTheme) : ''
      );
      return {
        ...state,
        userPreferredTheme
      };
    }),
  setIsDarkModeOn: (status) =>
    set((state) => {
      return {
        ...state,
        isDarkModeOn: status
      };
    })
}));

export default useDarkModeStatus;
