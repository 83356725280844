const savedKeys = ['liveSessions', 'streakNudges', 'storeTooltipStatus'];

function localStorageAvailable() {
  try {
    const x = 'storage_test';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function clearLocalStorage() {
  if (localStorageAvailable()) {
    const viewValues = [];

    Object.keys(localStorage).forEach((key) => {
      savedKeys.forEach((savedKey) => {
        if (key.includes(savedKey)) {
          viewValues.push({ key, value: localStorage[savedKey] });
        }
      });
    });
    localStorage?.clear();
    viewValues.forEach(({ key, value }) => localStorage.setItem(key, value));
  }
}

export { localStorageAvailable, clearLocalStorage };
