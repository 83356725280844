export const getResponseError = (response) => {
  const error = new Error(response.statusText);
  error.response = response;
  const contentType = response.headers.get('content-type');
  if (contentType?.includes('text/plain') || contentType?.includes('text/html'))
    error.status = 500;
  return error;
};

const checkAPIResponseStatus = (response) => {
  if (response.ok) return response;
  throw getResponseError(response);
};

export default checkAPIResponseStatus;
