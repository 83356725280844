import { IMessageConfig } from 'types/sockets';
import CommandListeners from './MessageCommandListeners';

const socketMessageHandler = (
  messageObj: IMessageConfig,
  socketInstance: any,
  goalEventProps: any,
  refetchMyInfo: any,
  stargateJwtToken: any
) => {
  const { command, data, type } = messageObj;

  if (command !== 'ERROR') {
    const currentMessageListener = CommandListeners.find(
      (listener) => listener.commandValue === command
    );
    if (currentMessageListener) {
      currentMessageListener.handler({
        socketInstance,
        goalEventProps,
        data,
        stargateJwtToken,
        store: null
      });
    }
  } else if (type === 'SS_001') {
    refetchMyInfo();
  }
};

export default socketMessageHandler;
