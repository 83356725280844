import deviceHelper from '@utils/deviceHelper';
import router from 'next/router';

const getPlatform = () => {
  const { query } = router;
  const isServer = typeof window === 'undefined';
  const { isWebView, isIos, isMobileDevice } = deviceHelper;

  if (isWebView({ isServer }) || query.inside_app === 'true')
    return 'Android webview';
  if (isIos({ isServer })) return 'iOS mWeb';
  if (isMobileDevice({ isServer })) return 'Android mWeb';
  return 'Web';
};

export default getPlatform;
