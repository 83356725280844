import { useEffect } from 'react';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getJwtToken, getSearchToken } from '@api/selectors/auth';
import cookies from '@utils/cookies';

const useJWT = () => {
  const jwtToken = useMyInfo(getJwtToken);
  const searchToken = useMyInfo(getSearchToken);

  useEffect(() => {
    if (jwtToken) cookies.setCookie('jwtToken', jwtToken, 3024000, true);
    if (searchToken) cookies.setCookie('searchToken', searchToken);
  }, [jwtToken, searchToken]);

  return null;
};

export default useJWT;
