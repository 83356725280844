import create from 'zustand/vanilla';
import createHook from 'zustand';
import { useEffect } from 'react';

export const sidenavStore = create((set) => ({
  selectedNav: 'plus',
  selectedEduNav: 'dashboard',
  setSelectedNav: (selectedNav) => set(() => ({ selectedNav })),
  setSelectedEduNav: (selectedEduNav) => set(() => ({ selectedEduNav }))
}));

const useSidenavStore = createHook(sidenavStore);

export const { setSelectedNav, setSelectedEduNav } = sidenavStore.getState();
export default useSidenavStore;

export const useSetSelectedNav = (selectedNav) =>
  useEffect(() => {
    setSelectedNav(selectedNav);
  }, [selectedNav]);

export const useSetSelectedEduNav = (selectedNav) =>
  useEffect(() => {
    setSelectedEduNav(selectedNav);
  }, [selectedNav]);
