/* eslint-disable no-param-reassign */
import { RewriteFrames } from '@sentry/integrations';

const integrations = [];
if (process.env.NODE_ENV === 'production') {
  integrations.push(
    new RewriteFrames({
      iteratee: (frame) => {
        frame.filename = frame.filename.replace(process.env.ASSET_PREFIX, '~');
        frame.filename = frame.filename.replace('.next', '_next');
        frame.filename = frame.filename.replaceAll('%5B', '[');
        frame.filename = frame.filename.replaceAll('%5D', ']');
        return frame;
      }
    })
  );
}
const sentryOptions = {
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  environment: process.env.NODE_ENV,
  release: process.env.APP_COMMIT_ID,
  integrations,
  denyUrls: [/.*freshbots\.ai(.*)/i],
  tracesSampleRate: 1.0
};

export default sentryOptions;
