import processUserData from '@api/processors/user';
import { PLATFORM } from '@constants/index';
import useFetch from '@hooks/useFetch';
import { readCookie } from '@utils/cookies';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const myInfoSWRKey = 'v2/user/me/';

/**
 * @param { selectorFunc } [Function] An optional argument that returns data selected from me response.
 * @returns { Object } An object with the response and function to refetch access control data
 */
const useMyInfo = (selectorFunc = null) => {
  const accessToken = readCookie('accessToken');
  const shouldFetch = !!accessToken;

  const { pathname } = useRouter();

  const {
    data: myInfo,
    mutate: refetchMyInfo,
    ...rest
  } = useFetch(
    shouldFetch ? myInfoSWRKey : null,
    {
      headers: {
        'X-Source': pathname,
        'X-platform': PLATFORM.OTHER
      }
    },
    {
      errorRetryCount: 1,
      revalidateOnMount: shouldFetch,
      dedupingInterval: 5 * 1000 * 60
    }
  );

  const processedMyInfo = useMemo(() => {
    if (!myInfo || myInfo?.error) return null;
    if (myInfo) return processUserData(myInfo);
  }, [myInfo]);

  if (typeof selectorFunc === 'function') return selectorFunc(processedMyInfo);
  return { myInfo: processedMyInfo, refetchMyInfo, ...rest };
};

export default useMyInfo;
