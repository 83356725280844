import { segment } from '@actions/analytics';
import {
  REFERRAL_PLAYER_DIALOG,
  REFERRAL_TOUCHPOINT_CLICKED,
  REFERRAL_TOUCHPOINT_CLOSED
} from '@constants/segment';
import { showReferralBannerStore } from '@stores/referralBanner';
import pushRoute from '@utils/pushRoute';
import isString from 'lodash/isString';
import cookies from './cookies';

export const parseJSON = (cookieData) => {
  try {
    if (isString(cookieData)) {
      return JSON.parse(cookieData);
    }
    return JSON.parse(JSON.stringify(cookieData));
  } catch {
    //
  }
};

export const hideReferrals = (lpss, onReferNowClick) => {
  showReferralBannerStore.getState().setShowReferralBanner(false);
  if (!onReferNowClick) {
    segment.track(REFERRAL_TOUCHPOINT_CLOSED, { last_primary_source: lpss });
  }
  cookies.setCookie('referral-banners', {
    date: new Date().getTime(),
    isFirstSevenDays: !cookies.readCookie('referral-banners') || false,
    dontshow: true
  });
};

export const gotoReferralPage = (goalInfo, lpss) => {
  segment.track(REFERRAL_TOUCHPOINT_CLICKED, { last_primary_source: lpss });
  if (lpss !== REFERRAL_PLAYER_DIALOG) {
    hideReferrals(lpss, true);
  }
  if (!goalInfo) {
    pushRoute('/settings', '/settings');
  } else {
    pushRoute('/settings/referral', '/settings/referral');
  }
};

export const updateFreeReferralCookies = ({ showBanner }) => {
  cookies.setCookie('referral-banners', {
    showFreeBanner: showBanner,
    freeReferralDate: new Date().getTime()
  });
};
export const freeReferralbannerStatus = () => {
  const referralObj = {
    showBanner: true,
    updateCookies: true
  };
  const referralCookie = cookies.readCookie('referral-banners')
    ? parseJSON(cookies.readCookie('referral-banners'))
    : null;
  if (referralCookie?.freeReferralDate) {
    const daysDifference = Math.round(
      (new Date() - new Date(referralCookie.freeReferralDate)) /
        (1000 * 3600 * 24)
    );
    referralObj.updateCookies = false;
    if (daysDifference > 7 || !referralCookie.showFreeBanner) {
      referralObj.showBanner = false;
    }
  }
  return referralObj;
};
export const checkShowReferrals = () => {
  const referralCookie = cookies.readCookie('referral-banners')
    ? parseJSON(cookies.readCookie('referral-banners'))
    : null;
  if (!referralCookie) return true;
  const daysDifference = Math.round(
    (new Date() - new Date(referralCookie.date)) / (1000 * 3600 * 24)
  );
  if (referralCookie.isFirstSevenDays && daysDifference > 7) {
    cookies.setCookie('referral-banners', {
      date: new Date().getTime(),
      isFirstSevenDays: false,
      dontshow: false
    });
    return true;
  }
  if (!referralCookie.isFirstSevenDays) {
    if (referralCookie.dontshow && daysDifference > 7) {
      cookies.setCookie('referral-banners', {
        date: new Date().getTime(),
        isFirstSevenDays: false,
        dontshow: false
      });
      return true;
    }
    if (!referralCookie.dontshow && daysDifference > 2) {
      cookies.setCookie('referral-banners', {
        date: new Date().getTime(),
        isFirstSevenDays: false,
        dontshow: false
      });
      return true;
    }
  }
  return false;
};

const referralHelper = { gotoReferralPage, hideReferrals };

export default referralHelper;
