/* eslint-disable max-classes-per-file */
export class NotFound extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = msg;
    if (Error.captureStackTrace) Error.captureStackTrace(this, NotFound);
  }
}

export class GTMError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, GTMError.prototype);
    this.name = 'GTMError';
    if (Error.captureStackTrace) Error.captureStackTrace(this, GTMError);
  }
}

export class AnalyticsEventsError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, AnalyticsEventsError.prototype);
    this.name = 'AnalyticsEventsError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, AnalyticsEventsError);
  }
}

export class DeviceLimitationAPIError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, DeviceLimitationAPIError.prototype);
    this.name = 'DeviceLimitationAPIError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, DeviceLimitationAPIError);
  }
}

export class QuotaExceededError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, QuotaExceededError.prototype);
    this.name = 'QuotaExceededError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, QuotaExceededError);
  }
}

export class FreshBotInitializationError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, FreshBotInitializationError.prototype);
    this.name = 'FreshBotInitializationError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, FreshBotInitializationError);
  }
}

export class DoubtsImageUploadError extends Error {
  constructor(msg, data) {
    super(msg);
    Object.setPrototypeOf(this, DoubtsImageUploadError.prototype);
    this.name = 'DoubtsImageUploadError';
    this.message =
      data && typeof data === 'object' ? JSON.stringify(data) : data || '';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, DoubtsImageUploadError);
  }
}
export class DoubtSubmitError extends Error {
  constructor(msg, data) {
    super(msg);
    Object.setPrototypeOf(this, DoubtSubmitError.prototype);
    this.name = 'DoubtSubmitError';
    this.message =
      data && typeof data === 'object' ? JSON.stringify(data) : data || '';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, DoubtSubmitError);
  }
}

export class SubmitForReviewError extends Error {
  constructor(msg, data) {
    super(msg);
    Object.setPrototypeOf(this, SubmitForReviewError.prototype);
    this.name = 'SubmitForReviewError';
    this.message =
      data && typeof data === 'object' ? JSON.stringify(data) : data || '';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, SubmitForReviewError);
  }
}

export class SolutionFeedbackSubmitError extends Error {
  constructor(msg, data) {
    super(msg);
    Object.setPrototypeOf(this, SolutionFeedbackSubmitError.prototype);
    this.name = msg;
    this.message =
      data && typeof data === 'object' ? JSON.stringify(data) : data || '';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, SolutionFeedbackSubmitError);
  }
}

export class SupportPageAuthError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'SupportPageAuthError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, SupportPageAuthError);
  }
}

export class DoubtsEducatorError extends Error {
  constructor(name, data) {
    super(name);
    Object.setPrototypeOf(this, DoubtsEducatorError.prototype);
    this.name = name;
    this.message =
      data && typeof data === 'object' ? JSON.stringify(data) : data || '';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, DoubtsEducatorError);
  }
}

export class SocketGenericError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'SocketGenericError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, SocketGenericError);
  }
}

export class SocketCloseError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'SocketCloseError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, SocketCloseError);
  }
}

export class PartnerWithUnacademyPageError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'PartnerWithUnacademyPageError ';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, PartnerWithUnacademyPageError);
  }
}

export class SocketRetryError extends Error {
  constructor() {
    super('Failed trying to force restart socket.');
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'SocketRetryError ';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, SocketRetryError);
  }
}

export class WorkerNotFoundError extends Error {
  constructor() {
    super('Tried to invoke woker when it is not available');
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'WorkerNotFoundError ';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, WorkerNotFoundError);
  }
}

export class PlannerHeartbeatError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'PlannerHeartbeatError ';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, PlannerHeartbeatError);
  }
}

export class APIFailureError extends Error {
  constructor(message) {
    super(message);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'APIFailureError';
    if (Error.captureStackTrace) Error.captureStackTrace(this, APIFailureError);
  }
}
export class APIFailureErrorException extends Error {
  constructor(message) {
    super(message);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'APIFailureErrorException';
    if (Error.captureStackTrace) Error.captureStackTrace(this, APIFailureError);
  }
}

export class BoundaryError extends Error {
  constructor(msg) {
    super(msg);
    Object.setPrototypeOf(this, Error.prototype);
    this.name = 'BoundaryError';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, PlannerHeartbeatError);
  }
}

export class CommonApiFailureError extends Error {
  constructor(name, data) {
    super(name);
    Object.setPrototypeOf(this, CommonApiFailureError.prototype);
    this.name = name || 'APIFailureError';
    this.message =
      data && typeof data === 'object' ? JSON.stringify(data) : data || '';
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, CommonApiFailureError);
  }
}
