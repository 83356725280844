import Router from 'next/router';
import segmentStorage from '@events/segmentStorage';

const scollToTop = () => document.documentElement.scrollTo(0, 0);

const pushRoute = (
  url,
  as,
  options,
  lps,
  replace = false,
  isScrollToTop = true
) => {
  if (lps) {
    segmentStorage.setItem('lps', lps);
  }
  const routeOptions = options || {};
  if (replace) {
    return Router.replace(url, as, routeOptions).then(
      isScrollToTop ? scollToTop : null
    );
  }

  return Router.push(url, as, routeOptions).then(
    isScrollToTop ? scollToTop : null
  );
};

export default pushRoute;
