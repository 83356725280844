/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, memo, Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';

import useJWT from '@hooks/useJWT';
import useReferralBanner from '@hooks/useReferralBanner';
import useFreshTicket from '@hooks/useFreshTicket';
import useFreshBots from '@hooks/useFreshBots';

import { DefaultSeo } from 'next-seo';
import Dynamic from '@base/Dynamic/Dynamic';
import { DeviceLimitationContextProvider } from '@context/DeviceLimitationContext';
import RenderGDPRCookieBanner from '@cont/App/RenderGDPRCookieBanner';

import SEO from '../../../../next-seo.config';

const ProgressBar = Dynamic(() => import('@base/ProgressBar/ProgressBar'));
const WindowsPolyfills = Dynamic(() => import('./WindowsPolyfills'));
// const RenderIntercom = Dynamic(() => import('@cont/App/RenderIntercom'));
const RenderSnackBar = Dynamic(() => import('@cont/App/RenderSnackBar'));
const RenderDeviceLimitationNudge = Dynamic(() =>
  import('@cont/DeviceLimitation/RenderDeviceLimitationNudge')
);
const RenderPostClassRecommendation = Dynamic(() =>
  import('@cont/App/RenderPostClassRecommendation')
);

function AppLayout({ Page, pageProps, inEducatorSide }) {
  const { Layout } = Page;
  const { pathname } = useRouter();

  const isHomePage = useMemo(() => pathname === '/', [pathname]);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  useJWT();
  useReferralBanner();
  useFreshTicket();
  useFreshBots(inEducatorSide, pageProps.tokens?.accessToken);

  return (
    <DeviceLimitationContextProvider>
      <Layout
        layoutOptions={Page?.pageInfo?.layoutOptions || {}}
        isEducatorSide={inEducatorSide}
        key={pathname}
        pageMetaInfo={pageProps.pageMetaInfo || {}}
      >
        {/* <RenderIntercom /> */}
        <DefaultSeo {...SEO} />
        <ProgressBar />
        <Fragment key={pathname}>
          <Page {...pageProps} />
        </Fragment>
        <WindowsPolyfills />
        <RenderSnackBar />
        <RenderPostClassRecommendation />
        <RenderDeviceLimitationNudge />
        <RenderGDPRCookieBanner shouldShowCookierBanner={isHomePage} />
      </Layout>
    </DeviceLimitationContextProvider>
  );
}

export default memo(AppLayout);
