import { useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  GOAL_UID,
  GOAL_NAME,
  IS_SUBSCRIPTION_ACTIVE,
  SUBSCRIPTION_DURATION,
  SUBSCRIPTION_END_DATE,
  LANGUAGE,
  NOT_APPLICABLE,
  SUBSCRIPTION_TYPE
} from '@events/segmentProps';
import { isCentreSubscription, subscriptionTypes } from '@constants/index';
import { emptyObject } from '@constants/empty';
import { getMeUserSubscriptionTimeline } from '@api/selectors/subscription';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import useTopology from '@api/hooks/topology/useTopology';
import Me from '@models/Me';

const useGoalEventProps = ({ goalUID = '' }) => {
  const {
    query: { goalUID: routeGoalUID }
  } = useRouter();
  const selectedGoalUID = useSelectedGoalUID();

  const currGoalUID = goalUID || routeGoalUID || selectedGoalUID;
  const { topology: goalData } = useTopology(currGoalUID);
  const { name: goalName, language } = goalData;
  const subscriptionTimeline = useMyInfo(getMeUserSubscriptionTimeline);

  const currentRunningSubscriptions =
    Me.getAllActiveSubscriptions(subscriptionTimeline, currGoalUID) ?? [];

  const getSubscriptionType = (type) => {
    const entries = Object.entries(subscriptionTypes);
    const subscribedType = entries.find((entry) => entry[1] === type);
    return subscribedType ? subscribedType[0] : 'NA';
  };
  const getActiveSubscription = () => {
    if (currentRunningSubscriptions.length === 1)
      return currentRunningSubscriptions[0];

    const plusOrIconicSubscription = currentRunningSubscriptions.find(
      (data) => {
        return (
          data?.subscription?.type === subscriptionTypes.PLUS ||
          data?.subscription?.type === subscriptionTypes.ICONIC ||
          data?.subscription?.type === subscriptionTypes.CENTRE ||
          isCentreSubscription(data?.subscription?.type, data?.contentTypeId)
        );
      }
    );

    if (plusOrIconicSubscription) return plusOrIconicSubscription;

    const storeSubscription = currentRunningSubscriptions.find((data) => {
      return data?.subscription?.type === subscriptionTypes.STORE;
    });

    if (storeSubscription) return storeSubscription;

    return currentRunningSubscriptions[0];
  };

  const calculateDuration = (startDate, expirationDate) => {
    const start = new Date(startDate);
    const expiration = new Date(expirationDate);
    const timeDiff = expiration.getTime() - start.getTime();
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return days;
  };

  return useMemo(() => {
    const activeSubscription = getActiveSubscription() || emptyObject;
    const subscription = activeSubscription?.subscription;
    const subscriptionDuration = calculateDuration(
      activeSubscription?.startedAt,
      activeSubscription?.expiredAt
    );

    let goalSegmentData = {
      [GOAL_UID]: NOT_APPLICABLE,
      [GOAL_NAME]: NOT_APPLICABLE,
      [IS_SUBSCRIPTION_ACTIVE]: currentRunningSubscriptions.length > 0,
      [SUBSCRIPTION_DURATION]: 'NA',
      [SUBSCRIPTION_END_DATE]: 'NA',
      [LANGUAGE]: language
    };
    if (currGoalUID) {
      goalSegmentData = {
        [GOAL_UID]: currGoalUID,
        [GOAL_NAME]: goalName,
        [IS_SUBSCRIPTION_ACTIVE]: currentRunningSubscriptions.length > 0,
        [SUBSCRIPTION_DURATION]: NOT_APPLICABLE,
        [SUBSCRIPTION_END_DATE]: NOT_APPLICABLE,
        [LANGUAGE]: language,
        [SUBSCRIPTION_TYPE]: getSubscriptionType(subscription?.type)
      };
    }

    if (currentRunningSubscriptions.length > 0) {
      goalSegmentData[SUBSCRIPTION_DURATION] = subscriptionDuration;
      goalSegmentData[SUBSCRIPTION_END_DATE] = activeSubscription.expiredAt;
    }
    return goalSegmentData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionTimeline, goalUID, goalName]);
};

export default useGoalEventProps;
