import { useEffect } from 'react';

const useLogoutListener = () => {
  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.storageArea !== window.localStorage) return;
      if (event.key === 'logoutuserfromalltabs') window.location.reload(true); // Hard refresh current tab.
      if (event.key === 'showlogoutnudge') window.location.reload(true);
    });
  }, []);

  return null;
};

export default useLogoutListener;
