import { SWRConfig } from 'swr';
import swrFetcher from '@api/fetchers';
import { useCallback } from 'react';

const SWRProvider = ({ children, pageProps }) => {
  const fetcher = useCallback(
    (key) => swrFetcher(key, { useOriginalURL: true }),
    []
  );
  return (
    <SWRConfig
      value={{ fallback: pageProps?.globalFallbackData || {}, fetcher }}
    >
      {children}
    </SWRConfig>
  );
};

export default SWRProvider;
