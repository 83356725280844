import createHook from 'zustand';
import createStore from 'zustand/vanilla';
import {
  closeToggleableElement,
  openToggleableElement,
  useToggleable
} from '@stores/toggleables';
import { SNACKBAR } from '@constants/toggleables';

const initial = {
  content: null
};

const getHandlers = (set) => ({
  setContent: (data = {}) =>
    set((state) => ({
      ...state,
      content: data
    }))
});

const store = createStore((set) => ({
  ...initial,
  ...getHandlers(set)
}));

const useStore = createHook(store);

const useSnackbar = () => {
  const { openElement: showSnackbar, closeElement: closeSnackbar } =
    useToggleable(SNACKBAR);
  const setContent = useStore((state) => state.setContent);
  const data = useStore((state) => state.content);

  const isActive = !!data?.message;

  const open = (content) => {
    setContent(content);
    showSnackbar();
  };

  const close = () => {
    setContent(null);
    closeSnackbar();
  };

  return {
    isActive,
    data,
    open,
    close
  };
};

const utils = (() => {
  const setContent = () => store.getState().setContent;
  const getContent = () => store.getState().content;

  const open = (content) => {
    setContent(content);
    openToggleableElement(SNACKBAR);
  };

  const close = () => {
    setContent(null);
    closeToggleableElement(SNACKBAR);
  };

  return {
    open,
    close,
    getContent
  };
})();

export { utils, useSnackbar, store };
