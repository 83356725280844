import sentryOptions from 'sentry.common.config';
import { init, configureScope } from '@sentry/nextjs';

const sentryInitialization = () => {
  init(sentryOptions);
  configureScope((scope) => {
    scope.setTag('serverSide', false);
    if (typeof navigator === 'object' && navigator?.connection) {
      scope.setTag('network speed', `${navigator?.connection?.downlink}mbps`);
      scope.setTag('network type', navigator?.connection?.effectiveType);
    }
  });
};

export default process.env.SENTRY_DSN ? sentryInitialization() : null;
