/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */
/* eslint-disable func-names */
import { sendPlannerDiagnosticData } from '@cont/Planner/planner-utils/diagnostics/collector';
import useFetch from '@hooks/useFetch';
import cookies from './cookies';
import isOfflineSubscription from './subscriptionHelpers/isOfflineSubscription';

const initializeFreshbots = (route, meData, supportMeData, countryCode) => {
  const { me, selfData = {} } = meData;
  const { plusSubscriptions = [], offlineSubscriptions = [] } = me;
  const goal_uid =
    meData.selectedGoal ||
    cookies.readCookie('selectedGoalUid') ||
    cookies.readCookie('selectedGoal') ||
    'NA';

  const subscriptionInfo =
    [...offlineSubscriptions, ...plusSubscriptions]?.find((subscription) => {
      return (
        subscription?.subscription?.value.uid === goal_uid ||
        subscription?.goal_uid === goal_uid
      );
    }) || {};
  const isOffLine = isOfflineSubscription(subscriptionInfo);

  const goal_name =
    subscriptionInfo?.subscription?.value?.name ?? selfData?.name ?? 'NA';

  (function (d, w, c) {
    if (!d.getElementById('spd-busns-spt')) {
      const n = d.getElementsByTagName('script')[0];
      const s = d.createElement('script');
      let loaded = false;
      s.id = 'spd-busns-spt';
      s.defer = true;
      s.setAttribute('data-self-init', 'false');
      s.setAttribute('data-init-type', 'delayed');
      s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js';
      s.setAttribute('data-client', process.env.FRESHBOT_CLIENT_KEY);
      s.setAttribute('data-bot-hash', process.env.FRESHBOT_HASH_KEY);
      s.setAttribute('data-env', 'prod');
      s.setAttribute('data-region', 'in');
      if (c) {
        s.onreadystatechange = s.onload = function () {
          if (!loaded) {
            c();
          }
          loaded = true;
        };
      }
      n.parentNode.insertBefore(s, n);
    }
  })(document, window, function () {
    if (!window.Freshbots) {
      initializeFreshbots(route, meData, supportMeData);
    } else if (meData.isLoggedIn || me.uid) {
      let userEmail = supportMeData?.gen_email_id;
      if (!userEmail && me.uid) {
        userEmail = `${me.uid.toLowerCase()}-${
          me.isEducator ? 'educator' : 'learner'
        }@unacdn.net`;
      }
      window.Freshbots.initiateWidget(
        {
          autoInitChat: false,
          getClientParams() {
            return {
              'cstmr::xtrInfrmtn:api_server_token': meData.accessToken,
              'sn::cstmr::id': me.uid,
              'cstmr::xtrInfrmtn:jwt_token': me.jwtToken,
              'cstmr::eml': userEmail,
              is_logged_in: meData.isLoggedIn,
              xtrnlTcktId: '',
              goal_uid,
              goal_name,
              is_anonymous: !meData.isLoggedIn,
              'cstmr::phn': me.phone,
              'cstmr::nm': me.name,
              country: countryCode,
              subscriptionStartedAt: subscriptionInfo.started_at,
              subscriptionUid: subscriptionInfo.subscription?.uid,
              source: cookies.readCookie('source') || 'NA',
              OSVersion: cookies.readCookie('OSVersion') || 'NA',
              device: cookies.readCookie('device') || 'NA',
              appVersionName: cookies.readCookie('appVersionName') || 'NA',
              appVersionCode: cookies.readCookie('appVersionCode') || 'NA',
              browser: '',
              browser_version: '',
              portal: isOffLine ? 'offline' : 'online'
            };
          }
        },
        function (successResponse) {
          if (window.Freshbots) {
            window.Freshbots.showWidget();
            window.Freshbots.freddyIsInitiatedChat = false;

            // mobile app workflow. This route is used only there.
            if (route === '/support') {
              if (!window.Freshbots.freddyIsInitiatedChat) {
                window.Freshbots.initiateChat(true);
                window.Freshbots.freddyIsInitiatedChat = true;
              }
              window.Freshbots.showWidget(true);
              document.getElementById('fbots-wdgt').style.width = '100vw';
              document.getElementById('fbots-wdgt').style.right = '0px';
              document.getElementById('fbots-wdgt').style.left = '0px';
              document.getElementById('fbots-wdgt').style.top = '0px';
              document.getElementById('fbots-wdgt').style.bottom = '0px';
              document.getElementById('fbots-wdgt').style.maxHeight = 'none';
              document.getElementById('fbots-wdgt').style.display = 'block';
            } else {
              const ele = document.querySelector(
                '.mobile-chat-container#mobile-chat-container'
              );
              ele.addEventListener(
                'click',
                function (e) {
                  e.stopPropagation();
                  if (!window.Freshbots.freddyIsInitiatedChat) {
                    window.Freshbots.initiateChat(true);
                    window.Freshbots.freddyIsInitiatedChat = true;
                  }
                  window.Freshbots.showWidget(true);
                  sendPlannerDiagnosticData();
                },
                true
              );
            }
          }
        },
        function (errorResponse) {
          /* TODO:- @priya remove eslint-disable no-unused-vars from file once successResponse and errorResponse is used */
        }
      );
    }
  });
};

export default initializeFreshbots;
