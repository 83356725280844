import { APIFailureError, APIFailureErrorException } from '@utils/customErrors';
import { captureException } from '@utils/sentry';
import shouldCaptureAPIFailure from './shouldCaptureAPIFailure';

// const { SEND_API_FAILURES_TO_SENTRY } = process.env;

const captureAPIFailure = ({
  endpoint,
  error,
  source = 'redux',
  options: optionsToFetcher = {},
  headers = {}
}) => {
  try {
    // flag to switch on/off sentry exception capture
    // if (!SEND_API_FAILURES_TO_SENTRY) return;

    const statusCode = error?.response?.status;

    // If the endpoint and the error status code are in the capture list.
    if (!shouldCaptureAPIFailure(endpoint, statusCode)) return;

    const usefulOptionsTags = [
      'useAuthToken',
      'useSearchToken',
      'useJWTToken',
      'requestType',
      'isFormData',
      'useOriginalURL'
    ];
    const extraProps = [
      { type: 'tag', key: 'source', value: source },
      { type: 'tag', key: 'endpoint', value: endpoint },
      { type: 'tag', key: 'serverSide', value: typeof window === 'undefined' }
    ];
    for (let i = 0; i < usefulOptionsTags.length; i += 1) {
      const option = usefulOptionsTags[i];
      if (typeof optionsToFetcher?.[option] !== 'undefined')
        extraProps.push({
          type: 'tag',
          key: `options.${option}`,
          value: optionsToFetcher[option]
        });
    }
    // Diagnostics
    if (optionsToFetcher?.useAuthToken) {
      extraProps.push({
        type: 'tag',
        key: 'diagnostics.hasAuthToken',
        value: !!headers.authorization
      });
    }
    if (optionsToFetcher?.useJWTToken) {
      extraProps.push({
        type: 'tag',
        key: 'diagnostics.hasJWTToken',
        value: !!headers.authorization
      });
    }
    if (optionsToFetcher?.useSearchToken) {
      extraProps.push({
        type: 'tag',
        key: 'diagnostics.hasSearchToken',
        value: !!headers.searchToken
      });
    }
    // ToDo: Remove this
    if (process.env.ENVIRONMENT !== 'production') {
      // eslint-disable-next-line no-console
      console.log(
        'For Gamma API Errors',
        `${statusCode} for ${endpoint.split('?')[0]}`
      );
    }

    // Send to Sentry
    captureException(
      new APIFailureError(`${statusCode} for ${endpoint.split('?')[0]}`),
      null,
      statusCode,
      extraProps
    );
  } catch (err) {
    captureException(new APIFailureErrorException(err));
  }
};

export default captureAPIFailure;
