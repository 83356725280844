import createStore from 'zustand/vanilla';
import createHook from 'zustand';

export const experimentsStore = createStore((set) => ({
  experimentVariations: {},
  setExperimentVariations: (experimentId, variations) =>
    set(({ experimentVariations }) => ({
      experimentVariations: {
        ...experimentVariations,
        [experimentId]: variations
      }
    }))
}));

export const setExperimentVariation = (experimentId, variation) =>
  experimentsStore.getState().setExperimentVariations(experimentId, variation);

const useExperiments = createHook(experimentsStore);

export default useExperiments;
