import Dynamic from '@base/Dynamic/Dynamic';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { getUserPreferredThemeSetter } from '@stores/selectors/darkModeStatusSelectors';
import { useIsPageInReduxContext } from '@context/IsPageInReduxContext';

const ReduxBasedStaticPageSetup = Dynamic(() =>
  import('@cont/App/StaticPage/ReduxBasedStaticPage')
);
const ReduxFreeStaticPageSetup = Dynamic(() =>
  import('@cont/App/StaticPage/ReduxFreeStaticPage')
);

const StaticPageSetup = ({ Page }) => {
  const isPageInReduxContent = useIsPageInReduxContext();

  const setUserPreferredTheme = useDarkModeStatus(getUserPreferredThemeSetter);

  useEffect(() => {
    const { isDarkModeOn } = Cookies.get();
    setUserPreferredTheme(!!isDarkModeOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Page.pageInfo]);

  return (
    <>
      {isPageInReduxContent ? (
        <ReduxBasedStaticPageSetup Page={Page} />
      ) : (
        <ReduxFreeStaticPageSetup Page={Page} />
      )}
    </>
  );
};

export default StaticPageSetup;
