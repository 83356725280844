import { createContext, FC, PropsWithChildren, useRef, useState } from 'react';

interface IDeviceLimitationHandler {
  logoutAllOtherDevice: () => Promise<void>;
  checkAgainForAccess: () => Promise<void>;
}

type CheckAccessRetryState = 'waiting' | 'checking' | 'success' | 'failed';

export interface IDeviceLimitationContext {
  showDeviceLimitationNudge: boolean;
  checkForAccessState: CheckAccessRetryState;
  setCheckForAccessState: (updatedState: CheckAccessRetryState) => void;
  updateHandlers: (handlers: IDeviceLimitationHandler) => void;
  showNudge: () => void;
  hideNudge: () => void;
  logoutAllOtherDevice: () => void;
  checkAgainForAccess: () => void;
}

const emptyFn = () => {};
const emptyAsyncFn = async () => {};

export const DeviceLimitationContext = createContext<IDeviceLimitationContext>({
  showDeviceLimitationNudge: false,
  checkForAccessState: 'waiting',
  setCheckForAccessState: emptyFn,
  updateHandlers: emptyFn,
  showNudge: emptyFn,
  hideNudge: emptyFn,
  logoutAllOtherDevice: emptyAsyncFn,
  checkAgainForAccess: emptyAsyncFn
});

export const DeviceLimitationContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [checkForAccessState, setCheckForAccessState] =
    useState<CheckAccessRetryState>('waiting');

  const [showDeviceLimitationNudge, setShowDeviceLimitationNudge] =
    useState(false);

  const deviceLimitationHandlerRef = useRef<IDeviceLimitationHandler>(null);
  const updateHandlers = (handlers: IDeviceLimitationHandler) => {
    deviceLimitationHandlerRef.current = handlers;
  };
  const { logoutAllOtherDevice = emptyFn, checkAgainForAccess = emptyFn } =
    deviceLimitationHandlerRef?.current ?? {};

  const hideNudge = () => {
    updateHandlers({
      logoutAllOtherDevice: emptyAsyncFn,
      checkAgainForAccess: emptyAsyncFn
    });
    setCheckForAccessState('waiting');
    setShowDeviceLimitationNudge(false);
  };
  const showNudge = () => setShowDeviceLimitationNudge(true);

  const data = {
    showDeviceLimitationNudge,
    checkForAccessState,
    setCheckForAccessState,
    updateHandlers,
    hideNudge,
    showNudge,
    logoutAllOtherDevice,
    checkAgainForAccess
  };

  return (
    <DeviceLimitationContext.Provider value={data}>
      {children}
    </DeviceLimitationContext.Provider>
  );
};
