import UAParser from 'ua-parser-js';
import { NOT_APPLICABLE } from '@constants/segment';
import { OS } from '@constants/support';

const BLANKSTRING = '';

/**
 * Method that finds out the browser's and operating system's details.
 * Works at both client and server side.
 * @param {String} userAgent The user agent of the current browser
 * @returns {Object} browser's and operating system's name and version,deviceType
 */
export const getBrowserInfo = (userAgent) => {
  const parser = new UAParser(userAgent);
  const {
    name: browserName = BLANKSTRING,
    version: browserFullVersion = BLANKSTRING
  } = parser.getBrowser();
  const { name: osName = BLANKSTRING, version: osVersion = BLANKSTRING } =
    parser.getOS();
  const browserMajorVersion = +browserFullVersion.split('.', 1);
  return {
    browserName,
    browserFullVersion,
    browserMajorVersion,
    osName,
    osVersion,
    deviceType: parser.getDevice()?.type
  };
};

// get browser name
const getBrowserName = (browserName) =>
  browserName === 'IE' ? 'Microsoft Internet Explorer' : browserName;

// get os name
const getOs = (osName) => {
  switch (true) {
    case /Mac OS/.test(osName):
      return OS.MacOS;
    case /Windows/.test(osName):
      return OS.Windows;
    case /Unix/.test(osName):
      return OS.UNIX;
    case /Linux/.test(osName):
      return OS.Linux;
    case /Android/.test(osName):
      return OS.Android;
    case /iOS/.test(osName):
      return OS.iOS;
    default:
      return OS.UNKNOWN;
  }
};

export const getSystemInfo = (browserInfo) => {
  const {
    browserName = 'NA',
    osName = 'NA',
    osVersion = 'NA',
    deviceType = 'NA',
    browserFullVersion = 'NA'
  } = browserInfo;
  return {
    os: getOs(osName),
    browser: getBrowserName(browserName),
    os_version: osVersion,
    app_version: Number(process.env.APP_VERSION),
    app_build_number: NOT_APPLICABLE,
    device: deviceType,
    browserVersion: browserFullVersion
  };
};
