/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import loadFullStory from '@utils/loadFullStory';

import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn, getMe } from '@api/selectors/auth';
import { useIsMobile } from '@stores/AppCommonData';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';

const useFullStory = () => {
  const me = useMyInfo(getMe);
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const isMobileDevice = useIsMobile();
  const { selectedGoal } = useSelectedGoal();
  useEffect(() => {
    if (isLoggedIn) loadFullStory({ me, selectedGoal, isMobileDevice });
  }, [isLoggedIn]);
  return null;
};

export default useFullStory;
