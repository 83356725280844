import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import fetch from '@api/fetchers';
import { HOST_TYPE } from '@utils/api-helper/host';

const defaultSwrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 15000
};

// swrOptions -> https://swr.vercel.app/docs/options
const useFetch = (key, fetcherOptions, swrOptions) => {
  const {
    useAuthToken = true,
    useSearchToken = false,
    isFormData = false,
    hostType = HOST_TYPE.DEFAULT,
    hasAppClient = false,
    params = {},
    pathVars = {},
    headers = {},
    useCamelCase = true,
    onError,
    onSuccess
  } = fetcherOptions || {};

  const options = useMemo(() => {
    return {
      useAuthToken,
      useSearchToken,
      isFormData,
      host: hostType,
      hasAppClient,
      params,
      pathVars,
      headers,
      useCamelCase
    };
  }, [
    useAuthToken,
    useSearchToken,
    isFormData,
    useCamelCase,
    hostType,
    hasAppClient,
    params,
    headers,
    pathVars
  ]);

  let SWROptionsToPass = {};
  if (typeof onSuccess === 'function') SWROptionsToPass.onSuccess = onSuccess;
  if (typeof onError === 'function') SWROptionsToPass.onError = onError;
  SWROptionsToPass = { ...SWROptionsToPass, ...swrOptions };

  const fetcher = useCallback(
    () =>
      fetch(key, options).then((data) => {
        if (data?.error) throw data.error;
        return data;
      }),
    [key, options]
  );

  const { data, error, ...rest } = useSWR(key, fetcher, {
    ...defaultSwrOptions,
    ...(SWROptionsToPass || {})
  });
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    ...rest // rest -> isValidating, mutate
  };
};

export default useFetch;
