function SegmentStorage() {
  if (typeof SegmentStorage.instance === 'object') {
    return SegmentStorage.instance;
  }
  this.storage = new Map();
  SegmentStorage.instance = this;
}

SegmentStorage.prototype.setItem = function setItem(key, value = {}) {
  this.storage.set(key, value);
};

SegmentStorage.prototype.getItem = function getItem(key, defaultValue) {
  return this.storage.get(key) ?? defaultValue;
};

SegmentStorage.prototype.deleteItem = function deleteItem(key) {
  return this.storage.delete(key);
};

const segmentStorage = new SegmentStorage();
Object.freeze(segmentStorage);

export default segmentStorage;
