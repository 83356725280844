/* eslint-disable import/no-cycle */
import camelcaseKeys from 'camelcase-keys';
import { getDefaultHost, HOST_TYPE } from '@utils/api-helper/host';
import { readCookie, setCookie } from '@utils/cookies';
import getUrl from '@utils/api-helper/getUrl';
import checkAPIResponseStatus from '@utils/api-helper/checkAPIResponseStatus';
import captureAPIFailure from '@utils/api-helper/captureAPIFailure';
import { openToggleableElement } from '@stores/toggleables';
import { utils as snackbarUtils } from '@stores/snackbar';
import signOutUser from '@utils/signOutUser';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { PLATFORM } from '@constants/index';
import { isMobile, isWebView } from '@utils/deviceHelper';
import { requestStatusStore } from '@stores/requestStatus';
import { fetchMyInfo, fetchNewToken } from './user';
import simpleFetch from './simpleFetcher';

const defaultHost = getDefaultHost();

export { simpleFetch };

let isRefreshing = false;

const reduxFreeFetcherErrorHandler = async ({
  error,
  endpoint,
  tokens = {},
  options = {},
  headers = {},
  isWorker
}) => {
  if (error?.response?.status >= 400 && error?.response?.status < 600)
    captureAPIFailure({
      endpoint,
      error,
      source: 'redux-free-fetcher',
      options,
      headers
    });

  const isOtherTokensRequest = options?.useSearchToken || options?.useJWTToken;
  if (error?.response?.status === 401 && !isOtherTokensRequest) {
    const accessToken = tokens?.accessToken || readCookie('accessToken');
    const refreshToken = tokens?.refreshToken || readCookie('refreshToken');
    const isServer = isWorker || typeof window === 'undefined';

    if (accessToken && refreshToken) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await (await fetchNewToken(refreshToken)).json();
          if (response?.error) throw new Error(response?.error);
          isRefreshing = false;
          fetchMyInfo(response.access_token);
          if (!isServer && response.access_token && response.refresh_token) {
            // Update auth token cookies on the client-side.
            setCookie(
              'accessToken',
              response.access_token,
              3024000,
              true,
              true
            );
            setCookie('refreshToken', response.refresh_token, 3024000, true);
          }
        } catch (err) {
          if (!isServer) {
            signOutUser('', { keepSelectedGoal: true });
            openToggleableElement(LOGIN_DRAWER);
          }
        }
      }
    } else if (!isServer) {
      signOutUser('', { keepSelectedGoal: true });
      openToggleableElement(LOGIN_DRAWER);
    }
  }

  if (error?.response?.status === 429) {
    snackbarUtils.open({
      message: 'Too many requests. Please try again after sometime.',
      type: 'error'
    });
  }

  if (error?.response?.status >= 500 || error?.status >= 500) return { error };

  const errorResp = (await error?.response?.json()) || error;
  return {
    error: errorResp,
    statusCode: error?.response?.status
  };
};

export default async function reduxFreeFetcher(endpoint, options = {}) {
  const {
    host = HOST_TYPE.DEFAULT,
    tokens = {},
    headers = {},
    useAuthToken = true,
    useSearchToken = false,
    useJWTToken = false,
    requestType = 'GET',
    params = {},
    pathVars = {},
    isFormData = false,
    skipLoader = false,
    body = null,
    useCamelCase = true,
    useOriginalURL = false,
    isWorker = false
  } = options;

  const url = useOriginalURL
    ? endpoint
    : getUrl({
        endpoint,
        hostType: host,
        params,
        pathVars,
        nextPreviewMode: readCookie('preview-mode')
      });

  // Tokens for headers
  const accessToken =
    useAuthToken && host !== HOST_TYPE.CMS
      ? tokens?.accessToken || readCookie('accessToken')
      : null;
  const searchToken = useSearchToken
    ? tokens?.searchToken || readCookie('searchToken')
    : null;
  const jwtToken = useJWTToken
    ? tokens?.jwtToken || readCookie('jwtToken')
    : null;
  let headersToSend = {};
  if (accessToken) headersToSend.authorization = `Bearer ${accessToken}`;
  if (searchToken) headersToSend.searchToken = `Bearer ${searchToken}`;
  if (jwtToken) headersToSend.authorization = `Bearer ${jwtToken}`;
  if (!isFormData) headersToSend['Content-Type'] = 'application/json';

  headersToSend = { ...headersToSend, ...(headers || {}) };

  const isServer = isWorker || typeof window === 'undefined';
  if (url.includes(defaultHost)) {
    if (isWebView({ isServer })) {
      headersToSend['X-Platform'] = PLATFORM.ANDROID_BROWSER;
    } else {
      const platform =
        !isServer && isMobile({ isServer }) ? PLATFORM.MOBILE : PLATFORM.OTHER;
      headersToSend['X-Platform'] = platform;
    }
  }

  if (isServer) headersToSend['user-agent'] = 'unacademy-frontend-server';

  let bodyToSend;
  if (body) {
    try {
      bodyToSend = isFormData ? body : JSON.stringify(body);
    } catch {
      /* */
    }
  }

  if (!skipLoader) requestStatusStore.getState().setFetching(true);

  const res = await fetch(url, {
    method: requestType,
    headers: headersToSend,
    credentials: 'same-origin',
    body: bodyToSend
  })
    .then(checkAPIResponseStatus)
    .then((response) => {
      return response.status === 204 ? response : response.json();
    })
    .then((jsonifiedResponse) => {
      if (useCamelCase) return camelcaseKeys(jsonifiedResponse, { deep: true });
      return jsonifiedResponse;
    })
    .catch((err) =>
      reduxFreeFetcherErrorHandler({
        endpoint: url,
        error: err,
        tokens,
        options: { ...options, useAuthToken, useSearchToken, useJWTToken },
        headers: headersToSend,
        isWorker
      })
    );

  if (!skipLoader) requestStatusStore.getState().setFetching(false);

  return res;
}
