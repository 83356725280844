import { segment } from '@actions/analytics';
import { postUserLocationData } from '@api/fetchers/geocode';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import useConstants from '@api/hooks/useConstants';
import { getIsLoggedIn } from '@api/selectors/auth';
import { getLocationConfig } from '@api/selectors/constants';
import { useEffect } from 'react';

export default function useUserLocation() {
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { collectUserLocation, locationPrecision } =
    useConstants(getLocationConfig);
  const successCallback = async (postion) => {
    const { coords } = postion;
    const { latitude, longitude } = coords;
    let [beforeDecimal, afterDecimal] = latitude.toString().split('.');
    const rndLat = locationPrecision
      ? `${beforeDecimal}.${afterDecimal.substring(0, locationPrecision)}`
      : latitude;
    [beforeDecimal, afterDecimal] = longitude.toString().split('.');
    const rndLong = locationPrecision
      ? `${beforeDecimal}.${afterDecimal.substring(0, locationPrecision)}`
      : longitude;
    beforeDecimal = null;
    afterDecimal = null;
    const userPrevLoc = JSON.parse(localStorage.getItem('userLocation')) || {};
    if (
      isLoggedIn &&
      (userPrevLoc.lat !== rndLat || userPrevLoc.long !== rndLong)
    ) {
      localStorage.setItem(
        'userLocation',
        JSON.stringify({ lat: rndLat, long: rndLong })
      );
      postUserLocationData({
        lat: rndLat,
        long: rndLong
      });
      segment.track('Location - Permission Allowed', {
        lat: rndLat,
        long: rndLong
      });
    }
  };
  const errorCallback = () => {
    const permissionDenied = +localStorage.getItem('permissionDenied') || 0;
    if (!permissionDenied) {
      segment.track('Location - Permission Denied');
      localStorage.setItem('permissionDenied', 1);
    }
  };

  useEffect(() => {
    if (isLoggedIn && collectUserLocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, collectUserLocation]);
}
