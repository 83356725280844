export default function loadModule(fn) {
  return new Promise((resolve) => {
    const callback = () => fn.then(resolve);
    callback().catch(() => {
      setTimeout(() => {
        callback().catch(() => {
          // do nothing
        });
      }, 500);
    });
  });
}
