/* eslint-disable import/prefer-default-export */
export const FONTS = [
  // ------------ REGULAR FONTS -----------------
  'https://static.uacdn.net/prod/fonts/avertastd-regular.woff2',

  // ------------ BOLD FONTS -----------------
  'https://static.uacdn.net/prod/fonts/avertastd-bold.woff2',

  // ------------ SEMIBOLD FONTS -----------------
  'https://static.uacdn.net/prod/fonts/avertastd-semibold.woff2'
];
