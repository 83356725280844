import PropTypes from 'prop-types';

import { Spacings } from '@styles/index';

const Icon = ({
  width,
  height,
  color,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  path,
  size,
  children,
  viewBox,
  pathFillRule,
  pathClipRule,
  stroke,
  strokeWidth,
  strokeLinecap,
  strokeLinejoin,
  'data-testid': dataTestId
}) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox={viewBox}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={className}
    data-testid={dataTestId}
  >
    {path && (
      <path
        d={path}
        fill={color}
        fillRule={pathFillRule}
        clipRule={pathClipRule}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
      />
    )}
    {children}
  </svg>
);

export default Icon;

Icon.propTypes = {
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
  viewBox: PropTypes.string,
  pathFillRule: PropTypes.string,
  pathClipRule: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeLinejoin: PropTypes.string,
  'data-testid': PropTypes.string
};

Icon.defaultProps = {
  path: null,
  color: 'var(--color-base-fill)',
  size: Spacings.SPACING_3B,
  className: '',
  width: null,
  height: null,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  children: null,
  viewBox: '0 0 80 80',
  pathFillRule: 'unset',
  pathClipRule: 'unset',
  stroke: null,
  strokeWidth: null,
  strokeLinecap: 'butt',
  strokeLinejoin: 'miter',
  'data-testid': null
};
