import useFetch from '@hooks/useFetch';
import { emptyObject } from '@constants/empty';
import { processTopologyData } from '../../processors/topology';

const topologyMiddleware = (useSWRNext) => (key, fetcher, config) => {
  const swr = useSWRNext(key, fetcher, config);

  const processedCourseData = swr.data?.processed
    ? swr.data
    : processTopologyData(swr.data);

  return {
    ...swr,
    data: processedCourseData
  };
};

export const getKey = (topologyUID) =>
  topologyUID ? `v1/topology/users/${topologyUID}/self/` : null;

const useTopology = (topologyUID, options = {}) => {
  const key = topologyUID ? getKey(topologyUID) : null;

  const { data: topology, ...rest } = useFetch(
    key,
    {},
    {
      use: [topologyMiddleware],
      revalidateOnMount: true,
      ...options
    }
  );

  return {
    key,
    topology: topology || emptyObject,
    ...rest
  };
};

export default useTopology;
