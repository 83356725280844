/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getIsLoggedIn } from '@api/selectors/auth';
import {
  getBatchGroupSubscriptions,
  isBatchSubscriptionActive
} from '@api/selectors/subscription';
import { businessPlatform } from '@utils/constants';
import plusNavBarItems from '@layout/NavBar/PlusNavBar/plusNavBarItems';
import { getBatchSubscription } from '@models/Me';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import useSidenavStore from '@stores/sidenav';
import { getSelectedNav } from '@stores/selectors/sidenav';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import usePurchasedItems from '@api/hooks/auth/usePurchasedItems';
import useTopology from '@api/hooks/topology/useTopology';

const platformNav = [
  plusNavBarItems.SCHEDULE,
  plusNavBarItems.ENROLLMENTS_BATCH,
  plusNavBarItems.SAVED,
  plusNavBarItems.UPCOMING_BATCHES
];
const groupTypes = {
  PLATFORM: 'Platform',
  PLATINUM: 'Platinum'
};

const usePlatformInfo = (batch = {}) => {
  const {
    query: { goalUID: routeGoalUID, batchGroupUID, batchUID }
  } = useRouter();
  const batchID = batchGroupUID || batchUID;
  const selectedGoalUID = useSelectedGoalUID();
  const goalUID = routeGoalUID || selectedGoalUID;

  const { topology: goal } = useTopology(goalUID);
  const isGoalBelongsToPlatform = goal?.isPlatformGoal || false;
  const isBatchGroupSubscriptionActive = usePurchasedItems(
    isBatchSubscriptionActive(goalUID)
  );
  const batchGroupSubscriptions = usePurchasedItems(getBatchGroupSubscriptions);
  const { user_subscription_timeline = [{}] } =
    getBatchSubscription(batchGroupSubscriptions, goalUID) ?? {};
  const { time_remaining = 0 } = user_subscription_timeline[0] || {};
  const isSubscriptionActive = time_remaining > 0;

  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const groupType = batch?.groupType ?? 'NA';
  const selectedNav = useSidenavStore(getSelectedNav);

  return useMemo(() => {
    const info = {
      isPlatform: false,
      isPlatinum: false,
      businessPlatform: businessPlatform.UNACADEMY,
      showPlatformNav: false,
      hideWatchMinForPlatform: false,
      entityContentType: 'topology',
      isPlatformBatch: false,
      entityTitle: batch?.name ?? 'NA'
    };
    if (groupTypes.PLATFORM.includes(groupType) || isGoalBelongsToPlatform) {
      info.isPlatform = true;
      info.businessPlatform = businessPlatform.UA_PLATFORM;
      info.hideWatchMinForPlatform = true;
      info.entityContentType = 'Platform Batch Group';
      info.isPlatformBatch = true;
      if (
        platformNav.includes(selectedNav) &&
        isBatchGroupSubscriptionActive &&
        isLoggedIn
      ) {
        info.showPlatformNav = true;
      }
    } else if (groupTypes.PLATINUM.includes(groupType)) {
      info.isPlatinum = true;
      info.businessPlatform = businessPlatform.UA_PLATINUM;
      info.entityContentType = 'Platinum Batch Group';
    }
    return info;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupType,
    isGoalBelongsToPlatform,
    batchID,
    goalUID,
    isLoggedIn,
    selectedNav,
    isSubscriptionActive
  ]);
};

export default usePlatformInfo;
