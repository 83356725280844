import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { fetchABExperimentData } from '@api/fetchers/experiments';
import { showTTUOption } from '@api/fetchers/ttu';
import { abProxyJWT } from '@utils/abHelper';
import { getIsLoggedIn, getJWTToken } from '@api/selectors/auth';
import { getTTUFlag } from '@stores/selectors/ttuSelectors';
import { useIsMobile } from '@stores/AppCommonData';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import useTTUStore from '@stores/ttu';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';

const useTTU = () => {
  const { query, isReady } = useRouter();
  const selectedGoal = useSelectedGoalUID();
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const meJWT = useMyInfo(getJWTToken);
  const ttuFlag = useTTUStore(getTTUFlag);
  const isMobile = useIsMobile();

  const currentlyActiveGoalForTTU = useRef(null);

  useEffect(() => {
    const currentGoalUID = query.goalUID || selectedGoal;

    if (
      isReady &&
      currentGoalUID &&
      !isMobile &&
      currentlyActiveGoalForTTU.current !== currentGoalUID
    ) {
      currentlyActiveGoalForTTU.current = currentGoalUID;
      showTTUOption(currentGoalUID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal, query.goalUID, isMobile, isReady]);

  useEffect(() => {
    if (ttuFlag && !isMobile) {
      fetchABExperimentData(
        'inbound_call_web_1',
        isLoggedIn ? meJWT : abProxyJWT
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, ttuFlag, isMobile]);
};

export default useTTU;
