/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import Head from 'next/head';
import { NextSeo } from 'next-seo';
import Dangerous from '@base/Dangerous';
import escapeJSON from '@utils/escapeJSON';

export const SEOScript = ({ schema, sanitize = true }) =>
  schema
    ? Dangerous({
        html: sanitize ? escapeJSON(schema) : JSON.stringify(schema),
        type: 'application/ld+json',
        sanitize
      })
    : false;

const SEO = ({
  title,
  description,
  openGraph,
  url,
  schema,
  sanitizeSchema = true
}) => {
  const SEOTitle = title || "Unacademy -  India's largest learning platform";
  const SEODescription =
    description ||
    'Prepare for examinations and take any number of courses from various topics on Unacademy - an education revolution';
  return (
    <>
      <NextSeo
        title={SEOTitle}
        description={SEODescription}
        openGraph={openGraph}
      />
      <Head>
        {SEOTitle && (
          <>
            <title key="title">{SEOTitle}</title>
            <meta property="og:title" content={SEOTitle} key="og:title" />
          </>
        )}
        {SEODescription && (
          <>
            <meta
              name="description"
              content={SEODescription}
              key="description"
            />
            <meta
              property="og:description"
              content={SEODescription}
              key="og:description"
            />
          </>
        )}
        {url && <link key="canonical" rel="canonical" href={url} />}
        {SEOScript({ schema, sanitize: sanitizeSchema })}
      </Head>
    </>
  );
};

export default SEO;
