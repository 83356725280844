const themeIndependentColors = `
  --color-i-white: #FFFFFF;
  --color-i-gray-93: #ABC9C7;
  --color-i-gray-94: #B9DFDC;
  --color-i-gray-95: #CADDDC;
  --color-i-gray-96: #DCEFED;
  --color-i-gray-97: #D8E6E5;
  --color-i-gray-98: #F3F5F7;
  --color-i-gray-99: #FCFCFC;
  --color-i-white-99: #EBF6F5;
  --color-i-green-99: #8DCCC5;
  --color-i-all-black: #000000;
  --color-i-pitch-black: #000000;
  --color-i-light-gray: #FCFCFCF;
  --color-i-white-v2: #8698a6;
  --color-i-white-v3: #FEFEFE;
  --color-i-gray: #627984;
  --color-i-gray-2: #CACACA;
  --color-i-gray-v2: #F0F4F7;
  --color-i-light-green: #E6F8F2;
  --color-i-light-orange: #FFEBCE;
  --color-i-light-red: #FFDAE1;
  --color-i-light-blue: #E5EDFA;
  --color-i-transparent-blue: #EAF2FE;
  --color-i-violet: #BA61D4;
  --color-i-vanilla: #F08FB4;
  --color-i-black: #3C4852;
  --color-i-black-light-25: rgba(60, 72, 82, 0.3);
  --color-i-dark-gray: #333333;
  --color-i-golden: #FFF7E8;
  --color-i-dark-gray-blue: #364049;
  
  --color-i-green: #08bd80;
  --color-i-green-light: rgba(8, 189, 128, 0.10);
  --color-i-green-focus: #009965;
  --color-i-blue: #2d81f7;
  --color-i-red: #eb5757;
  --color-i-red-light: rgba(235, 87, 87, 0.05);
  --color-i-red-focus: #C53D3D;
  --color-i-orange: #FFAD3B;
  

  --color-i-icon-gold: #F4D978;
  --color-i-icon-timer-gold: #D2B85A;
  --color-i-icon-butter: #DABC4C;
  --color-i-icon-black: #222222;
  --color-i-icon-dark-base: #2A2B2D;
  --color-i-icon-dark-header: #0909095c;
  --color-i-icon-dark-primary: #101010;
  --color-i-icon-dark-primary-transparent: #101010F2;
  --color-i-icon-light-hover: #2e2e2e;
  --color-i-icon-shadow: #3b3838bf;
  --color-i-icon-dark: #181919;
  --color-i-icon-dark-button: #242526;
  --color-i-icon-dark-secondary: #A2A0A2;
  --color-i-icon-dark-tertiary: #121212;
  --color-i-icon-dark-supporting: #424244;
  --color-i-icon-dark-supporting-secondary: #151515;
  --color-i-icon-light-tertiary: #F4F4F3;
  --color-i-icon-tacha: #CDB660;
  --color-i-icon-loader: #9098A3BF;
  --color-i-icon-progress: #FCFCFC30;
  --color-i-icon-overlay: #080808f2;
  --color-i-icon-gradient-1: #AA823D;
  --color-i-icon-gradient-2: #EFE288;
  --color-i-icon-gradient-3: #D1B85A;
  --color-i-icons-gradient: linear-gradient(261.69deg, #AA823D -0.19%, #EFE288 59.57%, #D1B85A 85.24%);
  --color-i-icons-skeleton-gradient: linear-gradient(90deg, rgba(0, 0, 0, 0),rgba(69, 64, 64, 0.23), rgba(0, 0, 0, 0));
  --color-i-icon-divider: #2A2B2D;
  --color-i-icon-base-fill: #F8F8F8;
  --color-i-icon-light-border: #2a2b2d6b;

  --color-i-accent-green: #08bd80;

  --color-i-text-black: #808080;
  --color-i-test: #4BB0D0;
  --color-i-combat: #2D81F7;
  --color-i-lmp: #5054cf;
  --color-i-silver: #837D8A;
  --color-i-light-silver: #E9E5EE;
  --color-i-divider: #e9eef2;
  --color-i-class: rgba(80, 84, 207, 0.15);
  --color-i-notes: rgba(255, 148, 115, 0.15);
  --color-i-practice: rgba(25, 154, 162, 0.15);
  --color-i-test-pink: rgba(234, 90, 143, 0.15);
  --color-i-combat-blue: rgba(60, 127, 250, 0.15);
  --color-i-white-transparent: rgba(252, 252, 252, 0.843);
  --color-i-winter-logo-blue: #003c8f;

  --color-i-facebook: #3B5998;
  --color-i-twitter: #00ACEE;
  --color-i-linkedIn: #0072B1;

  --color-i-light-gray: #FCFCFCF;
  --color-i-white-v2: #8698a6;
  --color-i-gray: #627984;
  --color-i-gray-v2: #F0F4F7;
  --color-i-gray-1: #a0a0a0;
  --color-i-gray-2: #c4c4c4;
  --color-i-gray-3: #9AAFBF;
  --color-i-gray-4: #EAEDF4;
  --color-i-gray-5: #D4DCE2;
  --color-i-gray-6: #F3F9FE;
  --color-i-gray-7: #F7F9FA;
  --color-i-gray-8: #94a8b3;
  --color-i-gray-9: #F4F4F3;
  --color-i-gray-10: #f8fafd;
  --color-i-gray-11: #DEE6EB;
  --color-i-gray-12: #828282;
  --color-i-gray-13: #7A8B94;
  --color-i-gray-14: #dde6ed;
  --color-i-gray-15: #7893a7;
  --color-i-gray-16: #EEEEEE;
  --color-i-gray-17: #6d6c84;
  --color-i-gray-18: #636D75;
  --color-i-gray-19: #f4f4f4;
  --color-i-gray-20: #babac0;
  --color-i-gray-21: #a0a0a5;
  --color-i-gray-22: #f0f0f0;
  --color-i-gray-23: #6d6d6d;
  --color-i-gray-24: #a0a096;
  --color-i-gray-25: #bcc5c9;
  --color-i-gray-26: #e1e0e0;
  --color-i-gray-27: #ededed;
  --color-i-gray-light-25: rgba(188, 197, 201, 0.3);
  --color-i-gray-28: #f8f8f8;
  --color-i-gray-29: #eBeBe8;
  --color-i-gray-30: rgba(0, 0, 0, 0.7);
  --color-i-gray-31: #d9d9d9;
  --color-i-gray-32: rgba(134, 152, 166, 0.12);
  --color-i-gray-33: rgba(255, 173, 59, 0.12);
  --color-i-gray-34: rgba(255, 255, 255, 0.14);
  --color-i-gray-35: #ebebeb;
  --color-i-gray-36: #f5f8fc;
  --color-i-gray-37: rgba(233, 238, 242, 0);
  --color-i-gray-38: #8B8B8B;
  --color-i-gray-39: #849295;
  --color-i-gray-40: #54748C;
  --color-i-gray-41: #f5f5f5;
  --color-i-gray-42: #8FA3B2;

  --color-i-violet-1: #7b8ab5;
  --color-i-violet-2: #493aaf;
  --color-i-violet-3: #ebe8ff;
  --color-i-violet-4: #5054CF;
  --color-i-violet-5: #4939ad;
  --color-i-violet-6: #eae7fa;
  --color-i-violet-7: #7b8ab5;
  --color-i-violet-8: #162e6d;
  --color-i-violet-9: #5754DB;
  --color-i-violet-10: #7D57EB;
  --color-i-violet-11: #F4F1FF;
  --color-i-violet-12: #7260E0;
  --color-i-violet-13: #9762f6;
  --color-i-violet-14: #8873FF;
  --color-i-violet-15: #9875FF;
  --color-i-violet-16: #070D1E;
  --color-i-violet-light: #B3C6F2;
  --color-i-violet-dark: #00154a;
  --color-i-violet-dark-1: #f7f9fb;
  --color-i-violet-light-1: #1f3262;
  --color-i-violet-17: #EDDEFF;
  --color-i-violet-18: #4B138F;
  --color-i-violet-19: #5816A9;
  --color-i-violet-20: #DFDEFF;
  --color-i-violet-21: #3B077A;
  --color-i-violet-22: #071437;
  --color-i-voilet-23: #745ae3;
  --color-i-voilet-24: rgba(45, 129, 247, 0.07);
  --color-i-voilet-25: #7B2C57;


  --color-i-blue: #2d81f7;
  --color-i-blue-1: #a9d4ff;
  --color-i-blue-2: #76baff;
  --color-i-blue-3: #1087ff;
  --color-i-blue-4: #1380d0;
  --color-i-blue-5: #e8f1ff;
  --color-i-blue-6: #f7faff;
  --color-i-blue-7: #3878D4;
  --color-i-blue-8: rgba(150, 194, 255, 0.8);
  --color-i-blue-9: #81b5ff;
  --color-i-blue-10: #f1f9ff;
  --color-i-blue-11: #f2f7fa;
  --color-i-blue-12: #54A4FF;
  --color-i-blue-13: rgba(229, 244, 255, 0.44);
  --color-i-blue-14: #eaf2fe;
  --color-i-blue-15: #eef6ff;
  --color-i-blue-16: #10A5D4;
  --color-i-blue-17: #5F8CF3;
  --color-i-blue-18: #408cf7;
  --color-i-blue-19: #1c76f3;
  --color-i-blue-20: #46BBE7;
  --color-i-blue-21: #0078d7;
  --color-i-blue-22: #f2f8ff;
  --color-i-blue-23: #086FFF;
  --color-i-blue-24: #20496b;
  --color-i-blue-25: #49BBDE;
  --color-i-blue-26: #ECF4FE;
  --color-i-blue-27: rgba(45, 129, 247, 0.1);
  --color-i-blue-28 : #d2e4fe;
  --color-i-blue-29 : #116ae8;
  --color-i-blue-30 : #CEE4FF;
  --color-i-blue-31 : #073D7F;
  --color-i-blue-32: #C2D8F3;
  --color-i-blue-33: #4A74BE;
  --color-i-blue-34: #3CAAEB;
  --color-i-blue-35 : #57b4f8;
  --color-i-blue-36: #C3D5E3;
  --color-i-blue-37: #E9F2FF;
  --color-i-blue-38: #209AB5;
  --color-i-blue-39: #069AB8;
  --color-i-blue-40: #3F51b5;
  --color-i-blue-41: #f8f8ff;
  --color-i-blue-42: rgba(32, 159, 177, 0.1);
  --color-i-blue-43: #004D81;
  --color-i-blue-44: #0D1837;
  --color-i-blue-45: #0D4491;
  --color-i-blue-46: #5873FF;
  --color-i-blue-47: #2d81f71a;
  --color-i-blue-48: #E6F0FE;

  --color-i-orange-1: #FFAD3B;
  --color-i-orange-2: #F19731;
  --color-i-orange-3: #FFEBCE;
  --color-i-orange-4: #ffeed7;
  --color-i-orange-5: #ffdc96;
  --color-i-orange-6: #fff0d0;
  --color-i-orange-7: #fff6e2;
  --color-i-orange-8: #fae3bd;
  --color-i-orange-9: #ffedd3;
  --color-i-orange-10: #fffbeb;
  --color-i-orange-11: #FFB800;
  --color-i-orange-12: #FDD0A8;
  --color-i-orange-13: #FFAA00;
  --color-i-orange-14: #FFF7EC;
  --color-i-orange-15: #EBD3A7;
  --color-i-orange-16: rgba(255, 173, 59, 0.12);
  --color-i-orange-17: rgba(233, 149, 23, 0.1);
  --color-i-orange-18: #FFF9F0;
  --color-i-orange-19: #FFF0DE;
  --color-i-orange-20: #F4D199;
  --color-i-orange-21: #FFF6EA;
  --color-i-orange-22: #ff9502;
  --color-i-orange-23: #833D0F;
  --color-i-orange-24: #FDC473CC;

  
  --color-i-yellow-1: #F1C119;
  --color-i-yellow-2: #FFE294;
  --color-i-yellow-3: #fff2d7;
  --color-i-yellow-4: #FDB858;
  --color-i-yellow-5: #FF9706;
  --color-i-yellow-6: #FFF3C9;
  --color-i-yellow-7: #CBA678;
  --color-i-yellow-8: #FCF8E7;
  --color-i-yellow-9: #fffefc;
  --color-i-yellow-10: #E79422;
  --color-i-yellow-13: #FFDA7C;
  --color-i-yellow-14: #F1B334;
  

  --color-i-pink-1: #fdeeee;
  --color-i-pink-2: #FFDAE1;
  --color-i-pink-3: #ffcdcd;
  --color-i-pink-4: #fe7b7c;
  --color-i-pink-5: #FFF4F4;
  --color-i-pink-6: #FDEFEF;
  --color-i-pink-7: #ff8080;


  --color-i-red-focus: #C53D3D;
  --color-i-red: #eb5757;
  --color-i-red-1: #F67474;
  --color-i-red-2: #EA6060;
  --color-i-red-3: #EB5757;
  --color-i-red-4:  #EB57571A;

  --color-i-brown-1: #B67245;
  --color-i-brown-2: #905318;
  --color-i-brown-3: #ab8355;
  --color-i-brown-4: #6C2A18;
  --color-i-brown-5: #6C520B;
  --color-i-brown-6: rgba(255, 173, 59, 0.2);
  --color-i-brown-7: #522C2C;
  --color-i-brown-8: #5D3402;
  --color-i-brown-9: #594602;
  --color-i-brown-10: #93550A;
  --color-i-brown-11: #885626;
  --color-i-brown-12: rgba(255, 173, 59, 0.1);
  --color-i-brown-13:  #DE7800;
  --color-i-brown-14: #CD691E;


  --color-i-turquoise-1: #21C5C6;
  --color-i-turquoise-2: #17AEB5;
  --color-i-turquoise-3: #a9f4fc;
  --color-i-turquoise-4: #199AA2;
  --color-i-turquoise-5: #01BCDB;
  --color-i-turquoise-6: #1FC1C1;

  --color-i-light-green: #E6F8F2;
  --color-i-green: #08bd80;
  --color-i-green-focus: #009965;
  --color-i-accent-green: #08bd80;
  --color-i-quiz: #04AA82;
  --color-i-green-1: #eefff7;
  --color-i-green-2: #cdfbe5;
  --color-i-green-3: #93B112;
  --color-i-green-4: #079364;
  --color-i-green-5: #62DBB2;
  --color-i-green-6: #E3F5EF;
  --color-i-green-7: #01B196;
  --color-i-green-8: #199AA2;
  --color-i-green-9: #1CC18A;
  --color-i-green-10: #E7F9F3;
  --color-i-green-11: #44A182;
  --color-i-green-12: rgba(8, 189, 128, 0.1);
  --color-i-green-13: #CAFFFF;
  --color-i-green-14: #086969;
  --color-i-green-15: #D5FFEB;
  --color-i-green-16: #00312A;
  --color-i-green-17: #008E7E;
  --color-i-green-18: #0099651A;

  --color-i-pitch-black: #000000;
  --color-i-black-1: #202022;
  --color-i-black-2: #0b1a3e;
  --color-i-black-3: #1f2432;
  --color-i-black-4: #071128;
  --color-i-black-5: #2c2c2e;
  --color-i-black-6: rgba(11, 11, 11, 0.4);
  --color-i-black-7: #2C3236;
  --color-i-black-8: #404040;
  --color-i-black-9: #01100D;
  --color-i-black-10: #00000080;

  --color-i-gold-1: #FED05C;
  --color-i-gold-2: #FDF5E4;
  --color-i-gold-3: #FFE6B2;
  --color-i-gold-4: #FFF8E0;

  --color-i-silver-1: #F8FBFD;
  --color-i-silver-2: #E2EDF4;

  --color-i-highlight: #FFEEC4;
  --color-i-current-highlight: #FBD39B;
  --color-i-yellow-11: #fffaf3;
  --color-i-yellow-12: #fef4e6;
  --color-i-orange-light: #FFAD3B;
  --color-i-orange-streaks: #FFB54E;
  --color-i-orange-dark: #f99d47;
  --color-i-blue-streaks: #34A4FF;

  --color-rank-pill-1: #B2863B;
  --color-rank-pill-2: #45889F;
  --color-rank-pill-3: #A46840;
  --color-rank-pill-4: #6B6D9C;

`;

export default themeIndependentColors;
