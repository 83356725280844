import { NOT_APPLICABLE } from '@constants/segment';

// Page Info Selectors
export const getPageInfo = ({ currentPageInfo }) => currentPageInfo;

export const getSetPageLPS = ({ setPageLPS }) => setPageLPS;

export const getNewTraversalPath = ({ currentPageInfo }) => {
  const route = currentPageInfo?.routePathList;
  if (!route || route.length === 0) return NOT_APPLICABLE;
  return route;
};

export const getCurrentPageName = ({ currentPageInfo }) => {
  return currentPageInfo?.name?.trim() || '';
};

// Is Mobile Selectors
export const getIsMobile = ({ isMobile }) => isMobile;

// Page SEO Content Selectors
export const getPageSEOContent = ({ seoContent }) => seoContent || {};

// Preview Mode Selector
export const getIsPreviewMode = ({ nextPreviewMode }) => nextPreviewMode;
export const getSetPreviewMode = ({ setPreviewMode }) => setPreviewMode;

// Selected Goal Uid Selectors
export const getSelectedGoalUid = ({ selectedGoalUid }) => selectedGoalUid;

// Login Process Completed
export const getIsLoginProcessCompleted = ({ loginProcessCompleted }) =>
  loginProcessCompleted;
export const getMarkLoginProcessCompleted = ({ setLoginProcessCompleted }) =>
  setLoginProcessCompleted;

export const getOnboardingPreferencesUpdated = (goalUID) => (state) =>
  state.areGoalOnBoardingPreferencesUpdated[goalUID] || false;
