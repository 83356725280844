const loadAndSetupSegment = (writeKey) => {
  // Create a queue, but don't obliterate an existing one!
  // var analytics = window.analytics = window.analytics || [];
  window.analytics = window.analytics || [];
  const { analytics: windowAnalytics } = window;
  const analytics = windowAnalytics;
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && window.console.error) {
      window.console.error('Segment snippet included twice.');
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  // eslint-disable-next-line func-names
  analytics.factory = function (method) {
    // eslint-disable-next-line func-names
    return function () {
      // eslint-disable-next-line prefer-rest-params
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // eslint-disable-next-line no-underscore-dangle
  analytics._writeKey = writeKey;
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.15.2';
};

export default loadAndSetupSegment;
