import { ICONS_GOALS_KEY } from '@constants/unacademyIcons/icons.constants';

export const isEmailLoginCountry = (countryCode) => (constants) => {
  const emailLoginCountries = constants?.EMAIL_LOGIN_ALLOWED_COUNTRIES ?? [];
  return emailLoginCountries.includes(countryCode);
};

export const getSocketConstants = (constants) => constants?.socketConfig;

export const getPlannerSyncInterval = (constants) =>
  constants?.plannerSyncInterval;

export const getPlannerSamplingFrequency = (constants) =>
  (constants?.PLANNER_THROTTLE_INTERVAL_WEB_IN_SEC ?? 5) * 1000;

export const getIsPlannerHeartbeatEnabled = (constants) =>
  constants?.PLANNER_HEARTBEAT_ENABLE ?? false;

export const getPlannerUpdateThrottleValues = (constants) =>
  constants?.PLANNER_RANDOMISATION_INTERVAL_WEB_IN_MILLIS;

export const getIsStargateEnabledForGoal = (goalUID) => (constants) => {
  const stargateConfig = getSocketConstants(constants) ?? {};
  const stargateEnabledGoals = stargateConfig.enabledGoals ?? [];
  return stargateEnabledGoals.includes(goalUID);
};

export const isUAIconsEnabled = (constants) => {
  const iconsGoalsList = constants?.[ICONS_GOALS_KEY];
  return Array.isArray(iconsGoalsList) && iconsGoalsList.length > 0;
};

export const getIsGTPGoal = (goalUID) => (constants) => {
  const gtpGoalsList = constants?.GTP_GOALS || [];
  return gtpGoalsList.includes(goalUID);
};

export const getIsTestRevampGoal = (goalUID) => (constants) => {
  const list = constants?.TEST_EXPERIENCE_V2_GOALS ?? [];
  return list?.includes(goalUID) ?? false;
};

export const getIsCompeteEnabled = (goalUID) => (constants) => {
  const competeList = constants?.COMPETE_GOALS ?? [];
  const IsCompeteEnabled = constants?.COMPETE_ENABLED_FOR_WEB;
  return (competeList.includes(goalUID) && IsCompeteEnabled) ?? false;
};
// selector for recorded content play allowed in the website
export const getIsRCPlayAllowed = (goalUID) => (constants) => {
  const list = constants?.RECORDED_CONTENT_PLAY_ALLOWED ?? [];
  return list?.includes(goalUID) ?? false;
};

export const getIsEducatorPlaylistABAllowed = (uid) => (constants) => {
  const list = constants?.EDUCATOR_CURATED_PLAYLIST_AB ?? [];
  return list?.includes(uid) ?? false;
};

export const getIsPostClassRecommendationEnabled = (goalUID) => (constants) => {
  const constant = constants?.POST_SPECIAL_CLASS_RECOMMENDATIONS ?? {};
  if (constant.IS_ENABLED_FOR_ALL_GOALS) return true;

  return constant.ENABLED_GOALS?.includes(goalUID) ?? false;
};

export const getAnalyticsEventInterval = (constants) =>
  constants?.CLASS_ANALYTICS_EVENT_INTERVAL;

export const getLocationConfig = (constants) => ({
  collectUserLocation: constants?.CLIENT_COLLECT_USER_LOCATION || false,
  locationPrecision: constants?.CLIENT_COLLECT_USER_LOCATION_PRECISION
});
