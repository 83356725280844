const plusNavBarItems = {
  PLUS_HOME: 'plus',
  SCHEDULE: 'planner',
  SAVED: 'saved',
  NOTES: 'notes',
  ENROLLMENTS: 'enrolled',
  ENROLLMENTS_BATCH: 'enrolled-batches',
  SYLLABUS: 'topics',
  DOUBTS_AND_SOLUTIONS: 'solutions',
  FREE_PLATFORM: 'free-platform',
  FREE_CLASSES_AND_TESTS: 'classes',
  TEST_SERIES: 'test-series',
  PRACTICE: 'practice',
  COMPETE: 'compete',
  // support links
  HELP_AND_SUPPORT: 'help-and-support',
  GET_THE_APP: 'get-the-app',
  OTHER_LINKS: 'other-links',
  REPORT_AN_ISSUE: 'report-an-issue',
  BROWSE: 'browse',
  ONE_TO_ONE_LIVE_MENTORSHIP: 'one-to-one-livementorship',
  ASK_A_DOUBT: 'ask-a-doubt',
  UPCOMING_BATCHES: 'upcoming-batches',
  VIDEO_LESSONS: 'video-lessons',
  PRINTED_NOTES: 'printed-notes',
  PLAYLIST: 'Playlist',
  Q_BANK: 'Q Bank',
  STORE: 'store'
};

export default plusNavBarItems;
