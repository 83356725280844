import clearCookiesOnSignout from './clearUserRelatedCookies';
import { clearLocalStorage, localStorageAvailable } from './localStorage';

const signOutUser = (redirectTo = '/', cookieClearingOptions = {}) => {
  clearCookiesOnSignout(cookieClearingOptions);
  window?.analytics?.reset?.();
  if (localStorageAvailable()) {
    clearLocalStorage();
    if (redirectTo)
      localStorage?.setItem('logoutuserfromalltabs', Math.random()); // Send a signal to all other tabs that user has logged out, and redirect the current tab.
  }
  // Clear Indexed DB
  const onClearCallback = () => {
    // Hard Refresh to clear redux store, after clearing the indexedDB, since clearing IndexedDB using LocalForage is an async operation.
    if (window.indexedDB) window.indexedDB.deleteDatabase('planner');
    if (!redirectTo) return;
    window.location.replace(redirectTo);
  };
  if (window.indexedDB) window.indexedDB.deleteDatabase('planner');
  window.setTimeout(onClearCallback, 750);
};

export default signOutUser;
