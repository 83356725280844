import socketMessageHandler from '@utils/SocketManager/socketMessageHandler/SocketMessageHandler';
import { ISocketsConfig } from 'types/sockets';
import { captureException } from '@utils/sentry';
import { updatePlannerDiagnosticsData } from '@cont/Planner/planner-utils/diagnostics/collector';

export interface ISocketManager {
  sendDataOnSocket(json: any): void;
  getISWSRunning(): boolean;
  close(): void;
  retry(): void;
}

class SocketManager implements ISocketManager {
  #ws: WebSocket;

  #stargateJwtToken: string;

  #socketConfig: ISocketsConfig;

  #socketConnectionRetryCount: number;

  #goalEventProps: any;

  #reconnectTimeout: ReturnType<typeof setTimeout>;

  #refetchMyInfo: any;

  constructor(
    stargateJwtToken: string,
    socketConfig: ISocketsConfig,
    goalEventProps: any,
    refetchMyInfo: any
  ) {
    this.#ws = null;
    this.#reconnectTimeout = undefined;
    this.#socketConnectionRetryCount = 0;
    this.#stargateJwtToken = stargateJwtToken;
    this.#socketConfig = socketConfig;
    this.#goalEventProps = goalEventProps;
    this.#refetchMyInfo = refetchMyInfo;

    if (this.#stargateJwtToken && this.#socketConfig) {
      setTimeout(this.#connect, 1000);
    }
  }

  #connect = () => {
    const { socketDomain } = this.#socketConfig;
    this.#ws = new WebSocket(
      `wss://${socketDomain}/ws/?token=${this.#stargateJwtToken}&platform=web`
    );

    this.#ws.onclose = this.#onClose;
    this.#ws.onopen = this.#onOpen;
    this.#ws.onmessage = this.#onMessage;
    this.#ws.onerror = this.#onError;
    this.#updateDiagnosticData(true);
  };

  #onOpen = () => {
    this.#socketConnectionRetryCount = 0;
    this.#updateDiagnosticData(true);
  };

  #onMessage = (event) => {
    const message = event.data;
    const { cmd_subtype: type, cmd: command, data } = JSON.parse(message);
    socketMessageHandler(
      { command, type, data },
      this,
      this.#goalEventProps,
      this.#refetchMyInfo,
      this.#stargateJwtToken
    );
  };

  #onClose = (event) => {
    clearTimeout(this.#reconnectTimeout);
    this.#updateDiagnosticData(false);
    this.#removeSocketListeners();
    if (!event.code || event.code !== 4001) {
      const socketRetryInterval = this.#socketConfig?.socketRetryInterval;
      const socketRetryCount = this.#socketConfig?.socketRetryCount;

      if (this.#socketConnectionRetryCount < socketRetryCount) {
        this.#reconnectTimeout = setTimeout(() => {
          this.#connect();
          this.#socketConnectionRetryCount =
            this.#socketConnectionRetryCount + 1;
        }, socketRetryInterval);
      }
    }
  };

  #onError = () => {};

  #removeSocketListeners() {
    if (this.#ws) {
      this.#ws.onopen = null;
      this.#ws.onclose = null;
      this.#ws.onmessage = null;
      this.#ws.onerror = null;
    }
  }

  #updateDiagnosticData(isWSRunning = false) {
    try {
      const diagnosticPayload: any = {
        isWSRunning,
        isWSActive: this.getISWSRunning()
      };
      if (this.#ws) diagnosticPayload.wsReadyState = this.#ws?.readyState ?? -1;
      updatePlannerDiagnosticsData(diagnosticPayload);
    } catch (err) {
      captureException(new Error('Failed to send diagnosticPayload'));
    }
  }

  // Public Methods

  sendDataOnSocket(json) {
    if (this.#ws) {
      const payload = JSON.stringify(json);
      this.#ws.send(payload);
    }
  }

  close() {
    this.#updateDiagnosticData(false);
    this.#removeSocketListeners();
    const isDeliberate = this.#ws && this.#ws.close;
    if (isDeliberate) {
      this.#ws.close(4001, 'Deliberately closed');
    }
  }

  retry() {
    this.close();
    this.#connect();
  }

  getISWSRunning() {
    if (!this.#ws) return false;
    return (
      this.#ws.readyState !== WebSocket.CLOSED &&
      this.#ws.readyState !== WebSocket.CLOSING
    );
  }
}

export default SocketManager;
