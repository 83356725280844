/* eslint-disable max-lines */
export const CATALOG_BOOK = 'text_book';
export const CATALOG_CHAPTER = 'chapter';
export const PAPER_SET = 'paper_set';
export const catalog = {
  [CATALOG_BOOK]: 1,
  [PAPER_SET]: 2
};

export const contentTypes = {
  PLUS_COURSES: 'plus-courses',
  TEST_SERIES: 'test-series',
  SPECIAL_CLASSES: 'special-classes'
};

export const TEST_TYPES = [
  { label: 'Full Syllabus Test', value: 1 },
  { label: 'Minor Test', value: 2 },
  { label: 'Previous Year Papers', value: 3 },
  { label: 'Revision Test', value: 5 }
];

export const TEST_FREQUENCY = [
  { label: 'Once a day', value: 1 },
  { label: 'Once a week', value: 2 },
  { label: 'Once in 2 weeks', value: 3 },
  { label: 'Once a month', value: 4 },
  { label: 'Twice a week', value: 5 },
  { label: 'Twice a month', value: 6 },
  { label: 'Thrice a week', value: 7 },
  { label: 'Thrice a month', value: 8 }
];

export const BACKEND_API_ERROR = 'Something went wrong.Please try again later';

export const ExEducatorDescription =
  'The educator is no longer associated with Unacademy. Their courses and quizzes can still be accessed from their profile';

export const inboundCallNumber = '+91 8585858585';

export const goalListForInboundCall = ['TMUVD', 'YOTUH']; // now only for IIT jEE & NEET UG

export const catalogEntityTypes = {
  CHAPTER: 'chapter',
  PAPER: 'paper'
};

export const myLibrarySourceType = {
  COURSE: 4,
  LESSON: 5
};

export const PlusTopologySource = {
  TOPICS: 'TOPICS',
  TOPIC_FEED: 'TOPIC_FEED',
  TOPIC_COURSES: 'TOPIC_COURSES'
};

export const languageTypes = {
  SPOKEN: 1,
  CONTENT_SLIDE_LANGUAGE: 2
};

export const staticStrings = {
  UNENROLL_MESSAGE:
    'This course will be removed from your schedule and you will stop receiving updates related to this course',
  UNENROLL_MESSAGE_FOR_COURSES:
    'Course will be added to planner within a few minutes',
  UNENROLL_MESSAGE_FOR_BATCHES:
    'Batch will be added to planner within a few minutes',
  UNENROLL_MESSAGE_FOR_LITE_COURSES: 'Enrolled successfully',
  UNENROLL_MESSAGE_FOR_RECORDED_GOALS: 'Enrolled successfully'
};

export const LEARNER_APP_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.unacademyapp&referrer=utm_source%3DFooter%26utm_medium%3DWebApp%26utm_campaign%3DFooter%26';

export const subscriptionTypes = {
  PLUS: 1,
  TRIAL: 2,
  ICONIC: 5,
  COMBO: 6,
  LITE: 7,
  PLATFORM: 8,
  STORE: 10,
  CENTRE: 20
};

export const centreContentTypes = {
  STANDALONE_CENTRE: 503,
  ALL_CENTRE: 504,
  CENTRE_COMBO: 601,
  PLUS: 116,
  CENTRE_REGISTRATION: 505
};

export const getContentTypeName = (contentType) => {
  const keys = Object.keys(centreContentTypes);
  const keyVal = keys.find((key) => centreContentTypes[key] === contentType);
  return keyVal ? keyVal.replace(/_/g, ' ') : null;
};

export const isCentreSubscription = (type, contentType) => {
  return (
    type === subscriptionTypes.STORE &&
    Object.values(centreContentTypes).indexOf(+contentType) !== -1
  );
};

export const subscriptionSubTypes = {
  STANDARD: 1, // what we show to everyone
  NON_STANDARD: 2, // what we show to upgrade flow
  OFFLINE: 3
};

export const subscriptionSubTypesObj = {
  [subscriptionSubTypes.STANDARD]: 'Standard',
  [subscriptionSubTypes.NON_STANDARD]: 'Non Standard',
  [subscriptionSubTypes.OFFLINE]: 'Offline'
};
// for offline they will add a new type, type 20
// right now type 1, subtype 3 - for offline
export const subscriptionTypesObj = {
  [subscriptionTypes.PLUS]: 'Plus',
  [subscriptionTypes.ICONIC]: 'Iconic',
  [subscriptionTypes.COMBO]: 'Combo',
  [subscriptionTypes.LITE]: 'Lite',
  [subscriptionTypes.PLATFORM]: 'Platform',
  [subscriptionTypes.TRIAL]: 'Trial',
  [subscriptionTypes.CENTRE]: 'Offline',
  [subscriptionTypes.STORE]: 'Store'
};

export const DEFAULT_LANGUAGE = [
  { code: 1, name: 'English', display_name: 'English', language_code: 1 }
];

export const LibrarySeeAllSource = {
  CONTINUE_LEARNING: 'continue-learning',
  SAVED_COURSES: 'saved-courses',
  SAVED_LESSONS: 'saved-lessons',
  WATCH_HISTORY: 'watch-history'
};

export const courseStates = {
  CREATED: 0,
  REVIEW: 5,
  UPCOMING: 7,
  LIVE: 10,
  COMPLETED: 20,
  PUBLIC: 30,
  TESTING: 35,
  DORMANT: 40,
  DELETED: 50
};

export const courseStateEventLabels = {
  [courseStates.CREATED]: 'Created',
  [courseStates.REVIEW]: 'Under Review',
  [courseStates.UPCOMING]: 'Upcoming',
  [courseStates.LIVE]: 'Live',
  [courseStates.COMPLETED]: 'Completed',
  [courseStates.PUBLIC]: 'Public',
  [courseStates.TESTING]: 'Testing',
  [courseStates.DORMANT]: 'Dormant',
  [courseStates.DELETED]: 'Deleted'
};

export const programmeTypes = {
  COURSE: 1,
  TESTSERIES: 2,
  CONTEST: 3,
  RECORDED: 4,
  PLATFORM_COURSE: 5,
  PLATFORM_TEST_SERIES: 6,
  PLATINUM_COURSE: 7,
  PLATINUM_TEST_SERIES: 8,
  HYBRID_COURSE: 9,
  HYBRID_TESTSERIES: 10,
  HYBRID_NON_OFFLINE: 11,
  ONLINE_WITH_EXTERNAL_STREAM: 12
};

export const batchTypes = {
  REGULAR: 1,
  VIDEO_LIBRARY: 2,
  DEFAULT: 3,
  PLATFORM_BATCH: 4,
  PLATINUM_BATCH: 5,
  CENTRE_BATCH: 6
};

export const programmeSubTypes = {
  FULL_SYLLABUS_TEST: 1,
  MINOR_TEST: 2,
  PREVIOUS_YEAR_PAPERS: 3,
  TA_COURSE: 4
};

export const SESSION_STATE_MAPPINGS = {
  quiz: {
    10: 'Created',
    20: 'Editing',
    30: 'Under Review',
    40: 'Published',
    100: 'Deleted'
  },
  post: {
    0: 'Created',
    5: 'Under Review',
    7: 'Upcoming',
    10: 'Live',
    15: 'Recorded',
    20: 'Completed',
    30: 'Public',
    35: 'Testing',
    40: 'Dormant',
    50: 'Deleted'
  }
};

export const quizSecondaryType = {
  QUIZ: 1,
  TEST: 2,
  UNDEFINED: 0
};

export const TEST_CONTENT_STATE = {
  TEMPLATE_PENDING: 1,
  ADD_QUESTION: 5,
  EDIT_QUESTION: 10,
  QUESTION_COMPLETED: 20
};

export const QUIZ_CONTENT_STATE = {
  CREATE_QUIZ: 15,
  ADD_QUESTION: 5,
  EDIT_QUESTION: 10,
  QUESTION_COMPLETED: 20
};

export const topologyLevels = {
  CATEGORY: 5,
  GOAL: 10,
  TOPIC: 15,
  CONCEPT: 20,
  SUBCONCEPT: 23,
  CONCEPT_FIELD: 25,
  GROUP: 30
};

export const testTypes = {
  OBJECTIVE: 1,
  SUBJCETIVE: 3,
  CONTEST: 5,
  DUMMY_ENTRY: 7
};

export const testReviewStates = {
  PENDING: 10,
  COMPLETED: 20
};

export const bannerStates = {
  GENERIC: 0,
  LEGENDS: 1,
  ITEM: 2,
  TEST_SERIES: 3,
  RENEWAL: 4,
  COMBAT: 5
};
export const testEvaluationStates = {
  CREATED: 10,
  ASSIGNED: 20,
  SKIPPED_EVALUATION: 30,
  SCORES_GIVEN: 40,
  COMPLETED: 50,
  DELETED: 100
};

export const calculatorType = {
  CALCULATOR_NOT_SELECTED: -1,
  NORMAL_CALC: 1,
  SCIENTIFIC_CALC: 2
};

export const savedObjectTypes = {
  LESSON: 1,
  TEST: 2,
  QUESTION: 3,
  NOTES: 4
};

export const quizTypes = {
  QUIZ: 1,
  PRACTICE_SESSION: 2,
  SUBJECTIVE_TEST: 3,
  HOSTED: 4,
  DUMMY_ENTRY: 7
};

export const eduOnboardingFormStatus = {
  NEW: 0,
  UNDER_REVIEW: 10,
  ACCEPTED: 40,
  REJECTED_CAN_REAPPLY: 20,
  REJECTED: 30
};

export const eduOnboardDemoVideoState = {
  CREATED: 0,
  RECORDING_IN_PROGRESS: 10,
  RECORDING_FINISHED: 20,
  RECORDING_SUBMITTED: 25,
  VIDEO_PROCESSING: 30,
  VIDEO_PROCESSING_FAILED: 40,
  DELETED: 50,
  FINISHED: 60
};

export const eduOnboardingQuestionTypes = {
  SINGLE_CHOICE: 'Single Choice',
  MULTIPLE_CHOICE: 'Multiple Choice',
  FILE_UPLOAD: 'File Upload',
  DROPDOWN: 'Dropdown',
  MULTIPLE_TEXT_INPUT: 'Multiple Text Input',
  TEXT_INPUT: 'Text Input',
  VIDEO_RECORDING: 'VideoRecording',
  MCQ: 'MCQ'
};

export const eduOnboardingOptionsDisplayTypes = {
  VERTICAL: 'Vertical',
  HORIZONTAL: 'Horizontal'
};

export const unlockFreePlanFor = {
  SPECIAL_CLASS: 1,
  FREE_TEST: 2,
  COURSE: 3,
  SPECIAL_CLASS_NOTIFY: 4,
  DOWNLOAD_LESSON: 5,
  DOWNLOAD_PDF: 6,
  SUMMIT: 7
};

export const driveItemTypes = {
  FOLDER: 0,
  ROOT: 10,
  FILE_PDF: 20
};

export const driveSortOptions = {
  LAST_MODIFIED: {
    label: 'Last edited',
    id: 'last_modified'
  },
  FIRST_CREATED: {
    label: 'Date created',
    id: 'first_created'
  },
  ALPHABETICALLY_DESC: {
    label: 'A - Z',
    id: 'alphabet_asc'
  }
};

export const driveIframeMessages = {
  FILE_SAVED: 'FILE_SAVED',
  PARTIAL_UPLOAD_DONE: 'PARTIAL_UPLOAD_DONE',
  CLOSE_IFRAME: 'CLOSE_IFRAME'
};

export const driveIframeActionTypes = {
  UPLOAD: 'UPLOAD',
  EDIT: 'EDIT',
  CLOSE_ON_BROWSER_BACK: 'CLOSE_ON_BROWSER_BACK'
};

export const driveTypeConstants = {
  FOLDER: 'FOLDER',
  FILE: 'FILE',
  DRAWER_TYPE_RENAME: 'rename',
  DRAWER_TYPE_ADD: 'add'
};
// item can be user, course, batch and item
export const itemTypes = {
  USER: 'user',
  COLLECTION: 'collection',
  POST: 'post',
  CLASS: 'class',
  QUIZ: 'quiz',
  TEST: 'test',
  BATCHES: ['batches', 'batch'],
  BATCH: 'batch',
  CONCEPT: 'concept',
  PLUS_COURSES: ['plus_courses', 'programme', 'test_series'],
  TOPOLOGY: 'topology',
  TEST_SERIES: 'test_series',
  COMBAT: 'combat',
  STORE: 'store'
};

export const entityContentType = {
  PLATFORM_BATCH_GROUP: 'Platform Batch Group',
  TOPOLOGY: 'Topology'
};

export const CourseLanguage = {
  SPOKEN_LANGUAGE: 1,
  CONTENT_SLIDE_LANGUAGES: 2
};

export const seeAllTypes = {
  GOAL: 'goal',
  SPECIAL_CLASS: 'specialClass',
  FREE_COURSES: 'freeCourses'
};

export const seeAllSubTypes = {
  CRASH_COURSES: 'crash_courses',
  LIVE_NOW: 'live_now',
  EDUCATORS: 'educators',
  UPCOMING: 'upcoming',
  RECENTLY_STARTED: 'recently_started',
  POPULAR_COURSES: 'popular_courses',
  FAVOURITE_EDUCATORS: 'favourite_educators'
};

export const sessionStates = {
  FUTURE: 0,
  LIVE: 10,
  PAST: 20,
  DORMANT: 50
};

export const nudgeTypes = {
  ADD_PHONE: 'ADD_PHONE',
  VERIFY_PHONE: 'VERIFY_PHONE',
  VERIFY_EMAIL: 'VERIFY_EMAIL'
};

export const goalLevelOnboardingStatus = {
  NEW: 1,
  INCOMPLETE: 5,
  COMPLETE: 10
};

export const feedblockTypes = {
  LEGENDS_ON_UNACADEMY: 'legends_on_unacademy',
  BANNER: 'banner',
  GENERIC_BANNER: 'generic_banner',
  MY_DOWNLOADS: 'my_downloads',
  EXT_SUBSCRIPTION_PREACTIVATION: 'external_subscription_preactivation',
  EXT_SUBSCRIPTION_POSTACTIVATION: 'external_subscription_postactivation',
  CAROUSEL_FEED: 'carousel_feed'
};

export const educatorValuePropsTypes = {
  INTEGER: 1,
  STRING: 2
};

export const evtNameCategoryMap = {
  WITHOUT_CATEGORY: 1,
  WITH_CATEGORY: 2,
  BOTH: 3
};

export const settingErrorCodes = {
  IMAGE_TOO_LARGE: 'E546'
};

export const languageCodes = {
  NOT_SELECTED: -1,
  ALL: 0,
  ENGLISH: 1,
  HINDI: 2,
  PUNJABI: 3,
  KANNADA: 4,
  SPANISH: 5,
  FRENCH: 6,
  TAMIL: 7,
  TELUGU: 8,
  MALAYALAM: 9,
  MARATHI: 10,
  BENGALI: 11,
  GUJRATI: 12,
  MAITHILI: 13,
  ORIYA: 14,
  GERMAN: 51,
  PORTUGUESE: 52,
  INDONESIAN: 53,
  BHOJPURI: 54,
  ASSAMESE: 55,
  URDU: 56,
  MEWARI: 57,
  SANSKRIT: 58,
  HINGLISH: 59
};

export const skipDoubtTopologyLevels = {
  TOPIC_GROUP: 1,
  CONCEPT: 2,
  TOPIC: 3
};

export const s3BucketTypes = {
  LEARNER_NOTES: 8
};

export const backupCoverPhoto =
  'https://edge.uacdn.net/static/thumbnail/programme/eb5953366d9d47f69e5c4c8eb7cc84ec.jpg?q=100&w=512';

export const apiStatus = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

export const responseCodes = {
  BAD_REQUEST: 400,
  FORBIDDEN: 403
};

export const courseTemplateState = {
  DRAFT: 0,
  COMPLETE: 1,
  INTERMEDIATE: 5
};

export const liveMentoringSessionState = {
  UPCOMING: 1,
  PAST: 2,
  ALL: 0
};

export const searchItemTypes = {
  COURSE: 'programme',
  TEST_SERIES: 'test_series',
  USER: 'user',
  PLUS_LESSON: 'plus_lesson',
  BATCH: 'batch',
  TOPOLOGY: 'topology'
};

export const PLATFORM = {
  MOBILE: 7,
  OTHER: 0,
  ANDROID_BROWSER: 3
};

export const iconicOnboardingStatus = {
  ICONIC_ONBOARDING_INVALID: -1,
  ICONIC_ONBOARDING_NOT_STARTED: 1,
  ICONIC_ONBOARDING_PREF_ADDED: 2,
  ICONIC_ONBOARDING_COMPLETED: 3
};

export const mentorAssignedStatus = {
  MENTOR_ASSIGNED: 1,
  MENTOR_READY_SOON: 2
};

export const LMPSessionStatus = {
  UPCOMING: 5,
  ASSIGNMENT_DUE: 10,
  REASSIGNED: 15,
  STARTED: 20,
  STOPPED: 25,
  RECORDING_AVAILABLE: 30,
  CANCELLED: 35,
  MODERATOR_CANCELLED: 40,
  EDUCATOR_CANCELLED: 42,
  ASSIGNMENT_FAILED: 45,
  DELETED: 50
};

export const RecordingStatus = {
  LEARNER_DID_NOT_JOIN: 10,
  RECORDING_NOT_AVAILABLE: 20,
  RECORDING_WILL_BE_AVAILABLE_SHORTLY: 30,
  RECORDING_AVAILABLE: 40
};

export const LMPSessionStates = {
  past: 2,
  upcoming: 1
};

export const sideNavFlags = {
  ONE_TO_ONE_LIVE_MENTORING: 'LIVE_MENTORING',
  DOUBTS_AND_SOLUTIONS: 'DOUBT_&_SOLUTIONS',
  NOTES: 'NOTES',
  PRINTED_NOTES: 'PRINTED_NOTES'
};

export const STRIPE_GATEWAY_ID = 13;

export const GTP_TEST_SERIES_TYPE_MAP = {
  FREE: 'FREE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  COMING_SOON: 'COMING_SOON'
};

export const Z_INDEX = {
  PLAYER: 9999999999
};

export const DRAWER_IDS = {
  COURSE_CLASS_PLAYER: 'COURSE_CLASS_PLAYER'
};

export const getCourseEnrollMessage = ({
  type,
  goalUID,
  selectedGoalUID,
  goalName,
  isRecordedGoal,
  isHyridNonOffline
}) => {
  if (isHyridNonOffline)
    return 'Scheduled live classes will get added to the planner soon';
  if (isRecordedGoal) return 'Enrolled successfully';
  if (selectedGoalUID !== goalUID && goalName)
    return `${type} will be added to ${goalName} goal planner shortly`;
  return `${type} will be added to planner within a few minutes`;
};

export const UNSAT_GOAL_UID = 'KEMRV';

export const CONTINUE_ON_APP = 'Continue on app';

export const NEW_HOME_ENABLED_GOALS = ['KNMDN'];
