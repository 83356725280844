/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import parseUrlQuery from '@utils/marketing';
import { getIsLoggedIn, getMeUsername } from '@api/selectors/auth';
import { useEffect } from 'react';
import fetch from '@api/fetchers';

const sendGAId = (username, campaign) => {
  const GAid = window.gaGlobal?.vid;
  if (!GAid) return;
  return fetch(`v1/user/${username}/google_analytics_details/`, {
    requestType: 'POST',
    body: {
      ga_info: { client_id: window.gaGlobal?.vid, context: { campaign } }
    }
  });
};

const useGAId = () => {
  const { query } = useRouter();

  const username = useMyInfo(getMeUsername);
  const isLoggedIn = useMyInfo(getIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) sendGAId(username, parseUrlQuery(query));
  }, [isLoggedIn, query]);

  return null;
};

export default useGAId;
