import styled from '@emotion/styled';
import P1 from '@base/P1/P1';
import Button from '@base/Button/Button';
import { mediaQuery, Spacings } from '@styles/index';
import cookies from '@utils/cookies';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ICONS_GOAL_UID } from '@constants/unacademyIcons/icons.constants';
import useOfflineCentreStore from '@stores/city-specific-offline-centres';
import useWindowSize from '@hooks/useWindowSize';
import useGeoLocation from '@api/hooks/useGeoLocation';

const Wrapper = styled.div`
  position: fixed;
  height: 72px;
  bottom: ${({ $shiftBanner }) =>
    $shiftBanner ? `${Spacings.SPACING_18B}` : `${Spacings.SPACING_0B}`};
  left: 0px;
  background: ${({ $isWhiteBackground }) =>
    $isWhiteBackground ? 'var(--color-i-white)' : 'var(--color-base-1)'};
  border-top: 1px solid var(--color-divider);
  width: 100%;
  z-index: 1001;
  padding: ${Spacings.SPACING_14} ${Spacings.SPACING_18} ${Spacings.SPACING_14}
    ${Spacings.SPACING_18};
  ${mediaQuery.uptoTablet} {
    height: auto;
  }

  button:first-child {
    color: ${({ $isWhiteBackground }) =>
      $isWhiteBackground
        ? 'var(--color-text-tertiary)'
        : 'var(--color-text-primary);'};
  }

  button:last-child {
    background: ${({ $isWhiteBackground }) =>
      $isWhiteBackground ? 'var(--color-base-1)' : 'var(--color-base-fill);'};
    color: ${({ $isWhiteBackground }) =>
      $isWhiteBackground
        ? 'var(--color-text-primary)'
        : 'var(--color-text-tertiary);'};
  }
`;

const Banner = styled.div`
  display: flex;
  max-width: 1136px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  ${mediaQuery.uptoTablet} {
    flex-direction: column;
  }
`;

const Text = styled(P1)`
  max-width: 60%;
  font-weight: 600;
  color: ${({ $isWhiteBackground }) =>
    $isWhiteBackground
      ? 'var(--color-i-icon-dark-tertiary)'
      : 'var(--color-text-primary)'};
  ${mediaQuery.uptoTablet} {
    max-width: 100%;
    margin-bottom: ${Spacings.SPACING_4B};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: 176px;
  margin-right: ${Spacings.SPACING_4B};
  background: transparent;
  :hover {
    background: transparent;
    box-shadow: none;
  }
`;

const AcceptButton = styled(StyledButton)`
  width: 140px;
  box-shadow: none;
  :hover {
    box-shadow: none;
  }
`;

const updatedDateEpoch = 1643007860123; // Mon Jan 24 2022 12:34:10 GMT+0530 (India Standard Time) - Privacy Updated date

const RenderGDPRCookieBanner = ({ shouldShowCookierBanner }) => {
  const [showBanner, setShowBanner] = useState(shouldShowCookierBanner);
  const { shiftCookiesBanner } = useOfflineCentreStore();
  const { isMobile } = useWindowSize();
  const { geoLocationData: { countryCode } = {} } = useGeoLocation();
  const shiftBanner = isMobile && shiftCookiesBanner;

  const {
    asPath,
    query: { inside_app, is_iframe_login }
  } = useRouter();
  useEffect(() => {
    const cookieValue = Number(cookies.readCookie('gdpr-cookie-consent'));
    setShowBanner(
      cookieValue ? new Date(cookieValue) < new Date(updatedDateEpoch) : true
    );
  }, []);

  const acceptCookies = () => {
    cookies.setCookie('gdpr-cookie-consent', new Date().getTime());
    setShowBanner(false);
  };

  const isUaIconMode = useMemo(() => {
    return (
      (asPath.includes(ICONS_GOAL_UID) || asPath.includes('/icons/')) &&
      !asPath.includes('/subscribe') &&
      !asPath.includes('/response') &&
      !asPath.includes('/thank-you')
    );
  }, [asPath]);
  // we are not showing cookies banner for India
  if (countryCode === 'IN' || !countryCode) return null;
  if (inside_app || is_iframe_login) return null;
  if (showBanner) {
    return (
      <Wrapper
        $isWhiteBackground={isUaIconMode}
        $shiftBanner={shiftBanner}
        id="accept-cookie-banner"
      >
        <Banner>
          <Text $isWhiteBackground={isUaIconMode}>
            We use cookies to personalise your experience and to analyse our
            website traffic. By continuing to use our website, you consent to
            our cookies.
          </Text>
          <ButtonsWrapper>
            <StyledButton
              variant="hollow"
              label="View cookie policy"
              grid={5}
              maxHeight="xs"
              color="black"
              onClick={() => window.open('/cookie-policy')}
            />
            <AcceptButton
              variant="hollow"
              label="Accept"
              grid={5}
              maxHeight="xs"
              color="black"
              onClick={acceptCookies}
            />
          </ButtonsWrapper>
        </Banner>
      </Wrapper>
    );
  }

  return <></>;
};

export default RenderGDPRCookieBanner;
