const isPwa = () => {
  if (typeof window !== 'undefined') {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone ||
      document.referrer.includes('android-app://')
    );
  }
};

const isProgressiveWebApp = isPwa();

export default isProgressiveWebApp;
