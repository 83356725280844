import { segment } from '@actions/analytics';
import { logout } from '@api/fetchers/auth';
import { DEVICE_LIMITATION_LOGGED_OUT } from '@constants/segment';
import { localStorageAvailable } from '@utils/localStorage';
import signOutUser from '@utils/signOutUser';
import { ISocketListener } from 'types/sockets';
import usePlannerSyncStore from '@stores/plannerSync';
import { getHeartbeat } from '@cont/Planner/planner-utils/diagnostics/PlannerHeartbeat';
import { heartbeatTypes } from '@cont/Planner/workers/main/planner.types';
import { notificationStore } from '@stores/streaks/notificationStore';

const PingCommandListener: ISocketListener = {
  commandValue: 'PING',
  handler: ({ socketInstance, stargateJwtToken }) => {
    const data = {
      token: stargateJwtToken,
      cmd: 'PONG'
    };
    socketInstance.sendDataOnSocket(data);
  }
};

/**
 * This listener just triggers logout for the current user.
 * Currently Device limitation dialog allows to logout from all other devices,
 * this flow is triggered in that case to logout user.
 */
const LogoutCommandListener: ISocketListener = {
  commandValue: 'LOGOUT',
  handler: ({ goalEventProps }) => {
    segment.track(DEVICE_LIMITATION_LOGGED_OUT, goalEventProps);
    logout();
    signOutUser();
    if (localStorageAvailable()) {
      localStorage?.setItem('showlogoutnudge', `${Math.random()}`);
    }
  }
};

/**
 * At this point we only mark that planner sync needs to be triggered in the mini
 * planner store. From there, `usePlannerLite` will check and pick it up and
 * trigger the sync and fetch updates from server.
 * @param data Additional data provided by socket on this message.
 */
const scheduleSync = (data: any) => {
  const { goal_uid: goalUID, delay } = data ?? {};
  usePlannerSyncStore.getState().scheduleSync(goalUID, delay, Date.now());
  getHeartbeat().handleEvent(heartbeatTypes.ON_DEMAND_SYNC_TRIGGER_START);
};
/**
 * This listener schedules a sync for planner.
 * Web will be listening over socket for SYNC_PLANNER command from backend.
 * Upon getting this command, it'll trigger a sync cycle from frontend immediately.
 * This is to ensure both frontend asks for updates as soon as possible when there are updates
 */
const FetchPlannerUpdatesListener: ISocketListener = {
  commandValue: 'SYNC_PLANER',
  handler: ({ data }) => scheduleSync(data)
};

const StreaksCommandHandler: ISocketListener = {
  commandValue: 'SYNC_STREAK',
  handler: () => {
    notificationStore.getState().toggleShouldFetchActivityData(true);
  }
};

const commandHandlers = [
  PingCommandListener,
  LogoutCommandListener,
  FetchPlannerUpdatesListener,
  StreaksCommandHandler
];

export default commandHandlers;
