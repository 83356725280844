import * as MetaTagConstants from '@utils/metaTags';

const title = "Unacademy -  India's largest learning platform";
const description =
  'Prepare for examinations and take any number of courses from various topics on Unacademy - an education revolution';
const imageURL =
  'https://static.uacdn.net/static/dist/c76c2e18c7980818a913f9467c74c567.png';
const url = 'https://unacademy.com';
const twitterCardType = 'summary';
const ogType = 'website';

const defaultSeo = {
  defaultTitle: title,
  openGraph: {
    type: ogType,
    url,
    title,
    description,
    site_name: MetaTagConstants.SITE_NAME,
    images: [
      {
        url: imageURL,
        width: 800,
        height: 600
      }
    ]
  },
  twitter: {
    title,
    handle: MetaTagConstants.TWITTER_USER_NAME,
    content: description,
    cardType: twitterCardType,
    images: [
      {
        url: imageURL,
        width: 800,
        height: 600
      }
    ]
  },
  additionalMetaTags: [
    {
      property: 'theme-color',
      content: MetaTagConstants.THEME_COLOR
    },
    {
      property: 'twitter:app:id:iphone',
      content: MetaTagConstants.APP_STORE_ID
    },
    {
      property: 'twitter:app:id:ipad',
      content: MetaTagConstants.APP_STORE_ID
    },
    {
      property: 'twitter:app:id:googleplay',
      content: MetaTagConstants.PLAY_STORE_ID
    },
    {
      property: 'twitter:app:url:iphone',
      content: MetaTagConstants.IOS_CUSTOM_SCHEME
    },
    {
      property: 'twitter:app:name:iphone',
      content: MetaTagConstants.IOS_APP_NAME
    },
    {
      property: 'al:ios:app_name',
      content: MetaTagConstants.IOS_APP_NAME
    },
    {
      property: 'al:ios:app_store_id',
      content: MetaTagConstants.APP_STORE_ID
    },
    {
      property: 'al:ios:url',
      content: MetaTagConstants.IOS_CUSTOM_SCHEME
    },
    {
      property: 'al:android:package',
      content: MetaTagConstants.ANDROID_PACKAGE_NAME
    },
    {
      property: 'al:android:url',
      content: MetaTagConstants.ANDROID_CUSTOM_SCHEME
    },
    {
      property: 'al:android:app_name',
      content: MetaTagConstants.ANDROID_APP_NAME
    }
  ]
};

export default defaultSeo;
