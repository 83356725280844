import create from 'zustand/vanilla';
import createHook from 'zustand';

export const notificationStore = create((set) => ({
  shouldFetchActivityData: true,
  toggleShouldFetchActivityData: (shouldFetchActivityData) =>
    set((state) => ({
      ...state,
      shouldFetchActivityData
    }))
}));

const useNotificationStore = createHook(notificationStore);

export default useNotificationStore;
