import { useEffect } from 'react';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getNudgeData } from '@utils/nudgeHelper';
import { getMe } from '@api/selectors/auth';
import useLayoutNudgeData from '@stores/nudgeData';

const useSetNudgeData = () => {
  const me = useMyInfo(getMe);
  const setNudgeData = useLayoutNudgeData((state) => state.setNudgeData);

  useEffect(() => {
    if (me?.uid) setNudgeData(getNudgeData(me));
  }, [me, setNudgeData]);
};

export default useSetNudgeData;
