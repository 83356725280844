import create from 'zustand/vanilla';
import createHook from 'zustand';

export const showReferralBannerStore = create((set) => ({
  showReferralBanner: true,
  setShowReferralBanner: (show = false) =>
    set(() => ({ showReferralBanner: show }))
}));

const useShowReferralBannerStore = createHook(showReferralBannerStore);

const useShowReferralBanner = () =>
  useShowReferralBannerStore((state) => state.showReferralBanner);

export default useShowReferralBanner;
