/* eslint-disable no-restricted-globals */
export const HOST_TYPE = {
  DEFAULT: 'DEFAULT',
  CMS: 'CMS',
  AB_TESTING: 'AB_TESTING',
  TEST_SERIES: 'TEST_SERIES',
  LIVE_QUIZ: 'LIVE_QUIZ',
  UNACADEMY_ICONS: 'UNACADEMY_ICONS',
  LOCATION_HOST: 'LOCATION_HOST',
  BACKEND_API_HOST: 'BACKEND_API_HOST'
};

export const getDefaultHost = () => {
  const isWorker =
    typeof WorkerGlobalScope !== 'undefined' &&
    // eslint-disable-next-line no-undef
    self instanceof WorkerGlobalScope;
  const isMainThread = typeof window !== 'undefined';
  return isMainThread || isWorker
    ? process.env.BACKEND_URL
    : process.env.SERVER_BACKEND_URL;
};

export const getCMSHost = () => process.env.CMS_URL;

export const getABTestingHost = () => process.env.AB_SERVER_URL;

export const getTestSeriesHost = () => process.env.TEST_SERIES_API_HOST;

export const getLiveQuizHost = () => process.env.LIVE_QUIZ_API_HOST;

export const getUnacademyIconsHost = () => process.env.ICONS_URL;

export const getBackendAPIHost = () => process.env.SERVER_API_URL;

export const getHost = (hostType) => {
  if (hostType === HOST_TYPE.CMS) {
    return getCMSHost();
  }

  if (hostType === HOST_TYPE.AB_TESTING) {
    return getABTestingHost();
  }

  if (hostType === HOST_TYPE.TEST_SERIES) {
    return getTestSeriesHost();
  }

  if (hostType === HOST_TYPE.LIVE_QUIZ) {
    return getLiveQuizHost();
  }

  if (hostType === HOST_TYPE.UNACADEMY_ICONS) {
    return getDefaultHost();
  }

  if (hostType === HOST_TYPE.BACKEND_API_HOST) {
    return getBackendAPIHost();
  }

  if (hostType === HOST_TYPE.LOCATION_HOST) {
    const isBrowser = typeof window !== 'undefined';
    const clientAPIHost =
      isBrowser && window.location.hostname.includes('gamma.unacademydev.com')
        ? process.env.BACKEND_URL
        : 'https://api.unacademy.com/';
    return isBrowser ? clientAPIHost : getDefaultHost();
  }

  return getDefaultHost();
};
