import { useEffect, useRef, useCallback } from 'react';

import Script from 'next/script';

import cookies from '@utils/cookies';

import { useRouter } from 'next/router';
import segmentStorage from '@events/segmentStorage';
import { getDaysLapsed } from '@utils/dates';
import Me from '@models/Me';
import useComponentWillMount from '@hooks/useComponentWillMount';
import { getIsEducator, getIsLoggedIn } from '@api/selectors/auth';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import { FPS_LIST, LPS_LIST, NOT_APPLICABLE } from '@constants/segment';
import { subscriptionTypes } from '@constants/index';
import usePlatformInfo from '@hooks/usePlatformInfo';
import {
  getMeLatestUserSubscriptions,
  getTotalActiveBatches
} from '@api/selectors/subscription';
import useSegmentStatus from '@stores/useSegmentStatus';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import {
  getSegmentLoadedSetter,
  getLoadSegment
} from '@stores/selectors/segmentStatusSelectors';
import { getNewTraversalPath } from '@stores/selectors/common';
import { useIsMobile, usePageInfo, useSetPageLPS } from '@stores/AppCommonData';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';
import deviceHelper from '@utils/deviceHelper';
import getPlatform from '@utils/getPlatform';
import validateAndSetSessionId, {
  DEVICE_ID_COOKIE_NAME
} from '@utils/session/validateAndSetSessionId';
import loadAndSetupSegment from './utils/loadAndSetupSegment';

export const getSubscriptionType = (type) => {
  const entries = Object.entries(subscriptionTypes);
  const subscribedType = entries.find((entry) => entry[1] === type);
  return subscribedType ? subscribedType[0] : 'NA';
};

const SEGMENT_CDN_URL = `https://cdn.segment.com/analytics.js/v1/${process.env.SEGMENT_KEY}/analytics.min.js`;

export default function SegmentAnalytics({ SEGMENT_ENABLED }) {
  // global
  const { route, asPath, events, query } = useRouter();
  const uid = useMyInfo((me) => me?.uid ?? NOT_APPLICABLE);
  const username = useMyInfo((me) => me?.username ?? NOT_APPLICABLE);
  const shouldLoadSegment = useSegmentStatus(getLoadSegment);
  const setSegmentLoaded = useSegmentStatus(getSegmentLoadedSetter);
  useComponentWillMount(() => loadAndSetupSegment(process.env.SEGMENT_KEY));
  const onScriptLoaded = useCallback(() => {
    window?.analytics?.page();
    setSegmentLoaded(true);
    validateAndSetSessionId();
    const deviceID = cookies.readCookie(DEVICE_ID_COOKIE_NAME);
    window?.analytics?.setAnonymousId(deviceID);
  }, [setSegmentLoaded]);
  const activeBatchSubscriptions = useMyInfo(getTotalActiveBatches);
  const state = useMyInfo((me) => me?.state ?? NOT_APPLICABLE);
  const dateJoined = useMyInfo((me) => me?.dateJoined);
  const userId = useMyInfo((me) => me?.userID) || null;
  const clientId = cookies.readCookie('_ga');
  const sessionId = cookies.readCookie('anonymous_session_id');
  const loginStatus = useMyInfo(getIsLoggedIn);
  const isEducator = useMyInfo(getIsEducator);
  let userType = isEducator ? 'educator' : 'learner';
  if (!loginStatus) userType = null;
  const isEmailVerified = useMyInfo((me) => me?.isEmailVerified);
  const isPhoneVerified = useMyInfo((me) => me?.isPhoneVerified);
  const isInvited = useMyInfo((me) => me?.isInvited || false);
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);
  const { selectedGoal } = useSelectedGoal();
  const { businessPlatform } = usePlatformInfo();
  const isMobile =
    useIsMobile() ||
    deviceHelper.isMobile({ isServer: typeof window === 'undefined' });
  const {
    uid: default_goal_uid = NOT_APPLICABLE,
    name: default_goal_name = NOT_APPLICABLE
  } = selectedGoal;
  const plusSubscriptions = useMyInfo(getMeLatestUserSubscriptions);
  const currentPageInfo = usePageInfo();
  const currentPath = usePageInfo(getNewTraversalPath);
  const activeSubscription =
    Me.getActiveSubscription(plusSubscriptions, default_goal_uid) ?? {};
  const { timeRemaining = 0 } = activeSubscription;
  const default_is_subscription_active = timeRemaining > 0;
  let subscription = {};
  let expired_at = NOT_APPLICABLE;
  const days_since_joining = dateJoined
    ? getDaysLapsed(dateJoined)
    : NOT_APPLICABLE;
  let { name: currentScreen, lps } = currentPageInfo;
  lps = lps || NOT_APPLICABLE;
  const setLps = useSetPageLPS();
  const prevLpsRef = useRef('');
  if (default_is_subscription_active) {
    subscription = activeSubscription.subscription;
    expired_at = activeSubscription.expired_at || activeSubscription.expiredAt;
  }
  useEffect(() => {
    const routeChangeStart = () => {
      prevLpsRef.current = lps;
      if (
        FPS_LIST.includes(currentScreen) ||
        LPS_LIST.includes(currentScreen)
      ) {
        setLps(currentScreen);
      } else {
        // rigt now do nothing
        // Later implementation
      }
    };
    const routeChangeComplete = () => {
      prevLpsRef.current = '';
    };
    const routeChangeError = () => {
      setLps(prevLpsRef.current);
      prevLpsRef.current = '';
    };
    events.on('routeChangeStart', routeChangeStart);
    events.on('routeChangeComplete', routeChangeComplete);
    events.on('routeChangeError', routeChangeError);
    return () => {
      events.off('routeChangeStart', routeChangeStart);
      events.off('routeChangeComplete', routeChangeComplete);
      events.off('routeChangeError', routeChangeError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreen, lps]);

  useEffect(() => {
    let screen = currentScreen;
    const path = currentPath || NOT_APPLICABLE;
    if (!screen) {
      screen = route || NOT_APPLICABLE;
    }

    const segmentData = {
      user_id: userId,
      user_type: userType,
      learner_uid: uid,
      client_id: clientId,
      login_status: loginStatus,
      session_id: sessionId,
      learner_username: username,
      platform: getPlatform(),
      default_goal_uid,
      default_goal_name,
      state,
      days_since_joining,
      default_is_subscription_active,
      is_verified: (isEmailVerified && isPhoneVerified) ?? false,
      is_invited: isInvited,
      current_screen: screen,
      current_page_url: asPath,
      path,
      last_primary_source: lps,
      default_subscription_duration: subscription.duration ?? NOT_APPLICABLE,
      default_subscription_end_date: expired_at,
      dark_mode: isDarkModeOn,
      first_primary_source: NOT_APPLICABLE,
      is_new_device: NOT_APPLICABLE,
      user_subscription_plan: getSubscriptionType(subscription?.type),
      is_batch_subscription_active: activeBatchSubscriptions > 0,
      business_platform: businessPlatform,
      number_of_active_batch_subscription: activeBatchSubscriptions,
      source_application: 'web',
      ...(isEducator && {
        educator_id: userId,
        educator_uid: uid,
        educator_username: username
      })
    };
    segmentStorage.setItem('segmentData', segmentData);
  }, [
    userId,
    clientId,
    loginStatus,
    userType,
    sessionId,
    dateJoined,
    isEmailVerified,
    isPhoneVerified,
    selectedGoal,
    state,
    timeRemaining,
    isInvited,
    currentScreen,
    route,
    lps,
    uid,
    username,
    default_goal_uid,
    default_goal_name,
    isMobile,
    days_since_joining,
    default_is_subscription_active,
    asPath,
    currentPath,
    subscription.duration,
    subscription.type,
    expired_at,
    isDarkModeOn,
    activeBatchSubscriptions,
    businessPlatform,
    isEducator,
    query
  ]);
  return shouldLoadSegment && SEGMENT_ENABLED ? (
    <Script
      id="segment-analytics-snippet"
      src={SEGMENT_CDN_URL}
      strategy="afterInteractive"
      onLoad={onScriptLoaded}
    />
  ) : null;
}
