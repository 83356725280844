/* eslint-disable radix */
/* eslint-disable max-lines */
/* eslint-disable no-restricted-globals */
import { addDays } from 'date-fns';
import { pluralize } from './textHelper';

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const weekDayWithValues = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 0, label: 'Sunday' }
];

export const monthNamesWithValues = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

export const getOrdinal = (num) => {
  if (num > 3 && num < 21) return 'th';
  switch (num % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getWeekDayString = (date, type = 'short') => {
  // expecting a date object

  return new Date(date).toLocaleDateString('locale', { weekday: type });
};
// Aug
export const getMonthString = (date, type = 'short') =>
  new Date(date).toLocaleString('default', { month: type });

// 1942
export const getYear = (date) => new Date(date).getFullYear();

// 5
export const getDay = (date, withOrdinal = false) => {
  const day = new Date(date).getDate();
  return withOrdinal ? `${day}${getOrdinal(day)}` : day;
};

const fromToDate = (s, e) => {
  const startsAt = new Date(s);
  const endsAt = new Date(e);
  if (endsAt.getFullYear() === startsAt.getFullYear())
    return `${startsAt.toLocaleString('default', {
      month: 'short'
    })} ${startsAt.getDate()} - ${endsAt.toLocaleString('default', {
      month: 'short'
    })} ${endsAt.getDate()}, ${endsAt.getFullYear()}`;
  return `${startsAt.toLocaleString('default', {
    month: 'short'
  })} ${startsAt.getDate()}, ${startsAt.getFullYear()} - ${endsAt.toLocaleString(
    'default',
    {
      month: 'short'
    }
  )} ${endsAt.getDate()}, ${endsAt.getFullYear()}`;
};

// 5th Aug
export const getDateMonthString = (date, full = false, withOrdinal = true) => {
  const month = getMonthString(date, full ? 'long' : 'short');
  const dateInMonth = getDay(date, withOrdinal);
  return `${dateInMonth} ${month}`;
};

// Aug 5
export const getDateShort = (d, short = true) => {
  const date = new Date(d);
  return `${date.toLocaleString('default', {
    month: short ? 'short' : 'long'
  })} ${date.getDate()}`;
};

// Aug 5, 2020
export const getFullDate = (d) =>
  d ? `${getDateShort(d)}, ${getYear(d)}` : 'N/A';

// Aug, 2020
export const getMonthyear = (d) =>
  d ? `${getMonthString(d)}, ${getYear(d)}` : 'N/A';

// 5 Aug 2020
export const getRegularDate = (d, short = true) =>
  d
    ? `${getDay(d)} ${getMonthString(d, short ? 'short' : 'long')} ${getYear(
        d
      )}`
    : 'N/A';

// 5 August, 2020
export const getFullDateWithDayAtStart = (date, long = true) => {
  const d = new Date(date);
  return `${getDay(d)} ${getMonthString(d, long ? 'long' : 'short')}, ${getYear(
    d
  )}`;
};

const startofWeek = (d) => {
  const date = new Date(d);
  const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

// 3:30 PM
export const getTime = (d, laterInMins) => {
  let date;
  if (laterInMins)
    date = new Date(new Date(d).getTime() + laterInMins * 60 * 1000);
  else date = new Date(d);
  const h = date.getHours();
  let m = date.getMinutes();
  if (m < 10) m = `0${m}`;
  return `${h % 12 || 12}:${m} ${h < 12 ? 'A' : 'P'}M`;
};

// 3:05
export const getTimeWithoutAmPm12HrFormat = (d) => {
  const date = new Date(d);
  const h = date.getHours();
  let m = date.getMinutes();
  if (m < 10) m = `0${m}`;
  return `${h % 12 || 12}:${m}`;
};

export const getAmPm = (d) => {
  const date = new Date(d);
  const h = date.getHours();
  return h < 12 ? 'AM' : 'PM';
};

// Aug 5, 3:30 PM
export const dateWithTime = (d) => {
  if (!d) return '';
  const date = new Date(d);
  return `${getDateShort(date)}, ${getTime(date)}`;
};

// Aug 5, 2020, 3:30 PM
export const fullDateWithTime = (d, separator = ', ') => {
  if (!d) return 'N/A';
  const date = new Date(d);
  return `${getFullDate(d)}${separator}${getTime(date)}`;
};

export const fullRegularDateWithTime = (d, separator = ', ') => {
  if (!d) return 'N/A';
  const date = new Date(d);
  return `${getRegularDate(d)}${separator}${getTime(date)}`;
};

export const getStartAndEndDateOfWeek = (d, lessons, quizes) => {
  if (!d) return null;
  const date = new Date(d);
  const tempDate = new Date(date);
  const monday = startofWeek(tempDate);
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  let infoString = '';
  if (monday.getMonth() === sunday.getMonth())
    infoString = `${monday.toLocaleString('default', {
      month: 'short'
    })} ${monday.getDate()} - ${sunday.getDate()}`;
  else
    infoString = `${monday.toLocaleString('default', {
      month: 'short'
    })} ${monday.getDate()} - ${sunday.toLocaleString('default', {
      month: 'short'
    })} ${sunday.getDate()}`;
  if (lessons) infoString += ` • ${lessons} lesson${lessons > 1 ? 's' : ''}`;
  if (lessons && quizes) infoString += ', ';
  if (!lessons && quizes) infoString += ' • ';
  if (quizes) infoString += ` ${quizes} quiz${quizes > 1 ? 'es' : ''}`;
  return infoString;
};

export const getStartAndEndDateofCourseWithCount = (
  date1,
  date2,
  lessons,
  quizzes,
  isTestSeries
) => {
  if (!date1 || !date2) return '';
  let infoString = `${getDateShort(date1)} - ${getDateShort(date2)}`;
  if (lessons) infoString += ` • ${lessons} lesson${lessons > 1 ? 's' : ''}`;
  if (lessons && quizzes) infoString += ', ';
  if (!lessons && quizzes) infoString += ' • ';
  if (isTestSeries && quizzes)
    infoString += ` ${quizzes} test${quizzes > 1 ? 's' : ''}`;
  else if (quizzes) infoString += ` ${quizzes} quiz${quizzes > 1 ? 'es' : ''}`;
  return infoString;
};

// Feb 11 - Feb 25
export const getScheduleDateInfo = (date1, date2) => {
  if (!date1 || !date2) return '';
  return `${getDateShort(date1)} - ${getDateShort(date2)}`;
};

/* weekDate = 2022-02-24T05:30:00Z
o/p Feb 21 - 27 */
export const getWeekDateInfo = (startDateInAWeek) => {
  if (!startDateInAWeek) return null;
  const date = new Date(startDateInAWeek);
  const tempDate = new Date(date);
  const monday = startofWeek(tempDate);
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  let infoString = '';
  if (monday.getMonth() === sunday.getMonth())
    infoString = `${monday.toLocaleString('default', {
      month: 'short'
    })} ${monday.getDate()} - ${sunday.getDate()}`;
  else
    infoString = `${monday.toLocaleString('default', {
      month: 'short'
    })} ${monday.getDate()} - ${sunday.toLocaleString('default', {
      month: 'short'
    })} ${sunday.getDate()}`;

  return infoString;
};

// e.g 3:01 pm Gives time in 12 hr format with am/pm
export const formatTime = (d) => {
  if (!d) return '';
  const date = new Date(d);
  const h = date.getHours();
  let m = date.getMinutes();
  if (m < 10) m = `0${m}`;
  return `${h % 12 || 12}:${m} ${h < 12 ? 'A' : 'P'}M`;
};

// e.g. 5h 16m
export const getTimeFormatInShortFormWithoutSecs = (
  timeInSeconds,
  h = 'h',
  m = 'm'
) => {
  if (timeInSeconds == null) return '';
  if (timeInSeconds <= 0) return '0s';
  const seconds = Math.floor(timeInSeconds);
  const hrs = Math.floor(seconds / 3600);
  const min = Math.floor((seconds - 3600 * hrs) / 60);
  let hoursText = `${hrs}${h} `;
  if (hrs === 0) hoursText = '';
  let minText = `${min}${m} `;
  if (min === 0) minText = '';
  return hoursText + minText;
};

export const getShortFormTimeFormat = (time, separator = ' ') => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - hours * 3600 - minutes * 60);

  let formatedTime = '';
  let addSeparator = false;

  if (hours > 0) {
    formatedTime += `${hours}h`;
    addSeparator = true;
  }

  if (minutes > 0) {
    if (addSeparator) {
      formatedTime += `${separator}`;
    }

    formatedTime += `${minutes}m`;
    addSeparator = true;
  }

  if (seconds > 0) {
    if (addSeparator) {
      formatedTime += `${separator}`;
    }

    formatedTime += `${seconds}s`;
  }

  if (!formatedTime) {
    return `${time}s`;
  }

  return formatedTime;
};

export const getTimeFormatInShortFormWithoutSecsFromMins = (
  timeInMins,
  showSeconds = false,
  h = 'h',
  m = 'm',
  secs = 'secs'
) => {
  if (timeInMins == null) return '';
  if (timeInMins <= 0) return '0s';
  const hrs = Math.floor(timeInMins / 60);
  const min = Math.floor(timeInMins - 60 * hrs);
  const seconds = Math.floor((timeInMins - Math.floor(timeInMins)) * 60);
  let hoursText = `${hrs} ${h} `;
  if (hrs === 0) hoursText = '';
  let minText = `${min} ${m} `;
  if (min === 0) minText = '';
  let secText = `${seconds} ${secs}`;
  if (seconds === 0) secText = '';
  return `${hoursText}${minText}${showSeconds && hrs === 0 ? secText : ''}`;
};

export const getMinutesValueFromSeconds = (value) => {
  if (value == null || value <= 0) return '';
  let durationStr = '';
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  durationStr = `${durationStr}${minutes}${minutes > 1 ? ' mins' : ' min'}`;
  durationStr = `${durationStr} ${seconds}${seconds > 1 ? ' secs' : ' sec'}`;
  return durationStr;
};

// eg x sec = ahym (3800s === 1h 3m // 3m)
export const getHoursMinutesValueFromSeconds = (value) => {
  if (value == null || value <= 0) return '0m';
  const seconds = Math.floor(value);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - 3600 * hours) / 60);
  const minutesText = minutes > 0 ? `${minutes}m` : '';
  if (hours > 0) return `${hours}h ${minutesText}`;
  return `${minutes}m`;
};

// eg: 1 h 35 m
export const formatHoursMinutesValueFromSeconds = (value) => {
  if (value == null || value <= 0) return '0 m';
  const seconds = Math.floor(value);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - 3600 * hours) / 60);
  const minutesText = minutes > 0 ? `${minutes} m` : '';
  if (hours > 0) return `${hours} h ${minutesText}`;
  return `${minutes} m`;
};

// e.g. 1:18 min
export const formatMinutesFromSeconds = (seconds, showLabel = true) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${
    remainingSeconds > 9 ? remainingSeconds : `0${remainingSeconds}`
  }${showLabel ? 'mins' : ''}`;
};

export const timeInMinsSecs = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins > 0 && !isNaN(mins) ? `${mins}m` : ''} ${
    // eslint-disable-next-line no-nested-ternary
    isNaN(secs) ? '0' : secs > 9 ? secs : `0${secs}`
  }s`;
};

export const compareDates = (date1, date2) => {
  if (!date1 || !date2) return null;
  const d1 =
    typeof date1.getTime === 'function'
      ? date1.getTime()
      : new Date(date1).getTime();
  const d2 =
    typeof date2.getTime === 'function'
      ? date2.getTime()
      : new Date(date2).getTime();
  if (d1 === d2) return 0;
  if (d1 < d2) return 1;
  if (d1 > d2) return -1;
};
// assuming date2 > date1
export const getTimeDifference = (
  date1,
  date2,
  inMins = true,
  inSeconds = false,
  useCurrentDate = false
) => {
  const currentDate = new Date().getTime();
  if ((!date1 || !date2) && !useCurrentDate) return null;
  const epoch1 = useCurrentDate ? currentDate : new Date(date1).getTime();
  const epoch2 = new Date(date2).getTime();
  const diff = (epoch2 - epoch1) / 1000;
  if (inSeconds) return diff;
  if (inMins) return diff / 60;
};

export const addTime = (date, time, unit = 's') => {
  if (date && time) {
    const timeDiff = {
      s: 1000,
      m: 60000,
      h: 60 * 60000,
      d: 24 * 60 * 60000,
      yr: 365 * 24 * 60 * 60000
    };
    return new Date(date.getTime() + time * timeDiff[unit]);
  }
  return date;
};

export const subtractTime = (date, time, unit = 's') => {
  if (date && time) {
    const timeDiff = {
      s: 1000,
      m: 60000,
      h: 60 * 60000,
      d: 24 * 60 * 60000,
      yr: 365 * 24 * 60 * 60000
    };
    return new Date(date.getTime() - time * timeDiff[unit]);
  }
  return date;
};

export const isSameDay = (dateToCheck, actualDate) =>
  dateToCheck.getDate() === actualDate.getDate() &&
  dateToCheck.getMonth() === actualDate.getMonth() &&
  dateToCheck.getFullYear() === actualDate.getFullYear();

export const isToday = (dateToCheck) =>
  new Date().getDate() === new Date(dateToCheck).getDate();

export const isSameMonth = (dateToCheck, actualDate) =>
  dateToCheck.getMonth() === actualDate.getMonth() &&
  dateToCheck.getFullYear() === actualDate.getFullYear();

export const timeAgoSinceDate = (date, full = true) => {
  // seconds value
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = month * 12;
  const secondsDiff = Math.floor(
    (Date.now() - new Date(date).getTime()) / 1000
  );
  if (secondsDiff < minute) return 'just now';
  let valueWithLabel;
  if (secondsDiff < hour)
    valueWithLabel = {
      value: secondsDiff / minute,
      label: full ? 'minute' : 'm'
    };
  else if (secondsDiff < day)
    valueWithLabel = {
      value: secondsDiff / hour,
      label: full ? 'hour' : 'h'
    };
  else if (secondsDiff < month)
    valueWithLabel = {
      value: secondsDiff / day,
      label: full ? 'day' : 'd'
    };
  else if (secondsDiff < year)
    valueWithLabel = {
      value: secondsDiff / month,
      label: full ? 'month' : 'mo'
    };
  else
    valueWithLabel = {
      value: secondsDiff / year,
      label: full ? 'year' : 'y'
    };
  if (full) {
    return `${pluralize(
      Math.floor(valueWithLabel.value),
      valueWithLabel.label
    )} ago`;
  }
  return `${Math.floor(valueWithLabel.value)}${valueWithLabel.label} ago`;
};

export const timeTillFromSeconds = (secondsDiff) => {
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;

  if (secondsDiff < minute) return '1 minute';

  let valueWithLabel;

  if (secondsDiff < hour)
    valueWithLabel = {
      value: secondsDiff / minute,
      label: 'minute'
    };
  else if (secondsDiff < day)
    valueWithLabel = {
      value: secondsDiff / hour,
      label: 'hour'
    };
  else if (secondsDiff < month)
    valueWithLabel = {
      value: secondsDiff / day,
      label: 'day'
    };
  else if (secondsDiff < year)
    valueWithLabel = {
      value: secondsDiff / month,
      label: 'month'
    };
  else
    valueWithLabel = {
      value: secondsDiff / year,
      label: 'year'
    };
  return pluralize(Math.floor(valueWithLabel.value), valueWithLabel.label);
};
export const getDateRange = (initial, final) => {
  if (!initial || !final) return '';
  const initialDate = new Date(initial);
  const finalDate = new Date(final);
  const initialMonth = getMonthString(initialDate);
  const finalMonth = getMonthString(finalDate);
  const initialYear = getYear(initialDate);
  const finalYear = getYear(finalDate);
  const isDiffYear = initialYear !== finalYear;
  const isDiffMonth = isDiffYear || initialMonth !== finalMonth;
  return `${getDay(initialDate)} ${
    isDiffMonth ? getMonthString(initialDate) : ''
  }${
    isDiffYear ? `, ${getYear(initialDate)}` : ''
  } - ${getFullDateWithDayAtStart(finalDate, false)}`;
};

// 18 Jun - 24 Jun
export const getCustomDateRange = (initial, final) => {
  if (!initial || !final) return '';
  const initialDate = new Date(initial);
  const finalDate = new Date(final);
  const initialYear = getYear(initialDate);
  const finalYear = getYear(finalDate);
  const isDiffYear = initialYear !== finalYear;
  return `${getDay(initialDate)} ${getMonthString(initialDate)} ${
    isDiffYear ? `${getYear(initialDate)}` : ''
  } - ${getDay(finalDate)} ${getMonthString(finalDate)} ${
    isDiffYear ? `${getYear(finalDate)}` : ''
  }`;
};

// lastDate == should be in epoch and less than current date
export const getDaysLapsed = (lastDate) => {
  if (!lastDate) return 0;
  const d1 = new Date(lastDate).getTime();
  const current = new Date().getTime();
  return Math.floor((current - d1) / (1000 * 3600 * 24));
};

export const getMinutesDifferenceBetweenDates = (futureDate, currentDate) => {
  const diffInMilliseconds = futureDate - currentDate;
  const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
  return diffInMinutes;
};

const getValueWithLeadingZeroes = (value) => (value < 10 ? `0${value}` : value);

// 23:45:30, 03:04:05, hh:mm:ss
export const getHoursMinutesSeconds = (date) => {
  if (!date) return '';
  const newDate = new Date(date);
  const hours = getValueWithLeadingZeroes(newDate.getHours());
  const minutes = getValueWithLeadingZeroes(newDate.getMinutes());
  const seconds = getValueWithLeadingZeroes(newDate.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
};

const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours
export const getRelativeDay = (date, lowerCase = false) => {
  if (!date) return '';
  const targetDate = new Date(date);
  const today = new Date();
  if (isSameDay(targetDate, today)) return lowerCase ? 'today' : 'Today';
  const todayInMs = today.getTime();
  if (isSameDay(targetDate, new Date(todayInMs - oneDayInMs)))
    return lowerCase ? 'yesterday' : 'Yesterday';
  if (isSameDay(targetDate, new Date(todayInMs + oneDayInMs)))
    return lowerCase ? 'tomorrow' : 'Tomorrow';
};

export const getRelativeDayorCurrentDate = (date, lowerCase = false) => {
  if (!date) return '';
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours
  const targetDate = new Date(date);
  const today = new Date();
  if (isSameDay(targetDate, today)) return lowerCase ? 'today' : 'Today';
  const todayInMs = today.getTime();
  // if (isSameDay(targetDate, new Date(todayInMs - oneDay))) return 'Yesterday';
  if (isSameDay(targetDate, new Date(todayInMs + oneDay)))
    return lowerCase ? 'tomorrow' : 'Tomorrow';
  return `${getDay(date)} ${getMonthString(date)}`;
};

export const isHoursCompletedFromCurrentTime = (hours, setupKey) => {
  const now = new Date().getTime(); // current time
  const setupTime = localStorage.getItem(setupKey);
  if (setupTime === null || now - setupTime > hours * 60 * 60 * 1000) {
    localStorage.setItem(setupKey, now);
    return true;
  }
  return false;
};

export const getDate = (d) => {
  const date = new Date(d);
  const month = date.getMonth() + 1;
  return `${getYear(date)}-${month < 10 ? '0' : ''}${month}-${getDay(date)}`;
};

export const getTimeIn24HourFormat = (d) => {
  const date = new Date(d);
  const hrs = date.getHours();
  return `${!hrs ? '0' : ''}${hrs}:${date.getMinutes()}`;
};

export const formatMinutesFromSecondsInISO = (seconds = 0) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `PT${minutes}M${
    remainingSeconds > 9 ? remainingSeconds : `0${remainingSeconds}`
  }S`;
};

export const convertMinsToHrsMins = (
  mins,
  short = false,
  withSpace = false
) => {
  if (!mins) {
    return '0 h 0 min';
  }
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  return `${h > 0 ? `${h}${withSpace ? ' ' : ''}${short ? 'h' : 'hrs'}` : ''} ${
    m > 0 ? `${m}${withSpace ? ' ' : ''}${short ? 'm' : 'mins'}` : ''
  }`;
};

export const convertTZ = (
  date,
  tzString = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString
    })
  );
};

export const getFormattedDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    {},
    { timeZone: 'UTC', day: '2-digit', month: 'long', year: 'numeric' }
  );
  return formattedDate;
};

export const getDDMMYYYYFormat = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}/${mm}/${yyyy}`;
};

export const getMMDDYYYYFormat = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${mm}/${dd}/${yyyy}`;
};

export const getYYYYMMDDFormat = (date, separator = '/') => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}${separator}${mm}${separator}${dd}`;
};

/**
 * Computes time in current day based on minutes
 * 600 => 10:00 AM
 * @param {number} totalMinutes
 * @returns string
 */
export const getTimeFromMinutes = (totalMinutes) => {
  if (totalMinutes === 0) {
    return '12:00 AM';
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours % 12 || 12}:${minutes ? '30' : '00'} ${
    hours < 12 ? 'A' : 'P'
  }M`;
};

// TODO - Write tests
export const generateStartOfToday = () => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return today;
};

// TODO - Write tests
export const generateStartOfDay = (targetDate) => {
  const now = new Date(targetDate);
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return today;
};

// TODO - Write tests
export const generateNextDays = (targetCount = 5) => {
  const today = generateStartOfToday();
  const days = [today];
  for (let i = 1; i < targetCount; i += 1) {
    days.push(addDays(today, i));
  }
  return days;
};

// Generate 24hr and 12hr time intervals
// twentyFourHrFormat = ["00:00", "01:00", ...., "22:00", "23:00"]
// twelveHrFormat = ["00:00 AM", ""01:00 AM"", ...., "10:00 PM", "11:00 PM"]
export const generateHours = ({
  interval = 60,
  includeSecInTwelveHr = false,
  includeSecInTwentyFourHr = false
} = {}) => {
  const twelveHrFormat = [];
  const twentyFourHrFormat = [];
  let tt = 0;
  const ap = ['AM', 'PM'];

  for (let i = 0; tt < 24 * 60; i += 1) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;

    twelveHrFormat[i] = `${`0${hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)}${
      includeSecInTwelveHr ? ':00' : ''
    } ${ap[Math.floor(hh / 12)]}`;

    twentyFourHrFormat[i] = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}${
      includeSecInTwentyFourHr ? ':00' : ''
    }`;

    tt += interval;
  }

  return {
    twentyFourHrFormat,
    twelveHrFormat
  };
};
export const getLabelForTimeinDay = (time) => {
  if (time === 12) {
    return '12:00 PM';
  }
  if (time === 24) {
    return '12:00 AM';
  }
  if (time > 12 && time <= 24) {
    return `${time - 12}:00 PM`;
  }
  if (time > 24) {
    return `${time - 24}:00 AM`;
  }
  return `${time}:00 AM`;
};

export const getStartEpochDate = (date) => {
  const newDate = new Date(Number(date));
  newDate.setHours(0, 0, 0, 0);
  const startEpoch = newDate.getTime();
  return startEpoch;
};

export const getOnlyIntegerDateDifference = (startDateInMs, targetDateInMs) => {
  const dayDifference = Math.floor(
    (targetDateInMs - startDateInMs) / oneDayInMs
  );
  return dayDifference;
};

export const getDateDifference = (targetDate) => {
  if (!targetDate) return 'NA';
  const today = new Date();
  const differenceFromTargetDate = new Date(targetDate);
  const diffInMilliseconds = differenceFromTargetDate - today;
  const diffInDays =
    diffInMilliseconds >= 0
      ? Math.ceil(diffInMilliseconds / oneDayInMs)
      : Math.floor(diffInMilliseconds / oneDayInMs);

  return diffInDays;
};

export const getDateDifferenceFromToday = (targetDate) => {
  const targetDateInMs = new Date(targetDate).getTime();
  const todayInMs = new Date().getTime();
  const differenceInMs = todayInMs - targetDateInMs;

  return Math.round(differenceInMs / oneDayInMs);
};

// this will return today, tomorrow and number between 2 to T days left(for Example 2, 3, 4 until the range of T) and
// after the T days it will return date month string.
// example if T is 4 => today, tomorrow, 2, 3, 4, date month.
export const getRelativeDayOrCurrentDateWithTDays = (
  date,
  lowerCase = false,
  T = 7,
  isIntegerDateDifference = false
) => {
  if (!date) return '';
  const targetDate = new Date(date);
  const today = new Date();
  const daysDifference = isIntegerDateDifference
    ? getOnlyIntegerDateDifference(today, targetDate)
    : getDateDifference(date);

  if (isSameDay(targetDate, today)) return lowerCase ? 'today' : 'Today';
  const todayInMs = today.getTime();
  if (isSameDay(targetDate, new Date(todayInMs + oneDayInMs)))
    return lowerCase ? 'tomorrow' : 'Tomorrow';
  if (daysDifference <= T && daysDifference > 0) return `${daysDifference}`;
  if (daysDifference < 0) return '';
  return `${getDay(date)} ${getMonthString(date)}`;
};
export const todayTomorrowConfig = ['today', 'Today', 'Tomorrow', 'tomorrow'];

// this function return relative days
// if currentDate is true then this function will calculate relative days from current date
export const getRelativeDays = (currentDate, date1 = null, date2 = null) => {
  if (currentDate && !date1) return 'NA';
  if (!currentDate && (!date1 || !date2)) return 'NA';
  const d1 = new Date(date1);
  const d2 = currentDate ? new Date() : new Date(date2);
  const diffTime = d1 - d2;
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getTimezoneDifference = () => {
  const date = new Date();
  return date.getTimezoneOffset() * -1;
};

/**
 * Computes time in current day based on minutes
 * 600 => 10 AM
 * @param {number} totalMinutes
 * @returns string
 */
export const get12HrsFormatTimeFromMinutes = (totalMinutes = 0) => {
  if (totalMinutes === 0) {
    return '12 AM';
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours % 12 || 12}${minutes ? `:${minutes}` : ''} ${
    hours < 12 ? 'A' : 'P'
  }M`;
};

/**
 * Computes days in the given month and year
 * January is 1, December is 12
 * @param {number} month
 * @param {number} year
 * @returns {number}
 */
export const daysInMonth = (month, year) => {
  switch (month) {
    case 2:
      return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
    case 9:
    case 4:
    case 6:
    case 11:
      return 30;
    default:
      return 31;
  }
};

export const addDaysV2 = (date, offset) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  return new Date(year, month, day + parseInt(offset.toString()));
};

export const addMonths = (date, offset) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  return new Date(year, month + parseInt(offset.toString()), day);
};

export const getMonday = (date) => {
  const day = date.getDay() || 7;
  if (day !== 1) date.setHours(-24 * (day - 1));
  return new Date(date);
};

export const getDateWithoutYear = (d, long = false) => {
  if (!d) return '';
  return `${getMonthString(d, long ? 'long' : 'short')} ${getDay(d)}`;
};

const dates = {
  fromToDate,
  getDateShort,
  getMonthString,
  getDateMonthString,
  getStartAndEndDateOfWeek,
  formatTime,
  getTimeFormatInShortFormWithoutSecs,
  getTimeFormatInShortFormWithoutSecsFromMins,
  getStartAndEndDateofCourseWithCount,
  getMinutesValueFromSeconds,
  formatMinutesFromSeconds,
  dateWithTime,
  compareDates,
  getYear,
  getDay,
  addTime,
  timeAgoSinceDate,
  timeTillFromSeconds,
  isSameDay,
  timeInMinsSecs,
  getFullDate,
  getTime,
  getTimeWithoutAmPm12HrFormat,
  getAmPm,
  getHoursMinutesValueFromSeconds,
  fullDateWithTime,
  getFullDateWithDayAtStart,
  getDateRange,
  getDaysLapsed,
  getMinutesDifferenceBetweenDates,
  isHoursCompletedFromCurrentTime,
  getFormattedDate,
  getRelativeDayOrCurrentDateWithTDays,
  getRelativeDays,
  getStartEpochDate,
  getDateDifference,
  getScheduleDateInfo,
  getWeekDateInfo,
  getTimezoneDifference,
  getWeekDayString,
  addDaysV2,
  addMonths,
  getMonday,
  getDateWithoutYear
};

export default dates;
