import create from 'zustand/vanilla';
import createHook from 'zustand';

export const citySpecificOfflineCentreStore = create((set) => ({
  userLocation: null,
  isBatchesOnTop: false,
  imagesClicked: false,
  isBatchesVisibleZus: false,
  centreData: null,
  centresListData: [],
  isDemoClassesClicked: false,
  isScholarShipBlockClicked: false,
  isDownloadBrochureClicked: false,
  shiftCookiesBanner: false,
  isBookAVisitFlow: false,
  isBookAVisitClicked: false,
  centreListData: [],
  selectedCentreBEData: {},
  selectedCentreFound: false,
  isBookAVisitEnabledFromCMS: false,
  isVisitBooked: false,
  visitBookedData: {},
  bookCentreList: [],
  centreAddress: null,
  gmapLink: null,
  setCentreAddress: (value) => {
    set((state) => ({ ...state, centreAddress: value }));
  },
  setGmapLink: (value) => {
    set((state) => ({ ...state, gmapLink: value }));
  },
  setBookCentreList: (value) => {
    set((state) => ({ ...state, bookCentreList: value }));
  },
  setVisitBookedData: (value) => {
    set((state) => ({ ...state, visitBookedData: value }));
  },
  setIsVisitBooked: (value) => {
    set((state) => ({ ...state, isVisitBooked: value }));
  },
  setIsBookAVisitEnabledFromCMS: (value) => {
    set((state) => ({ ...state, isBookAVisitEnabledFromCMS: value }));
  },
  setBookAVisitFlow: (value) => {
    set((state) => ({ ...state, isBookAVisitFlow: value }));
  },
  setBookAVisitClicked: (value) => {
    set((state) => ({ ...state, isBookAVisitClicked: value }));
  },

  setSelectedCentreFound: (data) => {
    set((state) => ({ ...state, selectedCentreFound: data }));
  },
  setCentreListData: (data) => {
    set((state) => ({ ...state, centreListData: data }));
  },

  setDownloadBrochureClicked: (value) => {
    set((state) => ({ ...state, isDownloadBrochureClicked: value }));
  },
  setScholarShipBlockClicked: (value) => {
    set((state) => ({ ...state, isScholarShipBlockClicked: value }));
  },
  setDemoClassesClicked: (value) => {
    set((state) => ({ ...state, isDemoClassesClicked: value }));
  },
  // Setting CMS centre Data
  setCentreData: (data) => {
    set((state) => ({
      ...state,
      centreData: data
    }));
  },
  setSelectedCentre: (data) => {
    set((state) => ({
      ...state,
      selectedCentreBEData: data
    }));
  },
  setCentresListData: (data) => {
    set((state) => ({
      ...state,
      centresListData: data
    }));
  },
  setUserLocation: (location) =>
    set((state) => ({
      ...state,
      userLocation: location
    })),
  setIsBatchesOnTop: (value) =>
    set((state) => ({
      ...state,
      isBatchesOnTop: value
    })),
  setImagesClicked: (value) =>
    set((state) => ({
      ...state,
      imagesClicked: value
    })),
  setIsBatchesVisibleZus: (value) =>
    set((state) => ({
      ...state,
      isBatchesVisibleZus: value
    })),
  setShiftCookiesBanner: (value) =>
    set((state) => ({
      ...state,
      shiftCookiesBanner: value
    }))
}));

const useCitySpecificiOfflineCentreStore = createHook(
  citySpecificOfflineCentreStore
);

export default useCitySpecificiOfflineCentreStore;
