import createHook from 'zustand';

/**
 * This is a mini store for storing planner sync status amids different components
 * At core of this store, we maintain a variable called `triggerSync` which is used
 * to derive if planner sync needs to be triggered per goalUID.
 *
 * This also currently carries additional data as in tracking delay for planner
 * update and the timestamp that it recieved the trigger for update fetch.
 * Usage: plannerSyncStore.getState().triggerSync, plannerSyncStore.getState().scheduleSync()
 */
const usePlannerSyncStore = createHook((set) => ({
  triggerSync: {},
  scheduleSync: (goalUID, delay, fetchedTimestamp) =>
    set((state) => {
      const goalCurrentData = state.triggerSync[goalUID] ?? {};
      let updatedGoalData = { ...goalCurrentData };
      if (!goalCurrentData.shouldSync) {
        updatedGoalData = { shouldSync: true, delay, fetchedTimestamp };
      }
      return {
        ...state,
        triggerSync: {
          ...state.triggerSync,
          [goalUID]: updatedGoalData
        }
      };
    }),
  performingSync: (goalUID) =>
    set((state) => {
      return {
        ...state,
        triggerSync: {
          ...state.triggerSync,
          [goalUID]: { shouldSync: false, delay: null, fetchedTimestamp: null }
        }
      };
    })
}));

export default usePlannerSyncStore;
