import { useRouter } from 'next/router';

const EducatorRoutes = {
  '/educator': true
};

const useEducatorContext = () => {
  const router = useRouter();
  const { route, asPath } = router;
  const isEducatorSide =
    /\/educator\/|\/moderator\//.test(asPath) || EducatorRoutes[route];
  return isEducatorSide;
};

export default useEducatorContext;
