import { subscriptionTypes } from '@constants/index';
import getActiveUidsByType from './getActiveUidsByType';
import getPlusSubscriptionsUids from './getPlusSubscriptionUIDs';

const getLiteSubscriptionsUids = (user) => {
  const plusUids = new Set(getPlusSubscriptionsUids(user));
  return getActiveUidsByType(user, subscriptionTypes.LITE).filter(
    (id) => !plusUids.has(id)
  );
};

export default getLiteSubscriptionsUids;
