import cookie from 'js-cookie';

const thirdPartyParamObj = {
  sameSite: 'None',
  secure: true
};

const getThirdPartyParam = (iframe) => {
  return iframe ? thirdPartyParamObj : {};
};

export const setCookie = (
  key,
  value,
  expires = 3024000,
  subDomain = false,
  iframe = false
) => {
  if (process.browser) {
    const thirdPartyParam = getThirdPartyParam(iframe);
    if (subDomain) {
      cookie.set(key, value, {
        expires,
        path: '/',
        ...thirdPartyParam
      });
      if (key === 'accessToken') {
        cookie.set('loggedIn', true, {
          expires,
          path: '/',
          ...thirdPartyParam
        });
      }
    } else {
      cookie.set(key, value, {
        expires,
        path: '/',
        ...thirdPartyParam
      });
    }
  }
};

export const eraseCookie = (key, iframe = false) => {
  if (process.browser) {
    const thirdPartyParam = getThirdPartyParam(iframe);
    cookie.remove(key, thirdPartyParam);
    if (key === 'accessToken') {
      cookie.remove('loggedIn', thirdPartyParam);
    }

    if (key === 'accessToken' || key === 'loggedIn') {
      cookie.remove('accessToken', {
        path: '/',
        ...thirdPartyParam
      });
      cookie.remove('accessToken', {
        path: '/',
        ...thirdPartyParam
      });
      cookie.remove('loggedIn', {
        path: '/',
        ...thirdPartyParam
      });
    }
  }
};

const deleteSubdomainCookies = () => {
  if (process.browser) {
    cookie.remove('accessToken', {
      path: '/',
      domain: '.unacademy.com'
    });
    cookie.remove('refreshToken', {
      path: '/',
      domain: '.unacademy.com'
    });
    cookie.remove('loggedIn', {
      path: '/',
      domain: '.unacademy.com'
    });
  }
};

const getCookieFromBrowser = (key) => {
  try {
    return cookie.get(key);
  } catch (error) {
    return null;
  }
};

const getCookieFromServer = (key, state) => state().auth[key];

export const readCookie = (key, state) =>
  state ? getCookieFromServer(key, state) : getCookieFromBrowser(key);

export const checkCookieStatus = () => {
  if (typeof window === 'undefined') {
    return;
  }
  // Quick test if browser has cookieEnabled host property
  if (navigator.cookieEnabled) return true;
  // Create cookie
  document.cookie = 'unacademycookietest=1; SameSite=None; Secure';
  const ret = document.cookie.indexOf('unacademycookietest=') !== -1;
  // Delete cookie
  document.cookie =
    'unacademycookietest=1; SameSite=None; Secure; expires=Thu, 01-Jan-1970 00:00:01 GMT';
  return ret;
};

const cookies = {
  setCookie,
  readCookie,
  eraseCookie,
  deleteSubdomainCookies
};

export default cookies;
