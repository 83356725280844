import { createContext, useContext } from 'react';

const IsPageInReduxContext = createContext();

// Don't use this component anywhere other than PageReduxProviderWrapper.jsx
const PageReduxContextWrapper = ({ children, store }) => {
  return (
    <IsPageInReduxContext.Provider value={store}>
      {children}
    </IsPageInReduxContext.Provider>
  );
};
// Use this hook to determine if the page being rendered currently has redux in it or not.
export const useIsPageInReduxContext = () => !!useContext(IsPageInReduxContext);

// Use this hook to get the store conditionally, i.e: the store will be the return value on pages that have Redux and undefined/falsy value on pages that don't
export const useConditionalReduxStore = () => useContext(IsPageInReduxContext);

export default PageReduxContextWrapper;
