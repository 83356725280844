import getIsCurrentSubscription from './getIsCurrentSubscription';
import isOfflineSubscription from './isOfflineSubscription';

/**
 *
 * @param {*} user
 * @returns  an array of goal uids - it checks whethe rthe subscription is active and offline or not
 * and whether the subscription is active
 */

export const getActiveOfflineSubscriptionUIDs = (user) =>
  (
    user?.userSubscriptionTimeline ||
    user?.latestUserSubscriptionsTimeline ||
    user?.latestUserSubscriptions
  )?.reduce((_arrayIds, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      isOfflineSubscription(_plusSubscription)
    )
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    return _arrayIds;
  }, []) ?? [];

export const getActiveOfflineSubscriptions = (user) =>
  (
    user?.userSubscriptionTimeline ||
    user?.latestUserSubscriptionsTimeline ||
    user?.latestUserSubscriptions
  )?.reduce((_arraySubscriptions, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      isOfflineSubscription(_plusSubscription)
    )
      return [..._arraySubscriptions, _plusSubscription];
    return _arraySubscriptions;
  }, []) ?? [];

export const getOfflineSubscriptions = (user) =>
  (
    user?.userSubscriptionTimeline ||
    user?.latestUserSubscriptionsTimeline ||
    user?.latestUserSubscriptions
  )?.filter((subscription) => {
    return isOfflineSubscription(subscription);
  });

export const getOfflineSubscriptionUIDs = (user) =>
  (
    user?.userSubscriptionTimeline ||
    user?.latestUserSubscriptionsTimeline ||
    user?.latestUserSubscriptions
  )?.filter((subscription) => {
    return isOfflineSubscription(subscription) && subscription.subscription.uid;
  });
