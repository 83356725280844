import { FONTS } from '../constants';

export default function loadAndSetupFonts() {
  const fragment = document.createDocumentFragment();
  /**
   * In NextJS, it's not recommended to use Head to load up fonts on client side.
   * Hence we are manually creating the links to stylesheet and preloading them after the client side becomes interactive.
   * This helps avoiding fonts as a render blocking resource, also not using the FontFace api sets the priority of the network call to low.
   */
  FONTS.forEach((source) => {
    const preloadedLinkTag = document.createElement('link');
    preloadedLinkTag.rel = 'preload';
    preloadedLinkTag.href = source;
    preloadedLinkTag.type = 'font/woff2';
    preloadedLinkTag.as = 'font';
    preloadedLinkTag.crossOrigin = 'anonymous';
    fragment.appendChild(preloadedLinkTag);
  });
  document.head.appendChild(fragment);
}
