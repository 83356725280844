export const getCoreItemKey = (goalUID) => `${goalUID}-core`; // contains userUID, version

export const userUIDKey = 'userUID';
export const versionKey = 'version';

export const getInitialSetupItemKey = (goalUID) => `${goalUID}-initial-setup`; // isSetupComplete, isSetupRunning, setupStartTime

export const isSetupCompleteKey = 'isSetupComplete';
export const isSetupRunningKey = 'isSetupRunning';
export const setupStartTimeKey = 'setupStartTime';

export const getFinalSetupItemKey = (goalUID) => `${goalUID}-final-setup`; // isSetupComplete, isSetupRunning, setupStartTime

export const getLastFetchedBackgroundDataStepItemKey = (goalUID) =>
  `${goalUID}-last-fetched-background-data-step`;

export const getLastFetchedMonthIndexItemKey = (goalUID) =>
  `${goalUID}-last-fetched-month-index`;

export const getLastUpdatedAtItemKey = (goalUID) =>
  `${goalUID}-last-updated-at`;

export const metaStoreName = 'meta';
