export const goalUidSlugMap = {
  KSCGY: 'upsc-civil-services-examination-ias-preparation',
  TMUVD: 'jee-main-and-advanced-preparation',
  YOTUH: 'neet-ug',
  VLEMN: 'ssc-exams',
  MAFGF: 'gre-graduate-record-examination-preparation',
  XNDUS: 'cat-common-admission-test-preparation',
  TEWDQ: 'nta-ugc-net',
  RTPSX: 'bank-exams',
  BBKWG: 'ca-chartered-accountancy-preparation',
  MRZFY: 'law-entrance-exams',
  DZVHL: 'international-english-language-testing-system',
  GWDPV: 'madhya-pradesh-public-service-commission',
  GNFFE: 'maharashtra-public-service-commission',
  FHWHO: 'punjab-public-service-commission',
  QJEJG: 'railway-exams',
  SCANJ: 'rajasthan-public-service-commission',
  XCTVJ:
    'uttar-pradesh-public-service-commissionuttar-pradesh-subordinate-services-selection-commission',
  EWFJR: 'english-language',
  NJHPJ: 'campus-placements',
  SUVLV: 'class-9',
  GSZGO: 'class-10',
  GWDPZ: 'class-11',
  DOZGI: 'personal-development',
  ZISFF: 'tamil-nadu-public-service-commission',
  TUNWK: 'cdsafcatcapf',
  LGQVU: 'gmat-graduate-management-admission-test',
  PESHE: 'gate',
  LVECA: 'karnataka-public-service-commission',
  QKHZF:
    'andhra-pradesh-public-service-commission-telangana-state-public-service-commission',
  ZIUBD: 'kerala-public-service-commission',
  RJVOD: 'gujarat-public-service-commission',
  UQCMN: 'west-bengal-public-service-commission',
  JJTHM: 'dsssb-delhi-subordinate-services-selection-board',
  HQDXE: 'ujian-tulis-berbasis-komputer-sbmptn',
  BIZXQ: 'csir-ugc-net',
  QOIVT: 'bpsc-bihar-public-service-commision',
  QZIXN: 'exame-nacional-do-ensino-medio',
  GMXMZ: 'teacher-eligibility-test-tet',
  IFKUZ: 'uptet',
  PLWCX: 'class-12-board',
  XTKHH: 'opsc',
  ZKXEQ: 'haryana-public-service-commission',
  EGKYI: 'jammu-kashmir-public-services',
  SIFWL: 'iit-jam',
  SDDOC: 'neet-pg',
  WXKBF: 'madhya-pradesh-teacher-eligibility-test',
  AELON: 'jharkhand-public-service-commission',
  DOKOV: 'programming',
  MCRYW: 'company-secretary',
  SWBEI: 'rajasthan-teacher-eligibility-test',
  WJWZU: 'haryana-teacher-eligibility-test',
  DANNJ: 'ndaairforce-x-ynavy',
  JXGKD: 'hppsc',
  QFJOA: 'engineering-services-examination',
  TPSBK: 'foundation-ntse'
};

const reverseSlugUidMap = () => {
  const result = {};
  Object.keys(goalUidSlugMap).forEach((key) => {
    const value = goalUidSlugMap[key];
    result[value] = key;
  });
  return result;
};

export const goalSlugUidMap = reverseSlugUidMap();

export const menus = [
  { label: 'Terms and conditions', url: { href: '/terms', as: '/terms' } },
  { label: 'Privacy policy', url: { href: '/privacy', as: '/privacy' } },
  { label: 'User guidelines', url: { href: '/guidelines', as: '/guidelines' } },
  {
    label: 'Refund Policy',
    url: { href: '/refund-policy', as: '/refund-policy' }
  },
  {
    label: 'Placement protection Terms',
    url: {
      href: '/placement-protection-terms',
      as: '/placement-protection-terms'
    }
  },
  {
    label: 'Takedown Policy',
    url: { href: '/takedown-policy', as: '/takedown-policy' }
  },
  {
    label: '1:1 Live Mentorship T&C',
    url: { href: '/live-mentorship-terms', as: '/live-mentorship-terms' }
  },
  {
    label: 'Minor Policy',
    url: { href: '/minor-policy', as: '/minor-policy' }
  },
  {
    label: 'Live Classroom T&C',
    url: { href: '/classroom-terms', as: '/classroom-terms' }
  },
  {
    label: 'Combat T&C',
    url: { href: '/combat-terms', as: '/combat-terms' }
  },
  {
    label: 'Referral T&C',
    url: { href: '/referral-terms', as: '/referral-terms' }
  },
  {
    label: 'Rank Predictor T&C',
    url: { href: '/rank-predictor-terms', as: '/rank-predictor-terms' }
  },
  {
    label: 'Ranker Rewards T&C',
    url: { href: '/ranker-rewards-terms', as: '/ranker-rewards-terms' }
  },
  {
    label: 'UNSAT Terms and FAQs',
    url: { href: '/unsat-terms', as: '/unsat-terms' }
  },
  {
    label: 'Notes T&C',
    url: { href: '/notes-terms', as: '/notes-terms' }
  },
  {
    label: 'Cookie Policy',
    url: { href: '/cookie-policy', as: '/cookie-policy' }
  },
  {
    label: 'Compete T&C',
    url: { href: '/compete-terms', as: '/compete-terms' }
  },
  {
    label: 'Unacademy State CETs T&C',
    url: { href: '/state-cet-terms', as: '/state-cet-terms' }
  },
  {
    label: 'Unacademy One Kota T&C',
    url: { href: '/unacademy-one-kota-terms', as: '/unacademy-one-kota-terms' }
  },
  {
    label: 'Unacademy Centres T&C',
    url: { href: '/unacademy-centres-terms', as: '/unacademy-centres-terms' }
  },
  {
    label: 'Unacademy Credits T&C',
    url: { href: '/unacademy-credits-terms', as: '/unacademy-credits-terms' }
  }
];

export const legalPageRoutes = [
  '/privacy',
  '/terms',
  '/guidelines',
  '/refund-policy',
  '/takedown-policy'
];

export const tabletRoutes = ['/explore'];

export const scrollConstants = {
  scroll32: 72 + 32,
  scroll50: 72 + 50,
  scroll100: 72 + 100,
  scroll280: 72 + 280
};

export const businessPlatform = {
  UA_PLATFORM: 'UA Platform',
  UNACADEMY: 'Unacademy',
  UA_PLATINUM: 'UA Platinum',
  UA_OFFLINE: 'UA Offline'
};

export const NeetUmeed = '854Q9TMJNT';
export const NeetUmeedTitle = 'NEET UG Crash Course';

export const NEOLESSON = 2;

export const FRESHBOT_STYLE =
  'https://cdn.in-freshbots.ai/assets/share/css/chat-combined.min.css?v=0.26';
