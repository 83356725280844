/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { getIsLoggedIn } from '@api/selectors/auth';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import {
  getUserPreferredTheme,
  getIsDarkModeOnSetter
} from '@stores/selectors/darkModeStatusSelectors';

import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsSubscribedToGoal } from '@api/selectors/subscription';
import { useFlagSelector } from './useLayoutOptions';

const useDarkModeCalculator = ({ inEducatorSide }) => {
  const router = useRouter();
  const userPreferredTheme = useDarkModeStatus(getUserPreferredTheme);
  const setIsDarkModeOn = useDarkModeStatus(getIsDarkModeOnSetter);
  const disableDarkMode = useFlagSelector('disableDarkMode');
  const { query, route } = router;
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const darkModeRef = useRef();
  const isSubscribed = useMyInfo(getIsSubscribedToGoal(query.goalUID));
  darkModeRef.current =
    route === '/goal/[goalSlug]/[goalUID]' && !isSubscribed
      ? false
      : userPreferredTheme && !disableDarkMode && !inEducatorSide;

  if (!process.browser) {
    setIsDarkModeOn(darkModeRef.current);
  }

  useEffect(() => {
    setIsDarkModeOn(darkModeRef.current);
  }, []);

  useEffect(() => {
    const updateDarkMode = () => {
      setIsDarkModeOn(darkModeRef.current);
    };
    router.events.on('routeChangeComplete', updateDarkMode);
    return () => {
      router.events.off('routeChangeComplete', updateDarkMode);
    };
  }, [router.events, darkModeRef.current, disableDarkMode, userPreferredTheme]);

  useEffect(() => {
    setIsDarkModeOn(darkModeRef.current);
  }, [userPreferredTheme, isLoggedIn, disableDarkMode]);
};

export default useDarkModeCalculator;
