import useFetch from '@hooks/useFetch';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import processPurchaseData from '@api/processors/purchaseData';
import { getIsLoggedIn } from '@api/selectors/auth';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { HOST_TYPE } from '@utils/api-helper/host';
import useFollowedGoals from '../goal/useFollowedGoals';

const educatorRouteRegex = new RegExp('^/educator/*');

const getKey = ({ pathname, isLoggedIn, hasPlatformGoal }) => {
  if (!hasPlatformGoal) return null;
  const isEducatorRoutes = educatorRouteRegex.test(pathname);
  if (isEducatorRoutes) return null;
  if (isLoggedIn) return 'v1/user/purchased_items/';
  return null;
};

const isUserHasPlatformGoal = (followedGoals = []) => {
  return !!followedGoals.find((followedGoal) => followedGoal?.isPlatformGoal);
};

const usePurchasedItems = (selector) => {
  const { pathname } = useRouter();
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { followedGoals } = useFollowedGoals();
  const hasPlatformGoal = isUserHasPlatformGoal(followedGoals);
  const key = getKey({ pathname, isLoggedIn, hasPlatformGoal });
  const { data: purchasedItems, ...rest } = useFetch(
    key,
    {
      hostType: HOST_TYPE.BACKEND_API_HOST
    },
    {
      useCamelCase: false
    }
  );
  const processedPurchaseData = useMemo(
    () => processPurchaseData(purchasedItems),
    [purchasedItems]
  );
  if (typeof selector === 'function') return selector(processedPurchaseData);
  return { purchasedItems: processedPurchaseData, ...rest };
};

export default usePurchasedItems;
