import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import create from 'zustand';
import createContext from 'zustand/context';
import { FPS_LIST, LPS_LIST } from '@constants/segment';
import deviceHelper from '@utils/deviceHelper';
import { emptyObject } from '@constants/empty';

import {
  getIsMobile,
  getPageInfo,
  getSetPageLPS,
  getPageSEOContent,
  getIsPreviewMode,
  getSetPreviewMode,
  getIsLoginProcessCompleted
} from '@stores/selectors/common';
import { readCookie } from '@utils/cookies';
import useUserLocation from '@hooks/useUserLocation';

const defaultPageInfo = {
  layoutOptions: {
    isCheckoutUrl: false,

    hideHeader: false,
    hideSearch: false,
    hideGoalSelector: false,
    hideFooter: false,
    hideLearnerMinutes: false,
    hideNudges: false,

    disableDarkMode: false,

    useFreeLayout: false,

    // navbars
    showPlusNav: false,
    showEducatorNav: false,

    // plugins
    hideIntercom: false,
    hideFreshbot: false
    // hideFreshTicket: false
  }
};

const initialSEOContent = {
  title: '',
  description: '',
  contentBlock: emptyObject
};

const initialPageData = {
  name: '',
  route: '',
  path: '',
  lps: 'NA',
  routePathList: [],
  ...defaultPageInfo
};

const processPageInfo = (
  pageInfo = { name: '', seeAll: false },
  pathname,
  query
) => {
  // pathname is the href part of route
  let pageData = { ...pageInfo };
  if (typeof pageData.name === 'function') {
    pageData.name = pageData.name({
      router: { pathname, query }
    });
  } else if (!pageData.name) pageData.name = pathname;

  pageData = {
    ...pageData,
    route: pathname
  };

  // Changing code here for fps and path
  const { routePathList = [] } = pageData;

  // new page name is a FPS
  if (FPS_LIST.includes(pageData.name)) {
    pageData.routePathList = [pageData.name];
  } else if (LPS_LIST.includes(pageData.name)) {
    pageData.routePathList = [...routePathList, pageData.name];
  } else if (pageData.seeAll) {
    pageData.routePathList = [...routePathList, 'SEE ALL'];
  }

  return pageData;
};

const CommonData = createContext();

const createCommonDataStore = (
  initialIsMobile,
  initialPageInfo,
  initialPageSEOData,
  initialPreviewMode,
  initialSelectedGoalUid
) =>
  create((set) => ({
    isMobile: initialIsMobile || false,
    setIsMobile: (isMobile) => set((state) => ({ ...state, isMobile })),
    currentPageInfo: initialPageInfo || initialPageData,
    setPageInfo: (newPageInfo) =>
      set((state) => ({
        currentPageInfo: { ...newPageInfo, lps: state.currentPageInfo.lps }
      })),
    setPageLPS: (lps = []) =>
      set((state) => ({ currentPageInfo: { ...state.currentPageInfo, lps } })),
    seoContent: initialPageSEOData || initialSEOContent,
    setPageSEOContent: (seoContent = emptyObject) =>
      set(() => ({ seoContent })),
    nextPreviewMode: initialPreviewMode || false,
    setPreviewMode: (nextPreviewMode = false) =>
      set(() => ({ nextPreviewMode })),
    selectedGoalUid:
      initialSelectedGoalUid || readCookie('selectedGoalUid') || null,
    setSelectedGoalUid: (selectedGoalUid) => set(() => ({ selectedGoalUid })),
    loginProcessCompleted: false,
    setLoginProcessCompleted: () => set(() => ({ loginProcessCompleted: true }))
  }));

export const useCommonData = (selector) => CommonData.useStore(selector);
export const useIsMobile = () => useCommonData(getIsMobile);
export const usePageInfo = (selector) => useCommonData(selector || getPageInfo);
export const useSetPageLPS = () => useCommonData(getSetPageLPS);
export const usePageSeoContent = () => useCommonData(getPageSEOContent);
export const useIsPreviewMode = () => useCommonData(getIsPreviewMode);
export const useSetPreviewMode = () => useCommonData(getSetPreviewMode);
export const useIsLoginProcessCompleted = () =>
  useCommonData(getIsLoginProcessCompleted);

const EffectWrapper = ({
  userAgent,
  pageInfo,
  pageMetaInfo,
  selectedGoalUid,
  children
}) => {
  const isServer = typeof window === 'undefined';
  const setIsMobileDevice = useCommonData((state) => state.setIsMobile);
  const setPageInfo = useCommonData((state) => state.setPageInfo);
  const setPageSEOContent = useCommonData((state) => state.setPageSEOContent);
  const setSelectedGoalUid = useCommonData((state) => state.setSelectedGoalUid);

  useEffect(() => {
    setIsMobileDevice(deviceHelper.isMobile({ isServer, userAgent }));
  }, [isServer, userAgent, setIsMobileDevice]);
  useEffect(() => {
    setPageInfo(pageInfo);
  }, [pageInfo, setPageInfo]);

  useEffect(() => {
    setPageSEOContent(pageMetaInfo);
  }, [pageMetaInfo, setPageSEOContent]);

  const selectedGoalUidCookie = readCookie('selectedGoalUid');
  useEffect(() => {
    setSelectedGoalUid(readCookie('selectedGoalUid') || selectedGoalUid);
  }, [selectedGoalUid, setSelectedGoalUid, selectedGoalUidCookie]);

  return children;
};

const AppCommonDataWrapper = ({
  isMobile,
  pageInfo,
  userAgent,
  seoContent,
  previewMode,
  selectedGoalUid,
  children
}) => {
  const { pathname, query } = useRouter();
  const isMobileDevice = useMemo(() => isMobile || false, [isMobile]);
  const processedCurrentPageInfo = useMemo(
    () =>
      processPageInfo(
        { ...initialPageData, ...(pageInfo || emptyObject) },
        pathname,
        query
      ),
    [pageInfo, pathname, query]
  );

  // getting user geo location
  useUserLocation();

  return (
    <CommonData.Provider
      createStore={() =>
        createCommonDataStore(
          isMobileDevice,
          processedCurrentPageInfo,
          seoContent,
          previewMode,
          selectedGoalUid
        )
      }
    >
      <EffectWrapper
        isMobile={isMobile}
        pageInfo={processedCurrentPageInfo}
        userAgent={userAgent}
        pageMetaInfo={seoContent}
        selectedGoalUid={selectedGoalUid}
      >
        {children}
      </EffectWrapper>
    </CommonData.Provider>
  );
};

export default AppCommonDataWrapper;
