const getImmutable = (val) => Object.freeze(val);

export const emptyObject = getImmutable({});

export const emptyList = getImmutable([]);

export const emptyPaginatedObject = getImmutable({
  loaded: false,
  isFetching: false,
  results: emptyList,
  total: 0
});

export const noOp = () => {};

export const alwaysTrue = () => true;
