import createStore from 'zustand/vanilla';
import createHook from 'zustand';

// Use this in a non-react component, for example: server-side
// toggleableStore.getState().open[id], toggleableStore.getState().openElement(id)
const toggleableStore = createStore((set) => ({
  open: {},
  openElement: (id) => set(({ open }) => ({ open: { ...open, [id]: true } })),
  closeElement: (id) => set(({ open }) => ({ open: { ...open, [id]: false } })),
  toggleElement: (id) =>
    set(({ open }) => ({ open: { ...open, [id]: !open[id] } }))
}));

// Utility functions to abstract the .getState() part of the store
const isOpen = (id) => toggleableStore.getState().open[id];
const toggleToggleableElement = (id) =>
  toggleableStore.getState().toggleElement(id);
const closeToggleableElement = (id) =>
  toggleableStore.getState().closeElement(id);
const openToggleableElement = (id) =>
  toggleableStore.getState().openElement(id);

const useToggleableStore = createHook(toggleableStore);

/**
 * Use the below in a React Component
 * const { isOpen, openElement, closeElement, toggleElement } = useToggleable("logindrawer")
 */
const useToggleable = (id) => {
  return {
    isOpen: useToggleableStore((store) => store.open[id] || false),
    openElement: useToggleableStore((store) => () => store.openElement(id)),
    toggleElement: useToggleableStore((store) => () => store.toggleElement(id)),
    closeElement: useToggleableStore((store) => () => store.closeElement(id))
  };
};

export {
  toggleableStore,
  isOpen,
  toggleToggleableElement,
  closeToggleableElement,
  openToggleableElement,
  useToggleable
};
