import create from 'zustand/vanilla';
import createHook from 'zustand';

export const layoutNudgeData = create((set) => ({
  nudgeData: null,
  hideGLONudge: {},
  setNudgeData: (data) => set(() => ({ nudgeData: data })),
  hideNudge: () => set(() => ({ nudgeData: null })),
  setHideGLONudge: (goalUID) =>
    set((state) => ({
      hideGLONudge: { ...state.hideGLONudge, [goalUID]: true }
    }))
}));

const useLayoutNudgeData = createHook(layoutNudgeData);

export default useLayoutNudgeData;
