export const ANDROID_PACKAGE_NAME = 'com.unacademyapp';
export const IOS_PACKAGE_NAME = 'com.unacademy.learn';

export const APP_STORE_ID = '1342565069';
export const APP_STORE_URL =
  'https://apps.apple.com/in/app/unacademy-learning-app/id1342565069';

export const PLAY_STORE_ID = '4976430399163977061';
export const PLAT_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.unacademyapp';

export const IOS_APP_NAME = 'Unacademy IOS';
export const ANDROID_APP_NAME = 'Unacademy Android';

export const SITE_NAME = 'Unacademy';

export const ANDROID_CUSTOM_SCHEME = 'unacademy://goal/ssc-exams/VLEMN/free';
export const IOS_CUSTOM_SCHEME = 'medium://p/9fd506428204';

export const THEME_COLOR = 'FFA500';

export const TWITTER_USER_NAME = '@unacademy';
