const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const s = document.createElement('script');
      s.src = src;
      s.id = id;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    } else {
      resolve();
    }
  });
};

export default loadScript;
