import { NotFound } from '@utils/customErrors';
import {
  configureScope,
  captureException as sentryCaptureException
} from '@sentry/nextjs';

/*
extraProps = [
  { type: tag | user | context, key, value }
]
*/
const clearExtraScopes = (extraProps = []) => {
  configureScope((scope) => {
    extraProps.forEach((prop) => {
      if (prop.type === 'tag') scope.setTag(prop.key, undefined);
      else if (prop.type === 'user') scope.setUser({ [prop.key]: undefined });
      else if (prop.type === 'context') {
        scope.setContext(prop.key, undefined);
      }
    });
  });
};

export function captureException(err, req, statusCode, extraProps = []) {
  configureScope((scope) => {
    scope.setTag('cluster_name', process.env.CLUSTER_NAME);
    scope.setTag('statusCode', statusCode || 500);
    extraProps.forEach((prop) => {
      if (prop.type === 'tag') scope.setTag(prop.key, prop.value);
      else if (prop.type === 'user') scope.setUser({ [prop.key]: prop.value });
      else if (prop.type === 'context') {
        const value = { Response: prop.value };
        scope.setContext(prop.key, value);
      }
    });
    if (req) {
      scope.setTag('serverSide', true);
      scope.setExtra('url', req.url);
      scope.setExtra('params', req.params);
      scope.setExtra('query', req.query);
      scope.setExtra('headers', req.headers);
    }
    if (statusCode === 404) {
      scope.setTag('pageNotFound', true);
    }
  });
  const error =
    statusCode === 404
      ? new NotFound(`Page not found. route :- ${req.url}`)
      : err;
  sentryCaptureException(error);
  clearExtraScopes(extraProps);
}

const sentryUtils = {
  captureException
};

export default sentryUtils;
