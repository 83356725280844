import { subscriptionTypes } from '@constants/index';
import {
  getExpiredUidsByType,
  getIconicSubscriptionsUids,
  getLiteSubscriptionsUids,
  getPlusSubscriptions,
  getPlusSubscriptionsUids,
  getPlusWithoutTrialSubscriptionsUids,
  getCentreSubscription
} from '@models/Me';
import {
  getActiveOfflineSubscriptionUIDs,
  getActiveOfflineSubscriptions,
  getOfflineSubscriptions,
  getOfflineSubscriptionUIDs
} from '@utils/subscriptionHelpers/getOfflineSubscriptionsAndUIDs';

const processUserData = (user = {}, handleSubscriptions = true) => {
  if (user.processed) return user;
  const firstName = user.firstName?.toLocaleString() ?? '';
  const lastName = user.lastName ?? '';
  const data = {
    ...user,
    processed: true,
    firstName,
    lastName,
    userID: user.userId,
    name: user.name || `${firstName} ${lastName}`,
    thumbnail: user.avatarV1 || user.avatar || null,
    description: user.bio || null,
    relativeLink:
      user.isEducator || user.isModerator
        ? user.relativeLink || `/@${user.username}`
        : `/learner/@${user.username}`,
    boardingSteps: user.boardingStepsV3,
    latestUserSubscriptions: user.latestUserSubscriptionsTypeWise,
    platformUnlockStatus: user.platformUnlock,
    userSubscriptionTimeline:
      user.latestUserSubscriptionsTimeline ?? user.userSubscriptionTimeline
  };
  if (handleSubscriptions) {
    // derived fields
    data.offlineSubscriptions = getOfflineSubscriptions(data);
    data.offlineSubscriptionUIDs = getOfflineSubscriptionUIDs(data);
    data.activeOfflineSubscriptions = getActiveOfflineSubscriptions(data);
    data.activeOfflineSubscriptionUIDs = getActiveOfflineSubscriptionUIDs(data);
    data.plusSubscriptions = getPlusSubscriptions(data);
    data.plusSubscriptionUids = getPlusSubscriptionsUids(data);
    data.iconicSubscriptionUIDs = getIconicSubscriptionsUids(data);
    data.plusWithoutTrialSubscriptionUids =
      getPlusWithoutTrialSubscriptionsUids(data);
    data.liteSubscriptionUids = getLiteSubscriptionsUids(data);
    data.expiredSubscriptionsUids = getExpiredUidsByType(data);
    data.expiredIconicUIDs = getExpiredUidsByType(
      data,
      subscriptionTypes.ICONIC
    );
    data.hasCentreSubscription = getCentreSubscription(data);
  }

  return data;
};

export default processUserData;
