import fetch from '@api/fetchers';

export const logout = () => fetch('v1/user/logout/', { requestType: 'POST' });

export const validateEmailPhone = ({
  email = '',
  countryCode = '',
  phone = '',
  sendOtp = false
}) =>
  fetch('v3/user/user_check/', {
    requestType: 'POST',
    body: { email, phone, country_code: countryCode, send_otp: sendOtp }
  });

export const verifyMailOtp = (email, otp, country_code = '', password = '') =>
  fetch('v1/user/verify_mail_otp/', {
    requestType: 'POST',
    body: { email, otp, country_code, password }
  });

export const verifyTTUNNumber = ({ phone, otp, countryCode }) =>
  fetch('v3/user/ttun_verify_phone/', {
    requestType: 'POST',
    body: { phone, otp, country_code: countryCode }
  });

export const ttunUpdatePhone = ({ phone, otp, countryCode, phoneCode }) =>
  fetch('v3/user/ttun_update_phone/', {
    requestType: 'POST',
    body: { phone, country_code: countryCode, phone_code: phoneCode, otp }
  });

export const submitSubscriptionFeedback = (data, uid) =>
  fetch(`v1/feedback/${uid}/response/`, {
    requestType: 'POST',
    body: data
  });

export const fetchSubscriptionFeedbackCards = (goalUID) =>
  fetch(
    `v1/feedback/card/?object_uid=${goalUID}&feedback_type=gtp_subscription`
  );

export const updatePartialUserDetails = ({
  // email,
  firstName,
  lastName,
  state,
  accessToken
}) =>
  fetch('v1/user/update-user-signup-details/', {
    requestType: 'POST',
    body: {
      // email,
      first_name: firstName,
      last_name: lastName,
      state
    },
    tokens: { accessToken }
  });

export const updateUserDetails = (data) =>
  fetch('v1/user/update/?v=2', {
    requestType: 'POST',
    body: data
  });

export const setGoalLanguage = (goalUid, languageCode) =>
  fetch('v1/user/me/plus_language/', {
    requestType: 'POST',
    body: {
      language: languageCode,
      goal_uid: goalUid
    }
  });

export const unlockFreePlan = (payload) =>
  fetch('v1/uplus/platform/unlock/', {
    requestType: 'POST',
    body: payload
  });
