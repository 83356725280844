export const LearnerId = 'Learner Id';
export const LearnerUserName = 'Learner Username';
export const EducatorUsername = 'Educator Username';
export const Platform = 'Platform';
export const CourseID = 'Course ID';
export const CourseTitle = 'Course Title';
export const GoalId = 'Goal Id';
export const GoalName = 'Goal Name';
export const ComboId = 'Combo Id';
export const ComboName = 'Combo Name';
export const IsCombo = 'Is Combo';
export const SubscriptionDuration = 'Subscription Duration';
export const Amount = 'Amount';
export const IsUserSubscribed = 'Is User Subscribed';
export const IsFirstSubscription = 'Is First Subscription';
export const NoOfDaysRemainingInSubscription =
  'No of Days Remaining in Subscription';
export const ReferralCode = 'Referral Code';
export const HasReferralCode = 'Has Referral Code';
export const HasAppliedCredits = 'Has Applied Credits';
export const CreditsApplied = 'Credits Applied';
export const PaymentGateway = 'Payment Gateway';
export const OrderId = 'Order Id';
export const LastPrimarySource = 'Last Primary Source';
export const SubscriptionType = 'Subscription Type';
export const IsPartPayment = 'Is Part Payment';
export const CurrentPartPaymentIndex = 'Current Part Payment Index';
export const OptedPartPayments = 'Opted Part Payments';
export const ObjectType = 'Object Type';
export const ObjectId = 'Object Id';
export const InviteCode = 'Invite Code';
