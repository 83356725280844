import getIsCurrentSubscription from './getIsCurrentSubscription';

const getActiveUidsByType = (user, type) =>
  (
    user?.latestUserSubscriptions || user?.latestUserSubscriptionsTypeWise
  )?.reduce((_arrayIds, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      (!type || _plusSubscription.subscription.type === type)
    )
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    return _arrayIds;
  }, []) ?? [];

export default getActiveUidsByType;
