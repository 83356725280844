/* eslint-disable import/prefer-default-export */
import fetch from '@api/fetchers';

const freshbotUrl = 'https://support-bot.unacademy.com/';

export const fetchMeData = (role = 'learner') =>
  fetch(`${freshbotUrl}me?role=${role}`, {
    useCamelCase: false,
    useJWTToken: true
  });
