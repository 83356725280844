/* eslint-disable max-classes-per-file */
export class UnAuthorizedError extends Error {
  static statusCode = 401;

  static statusText = 'Unauthorized';
}

export class NotFoundError extends Error {
  static statusCode = 404;

  static statusText = 'Not Found';
}
