import { segment } from '@actions/analytics';
import isProgressiveWebApp from '@utils/isPwa';

import {
  PLANNER_DIAGNOSIS,
  PLANNER_DIAGNOSIS_ERROR,
  PLANNER_DIAGNOSIS_NO_DATA
} from '@constants/segment';
import {
  getCoreItemKey,
  getFinalSetupItemKey,
  getInitialSetupItemKey,
  getLastFetchedMonthIndexItemKey,
  getLastUpdatedAtItemKey,
  isSetupCompleteKey,
  metaStoreName
} from '@cont/Planner/planner-utils/db-handler/meta-table-helper';
import { getHeartbeat } from '@cont/Planner/planner-utils/diagnostics/PlannerHeartbeat';
import { openDB } from 'idb';

interface ItemMetaInfo {
  uid: string;
  plannerItemType: string;
  eventType: string;
  activityAt: number;
  liveAt: number;
  finishedAt: number;
  isLive: boolean;
}

interface IDiagonsticMetadata {
  enable: boolean;
  goalUID: string;
  isWSRunning: boolean;
  wsReadyState?: number;
  isWSActive?: boolean;
  todayEvents: ItemMetaInfo[];
}

const diagnosticMetadata: IDiagonsticMetadata = {
  enable: false,
  goalUID: null,
  isWSRunning: false,
  isWSActive: false,
  todayEvents: null
};

export async function sendPlannerDiagnosticData(
  extraParams: Record<string, any>
) {
  const isOnPlanner = true; // window.location.href?.includes('planner');
  const { goalUID } = diagnosticMetadata;
  // console.log(goalUID, diagnosticMetadata.enable, isOnPlanner);
  if (isOnPlanner) {
    if (diagnosticMetadata.enable) {
      try {
        const dbStore = await openDB('planner');
        const [
          coreData,
          initialSetupInfo = {},
          lastFetchedMonthIndex,
          finalSetupInfo = {},
          lastUpdatedAt
        ] = await Promise.all([
          dbStore.get(metaStoreName, getCoreItemKey(goalUID)),
          dbStore.get(metaStoreName, getInitialSetupItemKey(goalUID)),
          dbStore.get(metaStoreName, getLastFetchedMonthIndexItemKey(goalUID)),
          dbStore.get(metaStoreName, getFinalSetupItemKey(goalUID)),
          dbStore.get(metaStoreName, getLastUpdatedAtItemKey(goalUID))
        ]);
        const initialSetupComplete =
          initialSetupInfo?.[isSetupCompleteKey] ?? false;
        const finalSetupComplete =
          finalSetupInfo?.[isSetupCompleteKey] ?? false;
        const heartbeat = getHeartbeat();
        const diagnosticPayload = {
          ...diagnosticMetadata,
          coreData,
          initialSetupInfo,
          initialSetupComplete,
          lastFetchedMonthIndex,
          finalSetupInfo,
          finalSetupComplete,
          lastUpdatedAt,
          ...extraParams,
          heartbeatErrors: heartbeat.getHeartbeatErrors(),
          recentLifecycleEvents: heartbeat.getRecentLifecycleEvents(),
          is_pwa: isProgressiveWebApp,
          current_client_time: new Date().getTime()
        };
        // console.log(diagnosticPayload);
        segment.track(PLANNER_DIAGNOSIS, diagnosticPayload);
      } catch (err) {
        segment.track(PLANNER_DIAGNOSIS_ERROR, {
          ...diagnosticMetadata,
          ...extraParams
        });
      }
    } else {
      segment.track(PLANNER_DIAGNOSIS_NO_DATA, {
        ...diagnosticMetadata,
        ...extraParams
      });
    }
  }
}

export function enablePlannerDiagnostics() {
  diagnosticMetadata.enable = true;
}

export function updatePlannerDiagnosticsData(
  data: Partial<IDiagonsticMetadata>
) {
  if (data.goalUID) diagnosticMetadata.goalUID = data?.goalUID;
  if (data.todayEvents) diagnosticMetadata.todayEvents = data?.todayEvents;
  if ('isWSRunning' in data) diagnosticMetadata.isWSRunning = data?.isWSRunning;
  if ('isWSActive' in data) diagnosticMetadata.isWSActive = data?.isWSActive;
  if ('wsReadyState' in data)
    diagnosticMetadata.wsReadyState = data?.wsReadyState;
}
