import { readCookie, setCookie } from '@utils/cookies';
import uuid from '@utils/uuid';

export const SESSION_ID_COOKIE_NAME = 'un_session_id';
export const DEVICE_ID_COOKIE_NAME = 'ajs_anonymous_id';
const MIN_INTERVAL_TO_SET = 2 * 1000; // 2 seconds

let lastSet: null | Number = null;

const validateAndSetSessionId = (): string | undefined => {
  const now = new Date().getTime();
  if (typeof lastSet === 'number' && now - lastSet <= MIN_INTERVAL_TO_SET)
    return;

  const existingSessionId = readCookie(SESSION_ID_COOKIE_NAME);
  const existingDeviceId = readCookie(DEVICE_ID_COOKIE_NAME);

  let newSessionId = '';
  let newDeviceId = '';
  if (!existingSessionId) newSessionId = uuid();
  if (!existingDeviceId) newDeviceId = uuid();

  const sessionId = existingSessionId || newSessionId;
  const deviceId = existingDeviceId || newDeviceId;
  const inThirtyMinutes = new Date(now + 30 * 60 * 1000);
  // @ts-ignore
  setCookie(SESSION_ID_COOKIE_NAME, sessionId, inThirtyMinutes);
  setCookie(DEVICE_ID_COOKIE_NAME, deviceId); // Extremely long expiry
  lastSet = now;
  return sessionId;
};

export default validateAndSetSessionId;
