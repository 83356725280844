import { css, Global } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import boxShadowGenerator from '@utils/boxShadowGenerator';

const GlobalStyles = ({ isOffline, isMobile, fontsLoaded }) => (
  <Global
    styles={css`
      ${emotionNormalize}
      html {
        ${isOffline && !isMobile && 'filter: grayscale(100%);'}
      }

      body {
        background-color: var(--color-base-0);
      }

      #mobile-chat-container {
        box-shadow: ${boxShadowGenerator()} !important;
      }

      .fa {
        font-family: FontAwesome, sans-serif !important;
      }

      * {
        text-rendering: optimizeLegibility;
        box-sizing: border-box;
        word-break: break-word;
        outline: none;
        text-decoration: none;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* !important is used in the following font setup as in some places MUI makes roberto the standard font, and hence we're overriding it. */
        font-family: ${fontsLoaded
          ? 'AvertaStd, -apple-system, BlinkMacSystemFont, sans-serif !important'
          : '-apple-system, BlinkMacSystemFont, sans-serif'};
        overscroll-behavior: none;
      }

      ::selection {
        color: var(--color-i-black);
        background: rgba(8, 189, 128, 0.4);
      }
      /* Setup the font-faces after they have been dynamically loaded on the client side. */
      ${fontsLoaded
        ? '@font-face { font-family: AvertaStd; src: url("https://static.uacdn.net/prod/fonts/avertastd-regular.woff2"); font-style: normal; font-display: swap;}'
        : ''}

      ${fontsLoaded
        ? '@font-face { font-family: AvertaStd; src: url("https://static.uacdn.net/prod/fonts/avertastd-bold.woff2"); font-weight: bold; font-display: swap;}'
        : ''}

      ${fontsLoaded
        ? '@font-face { font-family: AvertaStd; src: url("https://static.uacdn.net/prod/fonts/avertastd-semibold.woff2"); font-weight: 600; font-display: swap;}'
        : ''}
    `}
  />
);

export default GlobalStyles;
