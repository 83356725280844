import { emptyList } from '@constants/empty';
import { subscriptionTypes } from '@constants/index';
import isOfflineSubscription from '@utils/subscriptionHelpers/isOfflineSubscription';

const getIsCurrentSubscription = (subscription) => {
  return (subscription.timeRemaining || subscription.timeRemaining) > 0;
};

const LiteType = [subscriptionTypes.LITE];

export const getActiveUidsByType = (user, type) =>
  (
    user?.latestUserSubscriptions || user?.latestUserSubscriptionsTypeWise
  )?.reduce((_arrayIds, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      (!type || _plusSubscription.subscription.type === type)
    )
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    return _arrayIds;
  }, []) ?? [];

const isNotLiteTypeSubscription = (subscription) =>
  !LiteType.includes(subscription.subscription.type);

// it is not supposed to be here
export const getPlusSubscriptionsUIDS = (user) =>
  user?.userSubscriptionTimeline?.reduce((_arrayIds, _plusSubscription) => {
    if (
      getIsCurrentSubscription(_plusSubscription) &&
      isNotLiteTypeSubscription(_plusSubscription) &&
      !isOfflineSubscription(_plusSubscription)
    )
      return [..._arrayIds, _plusSubscription.subscription.value.uid];
    return _arrayIds;
  }, []) ?? [];

export const getIsPlusEnrolled = (meData) => meData?.isPlusEnrolled;

export const getMePlusSubscriptions = (meData) =>
  meData?.plusSubscriptions || emptyList;

export const getOfflineSubscriptions = (meData) =>
  meData?.activeOfflineSubscriptions || emptyList;

export const getAllOfflineSubscriptions = (meData) =>
  meData?.offlineSubscriptions || emptyList;

export const getMeLatestUserSubscriptions = (meData) =>
  meData?.latestUserSubscriptions || emptyList;

export const getMeCenterSubscription = (meData) =>
  meData?.hasCentreSubscription ?? false;

export const getMeUserSubscriptionTimeline = (meData) =>
  meData?.userSubscriptionTimeline || emptyList;

export const getIsUserSubscribedToStoreBundle =
  (bundleUID, contentTypeId) => (meData) => {
    const activeSubscriptions = meData?.userSubscriptionTimeline?.filter(
      (subscription) => {
        return (
          subscription?.contentTypeId === contentTypeId &&
          subscription?.subscription?.value?.uid === bundleUID &&
          subscription?.timeRemaining > 0
        );
      }
    );

    return !!activeSubscriptions && activeSubscriptions.length > 0;
  };

export const getIsUserSubscribedToDigitalNotes =
  (goalUid, contentTypeId) => (meData) => {
    const activeSubscriptions = meData?.userSubscriptionTimeline?.filter(
      (subscription) => {
        return (
          subscription?.contentTypeId === contentTypeId &&
          subscription?.goalUid === goalUid &&
          subscription?.timeRemaining > 0
        );
      }
    );

    return !!activeSubscriptions && activeSubscriptions.length > 0;
  };

export const getMeLatestUserSubscriptionsForGoal = (goalUID) => (meData) =>
  meData?.latestUserSubscriptionsTypeWise?.filter((subscription) => {
    return subscription.subscription.value?.uid === goalUID;
  }) || [];

export const getPlusSubscriptionUIDs = (meData) =>
  meData?.plusSubscriptionUids || emptyList;

export const getIconicSubscriptionUIDs = (meData) =>
  meData?.iconicSubscriptionUIDs || emptyList;

export const getOfflineSubscriptionUIDs = (meData) =>
  meData?.activeOfflineSubscriptionUIDs || emptyList;

export const getLiteSubscriptionUIDs = (meData) =>
  meData?.liteSubscriptionUids || emptyList;

export const getPrePaymentPlusSubscriptionUIDs = (meData) =>
  meData?.prePaymentPlusSubscriptionUids;

export const getIsSubscribed = (meData) =>
  getPlusSubscriptionUIDs(meData).length > 0 ||
  getOfflineSubscriptionUIDs(meData).length > 0;

export const getPlusSubscriptionInfo = (goalUID) => (meData) =>
  getMePlusSubscriptions(meData).find(
    ({ subscription }) => subscription?.value.uid === goalUID
  );

export const getPlusSubscriptionInfoLatest = (goalUID) => (meData) =>
  getMeLatestUserSubscriptions(meData).find(
    ({ subscription }) => subscription?.value.uid === goalUID
  );

export const getIsSubscribedToGoal = (goalUID) => (meData) => {
  if (!goalUID) {
    return false;
  }
  return (
    getActiveUidsByType(meData).includes(goalUID.toUpperCase()) ||
    getOfflineSubscriptionUIDs(meData).includes(goalUID.toUpperCase())
  );
};

export const getIsSubscribedToPlus = (goalUID) => (meData) => {
  if (!goalUID) {
    return false;
  }
  return getPlusSubscriptionUIDs(meData).includes(goalUID.toUpperCase());
};

export const getIsSubscribedToIconic = (goalUID) => (meData) => {
  if (!goalUID) {
    return false;
  }
  return getIconicSubscriptionUIDs(meData).includes(goalUID.toUpperCase());
};

// adding a new selector for finding subscription to an offline goal
export const getIsSubscribedToOffline = (goalUID) => (meData) => {
  if (!goalUID) {
    return false;
  }
  return (
    getOfflineSubscriptionUIDs(meData).includes(goalUID.toUpperCase()) ||
    getMeCenterSubscription(meData)
  );
};

export const getIsSubscribedToLite = (goalUID) => (meData) => {
  if (!goalUID) {
    return false;
  }
  return getLiteSubscriptionUIDs(meData).includes(goalUID.toUpperCase());
};

export const getHasExpiredSubscriptionToGoal = (goalUID) => (meData) => {
  if (!goalUID) {
    return false;
  }
  return (meData?.expiredSubscriptionsUids || []).includes(
    goalUID.toUpperCase()
  );
};

export const getPlusWithoutTrialSubscriptionUIDs = (meData) =>
  meData?.plusWithoutTrialSubscriptionUids || emptyList;

export const getIsSubscribedToPlusWithoutTrial = (meData) =>
  getPlusWithoutTrialSubscriptionUIDs(meData).length > 0;

// Purchase Data Subscriptions Related
const getPlatformSubscriptionUIDs = (purchasedItems) =>
  purchasedItems?.platformSubscriptionUIDs || emptyList;

export const getPlatformSubscriptionGoalUIDs = (purchasedItems) =>
  purchasedItems?.platformSubscriptionGoalUIDs || emptyList;

export const isBatchSubscriptionActive = (goalUID) => (purchasedItems) => {
  if (!goalUID) return false;
  return getPlatformSubscriptionGoalUIDs(purchasedItems).includes(
    goalUID.toUpperCase()
  );
};

export const hasActiveBatch = (purchasedItems) =>
  purchasedItems?.hasActiveBatch;

export const getBatchGroupSubscriptions = (purchasedItems) =>
  purchasedItems?.batchGroupSubscriptions ?? [];

export const getTotalActiveBatches = (purchasedItems) =>
  getPlatformSubscriptionUIDs(purchasedItems)?.length ?? 0;

export const getIsSubscribedToPlatformBatchGroup = (purchasedItems) =>
  !!getPlatformSubscriptionUIDs(purchasedItems)?.length;
