import { getPlatformSubscriptionUIDs } from '@models/Me';

const processPurchaseData = (data = []) => {
  const platformSubscriptionUIDs = getPlatformSubscriptionUIDs(
    data?.user_subscriptions
  );
  const platformSubscriptionGoalUIDs =
    data?.user_subscriptions
      ?.map((subscription) =>
        platformSubscriptionUIDs.includes(subscription.uid)
          ? subscription.goal.uid
          : null
      )
      .filter((subscriptionGoalUID) => subscriptionGoalUID) || [];
  const processedPurchaseData = {
    hasActiveBatch: platformSubscriptionUIDs?.length > 0,
    batchGroupSubscriptions: data?.user_subscriptions || [],
    platformSubscriptionUIDs: platformSubscriptionUIDs || [],
    platformSubscriptionGoalUIDs
  };

  return processedPurchaseData;
};

export default processPurchaseData;
