/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { checkShowReferrals } from '@utils/referralHelper';
import { showReferralBannerStore } from '@stores/referralBanner';

const useReferralBanner = () => {
  useEffect(() => {
    showReferralBannerStore
      .getState()
      .setShowReferralBanner(checkShowReferrals());
  }, []);

  return null;
};

export default useReferralBanner;
