export default function escapeJSON(string) {
  const json = JSON.stringify(string);
  return json
    .replace(/\//g, '\\/')
    .replace(
      /[\u003c\u003e]/g,
      (match) =>
        `\\u${`0000${match.charCodeAt(0).toString(16)}`
          .slice(-4)
          .toUpperCase()}`
    )
    .replace(
      /[\u007f-\uffff]/g,
      (match) => `\\u${`0000${match.charCodeAt(0).toString(16)}`.slice(-4)}`
    );
}
