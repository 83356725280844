import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';
import { useMemo } from 'react';

const processConstants = (data = {}) => {
  const {
    STARGATE_SOCKET_RETRY_COUNT,
    STARGATE_SOCKET_RETRY_INTERVAL_IN_MS,
    STARGATE_SOCKET_TIMEOUT_IN_MS,
    STARGATE_API_DOMAIN,
    STARGATE_SOCKET_DOMAIN,
    DEVICE_ACCESS_API_TIMEOUT_IN_MS,
    STARGATE_ENABLED_GOALS = [],
    PLANNER_SYNC_INTERVAL_WEB_IN_MIN,
    ...constants
  } = data;

  const socketConfig = {
    deviceAccessDomain: STARGATE_API_DOMAIN,
    socketDomain: STARGATE_SOCKET_DOMAIN,
    socketRetryCount: STARGATE_SOCKET_RETRY_COUNT,
    socketRetryInterval: STARGATE_SOCKET_RETRY_INTERVAL_IN_MS,
    deviceAccessTimeout: DEVICE_ACCESS_API_TIMEOUT_IN_MS,
    socketTimeout: STARGATE_SOCKET_TIMEOUT_IN_MS,
    enabledGoals: STARGATE_ENABLED_GOALS
  };

  const plannerSyncInterval =
    (PLANNER_SYNC_INTERVAL_WEB_IN_MIN ?? 2) * 60 * 1000;
  return {
    ...constants,
    socketConfig,
    plannerSyncInterval
  };
};

export const constantsAPIKey = 'v1/constants/?platform=0'; // pragma: allowlist secret

const useConstants = (selector) => {
  const { data: constants, ...rest } = useFetch(constantsAPIKey, {
    useCamelCase: false,
    hostType: HOST_TYPE.BACKEND_API_HOST
  });
  const processedConstants = useMemo(() => {
    if (constants) return processConstants(constants);
    return {};
  }, [constants]);
  if (typeof selector === 'function') return selector(processedConstants);
  return { constants: processedConstants, ...rest };
};

export const useConstantsGetValue = () => {
  const { data: constants } = useFetch('v1/constants/get-value/');
  return constants || {};
};

export default useConstants;
