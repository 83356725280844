import create from 'zustand/vanilla';
import createHook from 'zustand';

export const requestStatusStore = create((set) => ({
  apiFetching: false,
  showUberLoader: false,
  setFetching: (apiFetching = false) => set(() => ({ apiFetching })),
  setShowUberLoader: (showUberLoader = false) => set(() => ({ showUberLoader }))
}));

const useRequestStatusStore = createHook(requestStatusStore);

export default useRequestStatusStore;
