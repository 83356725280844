import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Close from '@icon/CloseCircleOutline';

const Reload = styled.button`
  padding: 0;
  cursor: pointer;
  background: transparent;
  color: var(--color-i-white);
  border: none;
  font-weight: bold;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FlexContent = styled.div`
  flex: 1;
`;

const FlexClose = styled.div`
  cursor: pointer;
`;

let count = 3;

const Loading = ({ error, retry, timedOut }) => {
  if (error || timedOut) {
    if (count > 0) {
      count -= 1;
      retry();
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open
        onClose={() => {}}
        autoHideDuration={null}
        id="snackbar-chunkError"
      >
        <Alert
          id="chunk-alert"
          elevation={6}
          variant="filled"
          severity="error"
          onClick={() => window.location.reload()}
        >
          <AlertTitle>Seems like website has not loaded properly.</AlertTitle>
          <Flex>
            <FlexContent>
              <Reload>Please click here to refresh.</Reload>
            </FlexContent>
            <FlexClose>
              <Close
                size="24px"
                onClick={(e) => {
                  e.stopPropagation();
                  document.getElementById('snackbar-chunkError').remove();
                }}
                color="var(--color-i-white)"
              />
            </FlexClose>
          </Flex>
        </Alert>
      </Snackbar>
    );
  }
  return null;
};

Loading.propTypes = {
  error: PropTypes.oneOf([null, PropTypes.objectOf(PropTypes.any)]),
  retry: PropTypes.func,
  timedOut: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
};

Loading.defaultProps = {
  error: null,
  timedOut: false,
  retry: () => {}
};

export default Loading;
