import { createContext } from 'react';
import PropTypes from 'prop-types';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getPlusSubscriptionInfoLatest } from '@api/selectors/subscription';

export const Context = createContext();

export const PurchaseTypeContextProvider = ({ children, goalUID }) => {
  const subscription = useMyInfo(getPlusSubscriptionInfoLatest(goalUID));
  // eslint-disable-next-line no-nested-ternary
  const purchaseType = subscription
    ? subscription.timeRemaining
      ? 'Extension'
      : 'Renewal'
    : 'New Purchase';
  return <Context.Provider value={purchaseType}>{children}</Context.Provider>;
};

PurchaseTypeContextProvider.defaultProps = {
  goalUID: ''
};

PurchaseTypeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  goalUID: PropTypes.string
};

PurchaseTypeContextProvider.defaultProps = {
  goalUID: ''
};
